import React from 'react'
import ColsAnalysisFields from './colsAnalysisFields';

const afColoursRevenue = [
    "rgb(249, 249, 249)",
    "rgb(232, 232, 232)",
    "rgb(249, 249, 249)",
    "rgb(232, 232, 232)",
    "rgb(249, 249, 249)",
    "rgb(232, 232, 232)",
    "rgb(249, 249, 249)",
    "rgb(232, 232, 232)",
    "rgb(249, 249, 249)",
    "rgb(232, 232, 232)",
    "rgb(249, 249, 249)",
    "rgb(232, 232, 232)",
    "rgb(249, 249, 249)",
    "rgb(232, 232, 232)",
    "rgb(249, 249, 249)",
    "rgb(232, 232, 232)",
]

const afColoursQuantity = [
    "#FFF",
    "rgb(241, 241, 241)",
    "#FFF",
    "rgb(241, 241, 241)",
    "#FFF",
    "rgb(241, 241, 241)",
    "#FFF",
    "rgb(241, 241, 241)",
    "#FFF",
    "rgb(241, 241, 241)",
    "#FFF",
]

export default function ColsAnalysisFieldsSet(props) {
    return (
        props.analysisFields.map((af, i) => {
            //console.log("af is " + JSON.stringify(af))
            return (
                <React.Fragment key={af.analysisFieldID}>
                    {
                        af.targetRevenue ?
                            <ColsAnalysisFields
                                {...props}
                                blankTarget={{
                                    ...props.blankTarget,
                                    targetType: "revenue",
                                    analysisFieldID: af.analysisFieldID
                                }}
                                targetType="revenue"
                                targets={(props.targets || []).filter(t => 
                                    t.targetType === "revenue" &&
                                    t.analysisFieldID === af.analysisFieldID
                                )}
                                backgroundColor={afColoursRevenue[i]}
                                analysisField={af}
                                totalTargets={(props.totalTargets || []).filter(t => 
                                    t.targetType === "revenue" &&
                                    t.analysisFieldID === af.analysisFieldID
                                )}
                                hoursInput={props.hoursInput}
                            />
                        : null
                    }
                    {
                        af.targetQuantity ?
                            <ColsAnalysisFields
                                {...props}
                                blankTarget={{
                                    ...props.blankTarget,
                                    targetType: "quantity",
                                    analysisFieldID: af.analysisFieldID
                                }}
                                targetType="quantity"
                                targets={(props.targets || []).filter(t => 
                                    t.targetType === "quantity" &&
                                    t.analysisFieldID === af.analysisFieldID
                                )}
                                backgroundColor={afColoursQuantity[i]}
                                analysisField={af}
                                totalTargets={(props.totalTargets || []).filter(t => 
                                    t.targetType === "quantity" &&
                                    t.analysisFieldID === af.analysisFieldID
                                )}
                                hoursInput={props.hoursInput}
                            />
                        : null
                    }
                </React.Fragment>
            )
        })
    )
}