import React, {useState, useEffect} from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import FileUpload from './fileUpload'
import authFetch from '../../../lib/authFetch'
import {getToken} from '../../../lib/auth'
import { Button } from '@material-ui/core'

const blankFile = {
    fileID: 0,
    library: "",
    size: 0,
    originalName: ""
}

export default function FileUploader(props) {
    //props.fileType controls the types of file being uploaded.  Supported: excel, pdf
    const [uploading, setUploading] = useState(false)
    const [validationText, setValidationText] = useState("")
    const [file, setFile] = useState(blankFile)

    useEffect(() => {
        //when a new file id is passed as the value, get the details of said file
        if(props.value === 0) {
            //there is no file use the blank one
            setFile(blankFile)
        } else {
            authFetch("/file/" + props.value, {method: "GET"})
            .then(r => {
                if(r.validationResult.fail) {
                    //use blank form
                    setFile(blankFile)
                } else {
                    //got information ok
                    setFile(r.data)
                }
            })
            .catch(err => {
                setFile(blankFile)
            })
        }
        
    }, [props.value])

    const urlType = props.urlType || "pdf"

    function handleFilesChange(newFiles) {
        //clear out any previous validation message
        setValidationText("")
        if(newFiles.length > 0) {
            //start an upload.  let the parent know that we have started uploading
            setUploading(true)
            if(props.onUploading !== undefined) {
                props.onUploading()
            }
            //create the file on the server
            //use the library passed in props
            var useLibrary = props.library
            if(useLibrary === undefined) {
                useLibrary = "misc"
            }
            //make a formdata of the files in the file upload
            var formData = new FormData();
            formData.append('file', newFiles[0]);
            authFetch("/file/" + useLibrary + "/" + urlType, {method: "POST", body: formData}, true)
            .then(r => {
                //was it a success?
                if(r.validationResult.fail) {
                    //there was a problem, show it in the validation text
                    setValidationText(r.validationResult.failSummary)
                } else {
                    //upload was a success
                    handleChange(r.data.fileID)
                }
            })
            .catch(err => {
                console.log("Error uploading file: " + err)
                //no need to do anything further, leave the value as it is
            })
            .finally(() => {
                setUploading(false)
                if(props.onUploadComplete !== undefined) {
                    props.onUploadComplete()
                }
            })
        }
    }

    function handleChange(newVal) {
        //a file has been successfully uploaded, make an event for the parent to digest
        //create a phony event object to pass back to the parent so it can handle it as if it's a text box
        var e = {
            target: {
                id: props.id,
                value: newVal
            }
        }
        props.onChange(e)
    }

    function handleChangeFile() {
        //reset the file id back to zero
        handleChange(0)
    }
    
    return (
        <div style={{height: "56px", display: "flex", width: "100%", cursor: props.disabled ? undefined : "pointer"}}>
            {
                props.value === 0 ? 
                    //either show upload box or static text depending on whether disabled
                    !props.disabled ?

                        //showing uplaod/uploading
                        uploading ? 
                            <div style={{width: "100%", height: "54px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <CircularProgress />
                            </div>
                        :
                            <FileUpload 
                                accept={props.accept === undefined ? "application/pdf" : props.accept}
                                multiple={false} 
                                onChange={handleFilesChange} 
                                label={props.label}
                                value={[]} //value is only used to trigger a change in display when not doing instant uploads 
                                error={(props.error || validationText !== "")} 
                                helperText={props.helperText + validationText}
                            />

                    :

                        //no value and disabled, show static text
                        "(no file selected)"

                :
                    //there is a value, show the details
                    <div style={{width: "100%", height: "54px", display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
                        <div style={{display: "flex", flexGrow: 1, justifyContent: "flex-start", alignItems: "center"}}>
                            <a style={{marginLeft: "8px"}} href={(window.location.href + "salt").replace(window.location.pathname + "salt", "/api") + "/file/" + props.value + "/download?token=" + getToken()} >{file.originalName} ({file.size} bytes)</a>
                        </div>
                        {
                            props.disabled ? undefined :
                            <div style={{display: "flex", alignItems: "center", width: "80px"}}>
                                <Button onClick={handleChangeFile} variant="contained">
                                    Change
                                </Button>
                            </div>
                        }
                        
                    </div>
            }
            
        </div>
    )
}