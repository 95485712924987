import React from 'react'

import Button from '@material-ui/core/Button'

//icons
import dateFormat from 'dateformat'

import MonthHeader from '../../shared/monthHeader'

export default function landingPage(props) {

    function handleDateChange(newDate) {
        //if date is this month then use todays date.  else use the first
        var dateStr
        if(dateFormat(new Date(), "yyyy-mm") === dateFormat(newDate, "UTC:yyyy-mm")) {
            //current month
            //console.log(dateFormat(newDate, "UTC:yyyy-mm-dd") + " is current")
            dateStr = dateFormat(new Date(), "yyyy-mm-dd")
        } else {
            //not current month, use last day of month
            //console.log(dateFormat(newDate, "UTC:yyyy-mm-dd") + " is NOT current")
            //console.log("UTC FUll year is " + newDate.getUTCFullYear())
            //console.log("UTC month is " + newDate.getUTCMonth())
            var lastDayOfMonth = new Date(Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth()+1, 0, 0,0,0,0)) //.setUTCHours(0).setUTCMinutes(0).setUTCSeconds(0).setUTCMilliseconds(0).setUTCFullYear(newDate.getUTCFullYear()).setUTCMonth(newDate.getUTCMonth()+1).setUTCDate(0)
            //console.log("Last day of month is " + lastDayOfMonth)
            dateStr = dateFormat(lastDayOfMonth, "UTC:yyyy-mm-dd")
        }
        props.history.push("/landing/" + props.match.params.tab + "/" + dateStr + "/" + props.match.params.userID)
    }

    function handleCustomClick() {
        //send them to the page where they can view custom drh table for a date
        props.history.push("/daily/dailyTable/" + getStoreID() + "/" + getSplitBy() + "/" + props.match.params.date + "/" + props.match.params.date)
    }

    function getStoreID() {
        //get store ID from the props
        if(props.match.params.tab + "" === "0") {
            //no store id on the cards
            return ""
        } else {
            //do a bit of maffs to work out the storeID from the tab
            //do we have allStores?
            var storeIndex = parseInt(props.match.params.tab, 10) - props.allStoresTabCount - 1
            //if this is negative then special cases (all users, all stores, all storeTypes) 
            if(storeIndex < 0) {return props.stores.map(s => s.storeID).join(",")}
            //just lookup the store index
            return ("" + props.stores[storeIndex].storeID)
        }
    }

    function getSplitBy() {
        //get store ID from the props
        if(props.match.params.tab + "" === "0") {
            //no store id on the cards
            return ""
        } else {
            //do a bit of maffs to work out the storeID from the tab
            //do we have allStores?
            var storeIndex = parseInt(props.match.params.tab, 10) - props.allStoresTabCount - 1
            //if this is negative then special cases (all users, all stores, all storeTypes) 
            if(storeIndex === -1) {return "storeStoreTypeID"}
            if(storeIndex === -2) {return "storeID"}
            //just lookup the store index
            return ("attributableToUserID")
        }
    }

    //var stringDate = props.match.params.date
    //console.log("GMT is " + dateFormat(stringDate, "GMT:yyyy-mm-dd HH:MM:ss") + " and UTC is " + dateFormat(stringDate, "UTC:yyyy-mm-dd HH:MM:ss"))

    return(
        <MonthHeader
            monthDate={new Date(dateFormat(props.match.params.date, "UTC:yyyy-mm-01"))}
            onChange={handleDateChange}
            loading={props.loading}
        >
            {
                //if on the tables tabs then show the custom button
                props.match.params.tab + "" === "0" ? null :
                <Button onClick={handleCustomClick}>
                    Custom...
                </Button>
            }
        </MonthHeader>
    );
}
