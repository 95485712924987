import React from 'react'

import DataTable, {addAuditFields, renderCB} from '../../../shared/dataTable/dataTable'
import AnalysisFieldEntityForm from './analysisFieldEntityForm';
import StoreEntityForm from '../stores/storeEntityForm';
import ChipCell from '../../../shared/dataTable/chipCell';

const filters = {
    fkJoins: "analysisListItems,analysisFieldStores,analysisFieldStores.store"
}

function sortByCode(a, b) {
    if (a.code < b.code){
        return -1;
    }
    if (a.code > b.code){
        return 1;
    }
    return 0;
}

const columnData = [
    { fieldName: 'analysisFieldID', default: true, name: 'ID', description: "The unchanging identifier of the analysisField"},
    { fieldName: 'name', default: true, name: 'Name', description: "Name of the analysisField"},
    { fieldName: 'inputType', default: true, name: 'Input type', description: "What type of input this analyssi field expects"},
    { fieldName: 'label', default: false, name: 'Label', description: "Label shown to the user when completing the analysis field"},
    { fieldName: 'defaultValue', default: false, name: 'Default value', description: "Default value when completing this anaylsis field"},
    { fieldName: 'regex', default: false, name: 'Name', description: "String pattern required when completing the field"},
    { fieldName: 'analysisListItems', default: true, name: 'List items', description: "List items the user must select from", renderCell: dr => dr.analysisListItems.sort(sortByCode).map(a => a.code).join(", ")},
    { fieldName: 'analysisFieldStores', default: true, name: 'Stores', description: "Which stores must record this analysis", renderCell: dr => 
    <ChipCell 
        chips={dr.analysisFieldStores.map(s => s.store)} 
        //onDataChanged={onDataChanged} 
        enableCrud={false}
        entityForm={StoreEntityForm}
        //parentEntityForm={StoreTypeEntityForm}
        //parentEntityValue={dataRow.storeTypeID}
    />
    },
    { fieldName: 'required', default: true, name: 'Required', description: "Whether the user is required to complete this field when recording a sale", renderCell: dr => renderCB(dr.required)},
    { fieldName: 'targetRevenue', default: true, name: 'Target revenue', description: "Whether this analysis field can have revenue targets recorded against it", renderCell: dr => renderCB(dr.targetRevenue)},
    { fieldName: 'targetQuantity', default: true, name: 'Target quantity', description: "Whether this analysis field can have quantity targets recorded against it", renderCell: dr => renderCB(dr.targetQuantity)},

];


export default function AnalysisFieldsList(props) {

    return(
        <div>
            <h1>
                Analysis Fields
            </h1>
            <p>
                Analysis fields record extra information when you make a sale.  They can be text, or selection of a single or multiple values from a list.
            </p>
            <DataTable
                columnData={addAuditFields(columnData)}
                entityForm={AnalysisFieldEntityForm}
                
                filters={filters} 
                title="Analysis fields"
                enableCrud={true}
            />
        </div>
    )
}