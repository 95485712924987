import React, {useEffect, useState} from 'react'
import dateFormat from 'dateformat'
import { CircularProgress } from '@material-ui/core'

import DataTable, {addAuditFields} from '../../shared/dataTable/dataTable'
import ColouredIcon from '../../shared/colouredIcon'
import authFetch from '../../../lib/authFetch'
import AnnouncementUserEntityForm from './entityForm/announcementUserEntityForm'

//gets the column data.  Takes into account the settings on the announcement to show/hide relevent columns like acknowledged or clicked
function getColumnData(announcement) {
    var columnData = [
        { fieldName: 'announcementID', default: false, name: 'ID', description: "The unchanging identifier of the announcement"},
        { fieldName: 'userName', default: true, name: 'User', description: "The user who is selected to see the announcement"},
        { fieldName: 'firstSeenDate', default: true, name: 'First Shown', description: "When the user was first shown the annoucement by the system", renderCell: dr => dateFormat(new Date(dr.firstSeenDate), "yyyy-mm-dd") === "2000-01-01" ? "Never" : dateFormat(new Date(dr.firstSeenDate), "dd/mm/yyyy HH:MM:ss") },
        { fieldName: 'lastSeenDate', default: true, name: 'Last Shown', description: "When the user was last shown the annoucement by the system", renderCell: dr => dateFormat(new Date(dr.lastSeenDate), "yyyy-mm-dd") === "2000-01-01" ? "Never" : dateFormat(new Date(dr.lastSeenDate), "dd/mm/yyyy HH:MM:ss") },
    ];
    //we want to show click column?
    if(announcement.clickType !== "none") {
        columnData.push(
            { fieldName: 'clickedDate', default: true, name: 'Clicked', description: "Whether the user has clicked the file or link in the announcement", renderCell: dr => dr.clicked ? <div><ColouredIcon type="success"/>{dateFormat(new Date(dr.clickedDate), "dd/mm/yyyy HH:MM:ss")}</div> : "No"},
        )
    }
    //we want to show acknowledged column?
    if(announcement.acknowledgable) {
        columnData.push(
            { fieldName: 'acknowledgedDate', default: true, name: "OK'd", description: "Whether the user has clicked OK to acknowledge and hide the announcement", renderCell: dr => dr.acknowledged ? <div><ColouredIcon type="success"/>{dateFormat(new Date(dr.acknowledgedDate), "dd/mm/yyyy HH:MM:ss")}</div> : "No"},
        )
    }
    return columnData
}

export default function AnnouncementUsersList(props) {
    const [announcement, setAnnouncement] = useState(null)

    //get the announcement for use in the header, building the columns etc
    useEffect(() => {
        setAnnouncement(null)
        authFetch("/announcements?announcementID=" + props.match.params.announcementID)
        .then(r => {
            setAnnouncement(r.listData[0])
        })
    }, [props.match.params.announcementID])

    return(
        <div>
                {
                    announcement === null ? <CircularProgress size={200} /> :
                    <>
                        <h1>
                            Users Selected To See Announcement {props.match.params.announcementID}
                        </h1>
                        <p>
                            Here are the users who are selected to see the announcement.  If applicable here you can also see if they have clicked or acknowledged the announcement.  <strong>If they have clicked OK (acknowledged) the announcement, or the 'until date' has passed, then it will no longer be showing</strong>.
                        </p>
                        <DataTable
                            columnData={addAuditFields(getColumnData(announcement))}
                            entityForm={AnnouncementUserEntityForm}
                            filters={{announcementID: props.match.params.announcementID}} 
                            title="Users"
                            enableCrud={false}
                            sort="userName"
                            sortDir="asc"
                            me={props.me}
                            enableRead={false}
                        />
                    </>
            }
        </div>
    )
}