import React, {useState, useEffect} from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import ReconcileIcon from '@material-ui/icons/DoneAll'
import { Button } from '@material-ui/core'
import dateFormat from 'dateformat'
import {formatMoney} from '../../../lib/general'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import ManualReconcileIcon from '@material-ui/icons/PlaylistAddCheck'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import LineUploadModal from './lineUpload/lineUploadModal'
import RecSheetLinesTable from './recSheetLinesTable'
import recSheetEntityForm from './recSheetEntityForm'
import authFetch from '../../../lib/authFetch'
import AutoRecModal from './autoRecModal'
import ManualModal from './manualModal'
import DeleteModal from './deleteModal'

export default function SecSheetLines(props) {
    const [uploadOpen, setUploadOpen] = useState(false)
    const [autoRecOpen, setAutoRecOpen] = useState(false)
    const [dataRefreshTime, setDataRefreshTime] = useState(new Date())
    const [recSheet, setRecSheet] = useState(recSheetEntityForm.blankForm)
    const [manualOpen, setManualOpen] = useState(false)
    const [manualIDs, setManualIDs] = useState([])
    const [selectRefreshTime, setSelectRefreshTime] = useState(new Date())
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [deleteIDs, setDeleteIDs] = useState([])

    //get the date card for use in the header etc
    useEffect(() => {
        authFetch("/recSheets?recSheetID=" + props.match.params.recSheetID)
        .then(r => {
            setRecSheet(r.listData[0])
        })
    }, [props.match.params.recSheetID])

    //clear selection when changing tab (filter)
    useEffect(() => {
        setSelectRefreshTime(new Date())
    }, [props.match.params.filter])

    function handleUploadClose() {
        setUploadOpen(false)
    }

    function handleUploadOpen() {
        setUploadOpen(true)
    }

    function handleUploadDataChange() {
        //set the data refresh date prop on the data table to trigger a refresh
        setDataRefreshTime(new Date())
    }

    function handleAutoRecOpen() {
        setAutoRecOpen(true)
    }

    function handleAutoRecClose() {
        //refresh the data since it may have changed
        setAutoRecOpen(false)
        setDataRefreshTime(new Date())
    }

    function handleManualOpen(selected) {
        //console.log("Starting rec of " + JSON.stringify(selected))
        setManualIDs(selected)
        setManualOpen(true)
    }

    function handleManualClose() {
        setManualIDs([])
        setManualOpen(false)
    }

    //function for rendering the actions area when some items are selected
    function selectActions(props) {
        return (
            <>
                <Tooltip title="Start manual reconciliation of these lines">
                    <IconButton aria-label="Start manual reconciliation of these lines" onClick={() => handleManualOpen(props.selected)}>
                        <ManualReconcileIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete the selected rate card lines">
                    <IconButton aria-label="Delete the selected rate card lines" onClick={() => handleDeleteOpen(props.selected)}>
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </>
        );
    }

    function handleDeleteOpen(deleteIDs) {
        setDeleteOpen(true)
        setDeleteIDs(deleteIDs)
    }

    function handleDeleteClose() {
        setDeleteOpen(false)
    }

    function handleDeleteDataChange() {
        setSelectRefreshTime(new Date())
        setDataRefreshTime(new Date())
    }

    return(
        <div>
            <h1>
                Revenue Reconciliation Sheet {dateFormat(recSheet.fromDate, "dd/mm/yyyy")} Lines
            </h1>
            {
                props.me.accessProfile.systemAreas.split(", ").includes("RevenueReconciliation") ?
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<CloudUploadIcon />}
                            style={{margin: "0px 14px 14px 0px"}}
                            onClick={handleUploadOpen}
                        >
                            Upload Revenue Reconciliation Sheet
                        </Button>
                        {
                            props.match.params.filter === "reconciled" ? null :
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<ReconcileIcon />}
                                style={{margin: "0px 14px 14px 0px"}}
                                onClick={handleAutoRecOpen}
                            >
                                Auto Reconcile
                            </Button>
                        }
                        {
                            props.match.params.filter === "reconciled" ? null :
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<ManualReconcileIcon />}
                                style={{margin: "0px 14px 14px 0px"}}
                                onClick={() => handleManualOpen([])}
                            >
                                Manual Reconcile
                            </Button>
                        }
                    </div>
                : null
            }
            <RecSheetLinesTable 
                dataRefreshTime={dataRefreshTime}
                recSheetID={props.match.params.recSheetID}
                filter={props.match.params.filter}
                me={props.me}
                selectActions={props.match.params.filter === "reconciled" ? undefined : selectActions}
                selectRefreshTime={selectRefreshTime}
            />
            <LineUploadModal
                open={uploadOpen}
                recSheetID={props.match.params.recSheetID}
                onClose={handleUploadClose}
                onDataChange={handleUploadDataChange}
                me={props.me}
            />
            <AutoRecModal
                open={autoRecOpen}
                onClose={handleAutoRecClose}
                recSheetID={props.match.params.recSheetID}
                me={props.me}
            />
            <ManualModal
                recSheetID={props.match.params.recSheetID}
                recSheetLineIDs={manualIDs} //inside the modal, if this is an empty array, the api will be called to get all lines
                onClose={handleManualClose}
                open={manualOpen}
                me={props.me}
            />
            <DeleteModal 
                open={deleteOpen}
                recSheetLineIDs={deleteIDs}
                onClose={handleDeleteClose}
                onDataChange={handleDeleteDataChange}
            />

        </div>
    )
}