import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import dateFormat from 'dateformat'
import LookupCell from '../../../shared/dataTable/lookupCell';
import useMediaQuery from '@material-ui/core/useMediaQuery'

//components
import DataTable from '../../../shared/dataTable/dataTable'
import StoreEntityForm from '../stores/storeEntityForm'
import SaleTypeEntityForm from '../saleTypes/saleTypeEntityForm'
import SKUHistoryCell from './skuHistoryCell'

//lib
import authFetch from '../../../../lib/authFetch';
import {formatMoney} from '../../../../lib/general'
import TrendIcon from '../../../shared/trendIcon';

const blankRateCard = {
    rateCardID: 0,
    fromDate: new Date(),
    name: ""
}


export default function RateCardCompareModal(props) {
    const [rateCards, setRateCards] = useState([blankRateCard, blankRateCard])
    const [dataRefreshTime, setDataRefreshTime] = useState(new Date())

    function cols() {
        //done this weird way becasue once upon a time i was going to put other tabs in for comparing RRP, Monthly.  To do
        var columnData = [
            { fieldName: 'storeName', default: true, name: 'Store', description: "The store", renderCell: (dr) => 
                <LookupCell 
                    id={dr.storeID} 
                    entityForm={StoreEntityForm}
                    label={dr.storeName}
                />
            },
            { fieldName: 'saleTypeName', default: true, name: 'Sale type', description: "The sale type", renderCell: (dr) => 
                <LookupCell 
                    id={dr.saleTypeID} 
                    entityForm={SaleTypeEntityForm}
                    label={dr.saleTypeName}
                />
            },
            { fieldName: 'sku', default: true, name: 'SKU', description: "The SKU", renderCell: dr => <SKUHistoryCell sku={dr.sku} /> },
            { fieldName: 'description', default: true, name: 'Description', description: "Description of the SKU"},
        ]
        addCols(columnData, "revenue", "Revenue")
        return columnData
    }

    function addCols(c, fieldName, displayName) {
        //first show the amounts
        [0, 1].forEach(n => {
            c.push({
                fieldName: fieldName + n, 
                default: true, 
                name: displayName + " " + dateFormat(rateCards[n].fromDate, "dd/mm/yyyy"), 
                description: displayName + " for the rate card effective " + dateFormat(rateCards[n].fromDate, "dd/mm/yyyy"), 
                renderCell: (dr) => formatMoney(dr[fieldName + n])
            })
        })
        //now add a difference
        c.push({
            fieldName: fieldName + "Difference", 
            default: true, 
            name: "Difference", 
            description: fieldName + " difference between the 2 rate cards", 
            renderCell: (dr) => <div><TrendIcon value={dr[fieldName + "Difference"]}/>{formatMoney(dr[fieldName + "Difference"])}</div>
        })
        //and a percentage difference
        c.push({
            fieldName: fieldName + "DifferencePercent", 
            default: true, 
            name: "Difference %", 
            description: fieldName + " difference percent between the 2 rate cards", 
            renderCell: (dr) => <div><TrendIcon value={dr[fieldName + "DifferencePercent"]}/>{formatMoney(dr[fieldName + "DifferencePercent"] * 100, 1) + "%"}</div>
        })
    }

    function handleEnter() {
        var newRCs = [...rateCards]
        authFetch("/rateCards?rateCardID=" + props.rateCardIDs[0])
        .then(r => {
            newRCs[0] = r.listData[0]
            return authFetch("/rateCards?rateCardID=" + props.rateCardIDs[1])
        })
        .then(r => {
            newRCs[1] = r.listData[0]
            setRateCards(newRCs)
        })
    }

    const noFullscreen = useMediaQuery("(min-width:1000px)")

    return(
        <Dialog open={props.open} maxWidth="lg" fullScreen={!noFullscreen} fullWidth={true} aria-labelledby="form-dialog-title" onEnter={handleEnter}>
            <DialogTitle id="form-dialog-title">
                Comparing {dateFormat(rateCards[0].fromDate, "dd/mm/yyyy")} and {dateFormat(rateCards[1].fromDate, "dd/mm/yyyy")}
            </DialogTitle>
            <DialogContent style={{minHeight: "250px"}}>
                <p>The order of the columns is the order they were selected in the previous screen.</p>
                <DataTable
                    columnData={cols()}
                    dataRefreshTime={dataRefreshTime}
                    filters={{rateCardIDs: props.rateCardIDs.join(",")}} 
                    title="Revenue Comparison"
                    enableCrud={false}
                    getListURL={"/rateCardCompare"}
                    idField="sku"
                    makeKeyFromDataRow={dr => dr.storeID + "-" + dr.sku + "-" + dr.saleTypeID}
                    me={props.me}
                />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
    )
}