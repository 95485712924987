import React, {useState, useRef} from 'react'
import {formatMoney} from '../../../lib/general'
import TableCell from '@material-ui/core/TableCell'
import Popover from '@material-ui/core/Popover'
import FormHelperText from '@material-ui/core/FormHelperText'

export default function TotalCell(props) {
    const [closed, setClosed] = useState(false)
    const anchorEl = useRef(null);

    function getTotal() {
        return formatMoney((props.totalTargets || []).reduce((acc, cur) => acc + (parseFloat(cur.target) + "" === "NaN" ? 0 : parseFloat(cur.target)), 0), 0)
    }

    function getError() {
        return (props.targets || []).reduce((acc, cur) => acc + (cur.error === undefined || cur.error === "" ? "" : cur.error), "")
    }

    return (
        <TableCell ref={anchorEl} style={{backgroundColor: props.backgroundColor, padding: "4px"}} align="right">
            {
                props.bold ?
                    <strong>
                        {getTotal()}
                    </strong>
                :
                    getTotal()
            }
            {
                getError() === "" ? null :
                <FormHelperText error={true} >
                    {getError()}
                </FormHelperText> 
            }
            <Popover
                open={!closed && props.highlightTargetID !== undefined && props.highlightTargetID !== 0 && (props.targets || []).map(t => t.targetID + "").includes(props.highlightTargetID + "")}
                anchorEl={anchorEl.current}
                onClose={() => setClosed(true)}
                onClick={() => setClosed(true)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{padding: "18px"}}>
                    The target you clicked
                </div>
            </Popover>
        </TableCell>
    )
}