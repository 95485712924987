import React from 'react'

import ExcelIcon from '@material-ui/icons/GridOnOutlined'
import NewIcon from '@material-ui/icons/OpenInNewOutlined'
import { Button } from '@material-ui/core'

export default function APIHowToLinks(props) {
    return (
        <div style={{width: "100%"}}>
            <a href="https://www.mtssoftware.co.uk/blog/create-reports-using-excel" target="_blank" style={{textDecoration: "none"}}>
                <Button
                    startIcon={<NewIcon />}
                    style={{margin: "5px 10px 10px 0px"}}
                >
                    How to use in Excel 
                </Button>
            </a>
            <a href="https://www.mtssoftware.co.uk/blog/create-reports-using-power-bi" target="_blank" style={{textDecoration: "none"}}>
                <Button
                    startIcon={<NewIcon />}
                    style={{margin: "5px 10px 10px 0px"}}
                >
                    How to use in Power BI 
                </Button>
            </a>
        </div>
    )
}