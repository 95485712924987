import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import dateFormat from 'dateformat'

import Alert from '@material-ui/lab/Alert';
import DatePicker from '../../shared/form/datePicker'
import authFetch from '../../../lib/authFetch'

export default function CopyModal(props) {
    const [newTargets, setNewTargets] = useState([])
    const [fromDate, setFromDate] = useState(new Date())
    const [loading, setLoading] = useState(false)

    function handleChange(e) {
        //console.log("Hadnlign dae")
        setFromDate(e.target.value)
        //cancel any previous requests
        var newDate
        try {
            newDate = new Date(e.target.value)
        } catch(err) {
            //is not a date
            //console.log("Date err " + err)
        }
        if(newDate === undefined) {
            //console.log("Dae no conv")
            //could not convert the date
            setNewTargets([])
        } else {
            //we have a date, make api call
            loadTargets(newDate)
        }
    }

    function loadTargets(forDate) {
        if(props.open) {
            setLoading(true)
            //console.log("Loading dae")
            authFetch("/targets?storeID=" + props.store.storeID + "&targetDate=" + dateFormat(forDate, "yyyy-mm-01"))
            .then(r => {
                setLoading(false)
                setNewTargets(r.listData.map(t => ({
                    ...t,
                    targetDate: props.targetTargetDate
                })))
            })
            .catch(err => {
                setNewTargets([])
            })    
        }
    }

    function handleEnter() {
        loadTargets(new Date())
    }

    const noFullscreen = useMediaQuery("(min-width:1000px)")

    function handleAction() {
        props.onTargetsChange(newTargets)
        props.onClose()
    }

    return(
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth="xs" fullWidth={true} aria-labelledby="form-dialog-title" onEnter={handleEnter}>
            <DialogTitle id="form-dialog-title">
                Copy targets to {dateFormat(props.targetTargetDate, "mmm yyyy")}
            </DialogTitle>
            <DialogContent>
                <DatePicker 
                    id="fromDate"
                    label="Choose a day within the month to copy FROM"
                    onChange={handleChange}
                    value={fromDate}
                    //error={props.validationResult.failMessages.hasOwnProperty("fromDate")}
                    //helperText={props.validationResult.failMessages["fromDate"]}
                    disabled={props.disabled}
                />
                {
                    props.targets.length === 0 ? null :
                    <Alert severity="warning">
                        If you continue the {props.targets.length} current targets for {props.store.name} {dateFormat(props.targetTargetDate, "mmm-yyyy")} will be overwritten
                    </Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
                <Button 
                    color="primary" 
                    onClick={handleAction} 
                    variant="contained"
                    disabled={loading || newTargets.length === 0}
                >
                    {loading ? "Loading..." : "Copy " + newTargets.length + " targets"}
                </Button>
            </DialogActions>
      </Dialog>
    )
}