import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import OrderRefField from './orderRefField'


export default function RefundModal(props) {
    const [note, setNote] = useState("")
    const [orderRef, setOrderRef] = useState("")
    const [noteError, setNoteError] = useState("")
    const [orderRefError, setOrderRefError] = useState("")

    function handleRefundSale() {
        var ok = true
        if(note === "") {
            ok = false
            setNoteError("You must explain the reason for cancelling")
        }
        if(orderRef.length !== 20) {
            ok = false
            setOrderRefError("Should be 20 characters")
        }
        if(ok) {
            //ok, we are cancelling
            props.onRefundSale(note, orderRef)
            props.onClose()
        }
    }

    const noFullscreen = useMediaQuery("(min-width:1000px)")

    return(
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth="xs" fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Refund Sale
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="note"
                    label="Refund reason (required)"
                    type="text"
                    value={note}
                    onChange={e => setNote(e.target.value)}
                    error={noteError !== ""}
                    helperText={noteError === "" ? undefined : noteError}
                    fullWidth
                />
                <strong>Please record the refund reference exactly as it appears on the PoS system</strong>
                <OrderRefField
                    id="orderRef"
                    label="Refund reference (20 digits)"
                    value={orderRef}
                    onChange={e => setOrderRef(e.target.value)}
                    error={orderRefError !== ""}
                    helperText={orderRefError === "" ? undefined : orderRefError}
                />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
                <Button color="secondary" onClick={handleRefundSale} variant="contained">
                    Refund Sale
                </Button>
            </DialogActions>
      </Dialog>
    )
}