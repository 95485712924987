import React from 'react'

import FileUploader from '../../../shared/form/fileUploader'

export default function Step0(props) {
    function handleChange(event) {
        //merge it with the existing form vals and call the parent handler function
        var newFormVals = {...props.formVals, [event.target.id]: event.target.value}
        //console.log(JSON.stringify(newFormVals))
        props.onChange(newFormVals)
    }


    return(
        <div>
            <h4>
                Choose the file
            </h4>
            <p>Pick the Excel file containing the reconciliation sheet lines to upload</p>
            <p>The system will look at all worksheets in the file, and you will have chance to map each one separately</p>
            <p>The first row must contain the headers</p>
            <FileUploader 
                onChange={handleChange} 
                value={props.formVals.fileID}
                id="fileID"
                disabled={false}
                error={props.validationResult.failMessages.hasOwnProperty("fileID")}
                helperText={props.validationResult.failMessages["fileID"]}
                library="recSheetLineUploads"
                label="Rec sheet line Excel file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                urlType="excel"
            />
        </div>
    )
}