import React, {useState, useRef} from 'react'
import { TextField } from '@material-ui/core'
import {targetsAreEqual} from './targetGrid'
import {formatMoney} from '../../../lib/general'
import Popover from '@material-ui/core/Popover'
import OptionalTooltip from '../../shared/optionalTooltip'

export default function TargetGridCell(props) {
    const [closed, setClosed] = useState(false)
    const anchorEl = useRef(null);

    function handleChange(e) {
        var rt = new RegExp("^[0-9]*$")
        if(rt.test(e.target.value)) {
            //if there is a target, update it.  If not, add it
            if(props.targets.length === 1) {
                //update it with the new value
                props.onChange(
                    props.allTargets.map(t => ({
                        ...t,
                        target: (targetsAreEqual(props.targets[0], t) ? e.target.value : t.target)
                    }))
                )
            } else {
                //add relevent targets with isTarget=true
                props.onChange(
                    props.allTargets.concat(
                        {
                            ...props.blankTarget,
                            target: e.target.value
                        }
                    )
                )
            }
        }
    }

    var value
    if(props.view === "set") {
        value = ((props.targets || []).length === 1 ? (props.disabled ? formatMoney(props.targets[0].target, 0) : props.targets[0].target) : "0")
    }
    if(props.view === "progress") {
        value = ((props.targets || []).length === 1 && props.targets[0].isTarget ? formatMoney(props.targets[0].actual, 0) : "n/a")
    }
    if(props.view === "variance") {
        value = ((props.targets || []).length === 1 && props.targets[0].isTarget ? formatMoney(props.targets[0].actual - props.targets[0].target, 0) : "n/a")
    }

    return (
        <>
            <OptionalTooltip
                tooltip={props.tooltip}
            >
                <TextField 
                    inputProps={{
                        style: {textAlign: "right"}
                    }}
                    margin="dense"
                    label={props.targetType === "revenue" ? "Rev" : "Qty"}
                    type="text"
                    value={value}
                    onChange={handleChange}
                    error={(props.targets || []).length === 1 ? (props.targets[0].error !== undefined && props.targets[0].error + "" !== "") : false}
                    helperText={(props.targets || []).length === 1 ? props.targets[0].error : ""}
                    fullWidth
                    ref={anchorEl}
                    disabled={props.disabled}
                />
            </OptionalTooltip>
            <Popover
                open={!closed && props.highlightTargetID !== undefined && props.highlightTargetID !== 0 && (props.targets || []).map(t => t.targetID + "").includes(props.highlightTargetID + "")}
                anchorEl={anchorEl.current}
                onClose={() => setClosed(true)}
                onClick={() => setClosed(true)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{padding: "18px"}}>
                    The target you clicked
                </div>
            </Popover>
        </>
    )
}