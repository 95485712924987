import React, {useEffect, useState} from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import authFetch from '../../../../lib/authFetch';
import { Tooltip } from '@material-ui/core';
import HistoryModal from './historyModal';



export default function SampleProgressForm(props) {
    const blankSteps = [
        {
            name: "unknown",
            displayName: "New " + props.entityDisplayName,
            description: "New " + props.entityDisplayName,
            applicable: true,
            justification: "Default placeholder"
        }
    ]
    
    //const classes = useStyles();
    const [historyOpen, setHistoryOpen] = useState(false)
    const [steps, setSteps] = useState(blankSteps);

    useEffect(() => {
        //make a call to get the steps that apply to this document
        authFetch(props.getStepsURL)
        .then(r => {
            //whether validation passed or not, use the listData return as the steps
            setSteps(r.listData)
        })
        .catch(err => {
            //could not get the steps for some reason
            setSteps(blankSteps)
        })
    }, [props.documentID, props.getStepsURL])

    function getSteps() {
        //we must make sure the steps includes the one in currentHistory in order to avoid misleading the user
        if(steps.filter(s => s.applicable).map(s => s.name).includes(props.currentHistoryStep)) {
            return steps
        } else {
            //we need to add the current step to the array to avoid misleading the user
            var newSteps = [...steps,
                {
                    name: props.currentHistoryStep, 
                    displayName: props.currentHistoryStep + " (irregular)", 
                    description: "Unknown step",
                    applicable: true,
                    justification: "Step added because the document says it is at this status despite the server not including it in the list of valid steps"
                }
            ]
            return newSteps
        }
    }

    function handleHistoryOpen(isOpen) {
        setHistoryOpen(isOpen)
    }

    //console.log("Currenthistory: " + JSON.stringify(props.currentHistory))

    return (
        <div>
            <Tooltip title="Click for history">
                <div onClick={() => handleHistoryOpen(true)}>
                    <Stepper 
                        style={{padding: "10px 0px 0px 0px"}} activeStep={
                            //props.currentHistory === undefined ? 0 :
                            getSteps().filter(s => s.applicable).reduce((acc, cur, i) => cur.name === props.currentHistoryStep ? i : acc, 0)
                        } 
                        alternativeLabel={props.alternativeLabel}
                    >
                            {getSteps().filter(s => s.applicable).map((step, i) => (
                                <Step key={step.name + ""} completed={i < getSteps().filter(s => s.applicable).reduce((acc, cur, i) => cur.name === props.currentHistoryStep ? i : acc, 0)}>
                                    <StepLabel error={step.name === "cancelled"}>{step.displayName}</StepLabel>
                                </Step>
                            ))}
                    </Stepper>
                </div>
            </Tooltip>
            <HistoryModal 
                steps={getSteps()} 
                //currentHistory={props.currentHistory} 
                documentID={props.documentID} 
                open={historyOpen} 
                onClose={() => handleHistoryOpen(false)} 
                documentType={props.documentType}
                //entityName={props.entityName}
                entityDisplayName={props.entityDisplayName}
            />
        </div>
    );
}