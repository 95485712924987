import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link, Route} from 'react-router-dom'
import TargetsToolbarViews from './targetsToolbarViews'

export default function TargetsToolbar(props) {

    return (
        <Route path="/targets/:view/:targetDate/:storeID" component={TargetsToolbarViews} />
    );
}
