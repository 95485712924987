import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid'

export default function MultipleModal(props) {
    if(props.multipleData.rateCardLineStores === undefined) {
        return (null)
    }

    return(
        <Dialog open={props.open} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Select the SKU
            </DialogTitle>
            <DialogContent>
                <Grid spacing={1} container>
                    {
                        props.multipleData.rateCardLineStores.map(rcls => 
                            <Grid key={rcls.rateCardLineStoreID} item xs={12} style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <Button
                                        variant="contained"
                                        onClick={() => props.onClose(rcls.rateCardLineStoreID)}
                                        color="primary"
                                    >
                                        {rcls.sku}
                                    </Button>
                                </div>
                                <div style={{display: "flex", flexGrow: 1, flexFlow: "row wrap", alignItems: "center", paddingLeft: "5px"}}>
                                    {rcls.description}
                                </div>
                            </Grid>
                        )
                    }
                    
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={() => props.onClose()} variant="contained">
                    Cancel
                </Button>
            </DialogActions>
      </Dialog>
    )
}