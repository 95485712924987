import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom'

export default function UsersToolbar(props) {
    //calculate the tab value
    let tabValue = false
    //console.log("PLP is " + props.location.pathname + " and match is ")
    if(props.location.pathname === "/data/users") {tabValue = 0}
    if(props.location.pathname === "/data/users/inactive") {tabValue = 1}
    if(props.location.pathname === "/data/users/all") {tabValue = 2}
    if(props.location.pathname === "/data/users/accessProfiles") {tabValue = 3}
    if(props.location.pathname === "/data/users/loginBackground") {tabValue = 4}

    return (
        props.me.accessProfile.systemAreas.split(", ").includes("UserSetup") ?
            <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
                <Tab label="Active users" component={Link} to={"/data/users"} />
                <Tab label="Inactive users" component={Link} to={"/data/users/inactive"} />
                <Tab label="All Users" component={Link} to={"/data/users/all"} />
                <Tab label="Access Profiles" component={Link} to={"/data/users/accessProfiles"} />
                <Tab label="Login background" component={Link} to={"/data/users/loginBackground"}/>
            </Tabs>
        :
            <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
                <Tab label="Active users" component={Link} to={"/data/users"} />
            </Tabs>
    );
}
