import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {appearsLoggedIn} from './lib/auth'

//project
import leftMenu from './components/shared/menu/leftMenu'
import login from "./components/pages/user/login"
import UrlRedirect from './components/pages/announcements/urlRedirect'

export default function App(props) {

    //get app name from API
    useEffect(() => {
        //prefix the API url
        let url = (window.location.href + "salt").replace(window.location.pathname + "salt", "/api") + "/appName"
        //return a normal fetch and auth check
        return fetch(url, {
            method: "GET",
            headers: {
                ["Content-Type"]: "application/json"
            }
        })
        .then(response => response.json())
        .then(r => document.title = r.data)
        .catch(err => {
            console.log(err)
        })
    }, [])

    return (
            <BrowserRouter>
                <Switch>
                    <Route path="/announcement/urlRedirect/:announcementID" render={renderProps => <UrlRedirect {...renderProps} me={props.me} />} />
                    <Route path="/" component={appearsLoggedIn() === 0 ? login : leftMenu} />
                </Switch>
            </BrowserRouter>
        );
}

