import React, {useState} from 'react'
import { Divider, Button } from '@material-ui/core'
import APIIcon from '@material-ui/icons/AccountTreeOutlined'
import APIModal from './apiModal'

export default function APIFooter(props) {
    const [open, setOpen] = useState(false)

    //if they dont have api access return nothing, they wont be able to use it
    if(props.me.accessProfile.systemAreas.split(", ").includes("AccessAPI")) {
        return (
            <>
                <Divider style={{marginTop: "28px", marginBottom: "12px"}} />
                <Button 
                    startIcon={<APIIcon/>}
                    onClick={() => setOpen(true)}
                >
                    {props.details.name} API details
                </Button>
                <APIModal
                    onClose={() => setOpen(false)}
                    open={open}
                    details={props.details}
                />
            </>
        )
    } else {
        //no api access
        return null
    }
}