import React, {useState} from 'react'
import {Route, Switch} from 'react-router-dom'

import AllUsersList from './allUsersList'
import ActiveUsersList from './activeUsersList'
import InactiveUsersList from './inactiveUsersList'
import AccessProfilesList from './accessProfilesList'
import LoginBackground from './background/loginBackground'

export default function Users(props) {
    if(props.me.accessProfile.systemAreas.split(", ").includes("UserSetup")) {
        return (
            <Switch>
                <Route path="/data/users/inactive" render={routeProps => <InactiveUsersList {...routeProps} me={props.me} />} />
                <Route path="/data/users/all" render={routeProps => <AllUsersList {...routeProps} me={props.me} />} />
                <Route path="/data/users/accessProfiles" render={routeProps => <AccessProfilesList {...routeProps} me={props.me} />} />
                <Route path="/data/users/loginBackground" render={routeProps => <LoginBackground {...routeProps} me={props.me} />} />
                <Route path="/data/users" render={routeProps => <ActiveUsersList {...routeProps} me={props.me} />} />
            </Switch>
        )
    } else {
        return (
            <Switch>
                <Route path="/data/users" render={routeProps => <ActiveUsersList {...routeProps} me={props.me} />} />
            </Switch>
        )
    }
}