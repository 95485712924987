import React, {useState, useEffect} from 'react'
import {Route, Switch, Link} from 'react-router-dom'
import Alert from '@material-ui/lab/Alert'
import CircularProgress from '@material-ui/core/CircularProgress'

import dateFormat from 'dateformat'
import authFetch from '../../../../lib/authFetch'
import RateCardLinesTable from './rateCardLinesTable'
import RateCardLinesApiDate from './rateCardLinesApiData'
import saleTypeEntityForm from '../../data/saleTypes/saleTypeEntityForm'
import SaleTypeTabs from './saleTypeTabs'
import RevenueCommissionSwitchTitle from '../../../shared/title/revenueCommissionSwitchTitle'
import {byName} from '../../../../lib/general'

const blankRateCard = {
    rateCardID: 0,
    fromDate: new Date(),
    nextRateCard: {
        rateCardID: 0,
        fromDate: new Date()
    }
}

export default function CurrentRateCard(props) {
    const [rateCard, setRateCard] = useState(blankRateCard)
    const [loading, setLoading] = useState(true)
    const [dataRefreshTime, setDataRefreshTime] = useState(new Date())
    const [saleType, setSaleType] = useState(saleTypeEntityForm.blankForm)

    useEffect(() => {
        setLoading(true)
        if(props.me !== undefined && Array.isArray(props.me.saleTypes) && [props.me.saleTypes.length > 0]) {
            setSaleType(props.me.saleTypes[0])
        }
        var proms = []
        //get the current rate card
        proms.push(
            authFetch("/rateCard/current")
            .then(r => {
                setRateCard(r.data)
            })
        )
        Promise.all(proms)
        .then(na => {
            setLoading(false)
        })
    }, [])

    function handleSaleTypeChange(newSaleType) {
        setSaleType(newSaleType)
    }

    if(props.me !== undefined && Array.isArray(props.me.saleTypes) && [props.me.saleTypes.length > 0]) {
        return(
            loading ? 
                <CircularProgress size={250} />
            :
                rateCard.rateCardID === 0 ?
                    <Alert severity="error" style={{marginBottom: "10px"}}>There is no active rate card!  Sales cannot be recorded!</Alert>
                :
                    <div>
                        <RevenueCommissionSwitchTitle
                            me={props.me}
                            title={"Current Rate Card (since " + dateFormat(rateCard.fromDate, "dd/mm/yyyy") + ")"}
                        />
                        {
                            rateCard.nextRateCard.rateCardID === 0 ? undefined :
                            <Alert severity="info" style={{marginBottom: "10px"}}>
                                A <Link to={"/data/rateCards/" + rateCard.nextRateCard.rateCardID + "/lines"} >new rate card</Link> becomes effective on <strong>{dateFormat(rateCard.nextRateCard.fromDate, "dd/mm/yyyy")}</strong>
                            </Alert>
                        }
                        <SaleTypeTabs
                            onChange={handleSaleTypeChange}
                            saleTypes={props.me.saleTypes.sort(byName)}
                            saleType={saleType}
                            blankForm={saleTypeEntityForm.blankForm}
                            rateCardID={rateCard.rateCardID}
                            me={props.me}
                        />
                        <RateCardLinesTable 
                            dataRefreshTime={dataRefreshTime}
                            rateCardID={rateCard.rateCardID}
                            enableCrud={false}
                            saleType={saleType}
                            me={props.me}
                        />
                        <RateCardLinesApiDate me={props.me} />
                    </div>
        )
    } else {
        return (null)
    }
}