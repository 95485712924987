import React, {useEffect} from 'react'

import TransactionKPICardSet from './transactionKPICardSet'
import TargetKPICardSet from './targetKPICardSet'
import UserTitle from './cardTitles/userTitle'
import StoreTitle from './cardTitles/storeTitle'
import AllTitle from './cardTitles/allTitle'
import MetricPartCards from './metricPartCards'
import CardParams from './cardParams'

import authFetch from '../../../../lib/authFetch'
import { accessibleUsers } from '../../../../lib/general'

export default function Cards(props) {
    //check if the userID param is passed?
    if(props.match.params.userID === undefined) {
        props.history.push("/landing/0/" + props.match.params.date + "/" + props.me.userID)
        //console.log("Redirecting")
        return ("Redirecting...")
    }

    //on first load, and if any of the params change, reload the kpiCard
    useEffect(() => {
        //console.log("Firing api")
        //make the API call
        props.onLoadingChange(true)
        authFetch("/kpi/cards?date=" + props.match.params.date + "&userID=" + props.match.params.userID)
        .then(r => {
            props.onLoadingChange(false)
            props.onNewKpiCard(r.data)
        })
        .catch(err => {
            props.onLoadingChange(false)
        })
    }, [props.match.params.userID, props.match.params.date])


    //is the cards kpi loaded in yet?
    if(props.kpiCard === null || props.kpiCard === undefined) {
        return (
            <div style={{minHeight: "800px"}}>
                <p>Loading cards...</p>
            </div>
        )
    }

    const allUsers = accessibleUsers(props.me)

    return(
        <>
            <div>
                <CardParams
                    me={props.me}
                    hidden={props.hidden}
                    onChangeHidden={props.onChangeHidden}
                    userID={props.match.params.userID}
                    onCardUsersChange={props.onCardUsersChange}
                />
                {
                    props.kpiCard.targets === undefined || props.kpiCard.targets.length === 0 ? null :
                    <>
                        <TargetKPICardSet
                            value={props.kpiCard.targets}
                            minHeight={"122px"}
                            me={props.me}
                            hidden={props.hidden}
                            onAddHidden={props.onAddHidden}
                            loading={props.loading}
                        />
                    </>
                }
                {
                    props.kpiCard.user === undefined ? null :
                        props.kpiCard.user.map((u, i) => {
                            var thisUser = allUsers.filter(au => au.userID + "" === u.day.current.params.attributableToUserID + "")
                            return (
                                <TransactionKPICardSet
                                    key={i}
                                    value={u}
                                    minHeight={"182px"}
                                    me={props.me}
                                    userName={thisUser.length === 0 ? "(Username not found)" : thisUser[0].name}
                                    title={UserTitle}
                                    onAddHidden={props.onAddHidden}
                                    setNamePrefix="user"
                                    hidden={props.hidden}
                                    loading={props.loading}
                                />
                            )
                        })
                }
                {
                    props.kpiCard.store === undefined ? null :
                        props.kpiCard.store.map((s, i) => {
                            var thisStore = props.stores.filter(as => as.storeID + "" === s.day.current.params.storeID + "")
                            return (
                                <TransactionKPICardSet
                                    key={i}
                                    value={s}
                                    minHeight={"182px"}
                                    me={props.me}
                                    title={StoreTitle}
                                    storeName={thisStore.length === 0 ? "(Store name not found)" : thisStore[0].name}
                                    onAddHidden={props.onAddHidden}
                                    setNamePrefix={s.day.current.params.storeID + "store"}
                                    hidden={props.hidden}
                                    loading={props.loading}
                                />
                            )
                        })
                }
                {
                    props.kpiCard.myStores === undefined ? null :
                    <TransactionKPICardSet
                        value={props.kpiCard.myStores}
                        minHeight={"215px"}
                        me={props.me}
                        title={AllTitle}
                        onAddHidden={props.onAddHidden}
                        setNamePrefix="allStore"
                        hidden={props.hidden}
                        loading={props.loading}
                    />
                }
            </div>
            <MetricPartCards
                hidden={props.hidden}
                onAddHidden={props.onAddHidden}
                kpiCard={props.kpiCard}
                me={props.me}
                loading={props.loading}
                date={props.match.params.date}
                stores={props.stores}
                allUsers={allUsers}
            />
        </>
    )
}
