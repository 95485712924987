import React from 'react'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import {Route} from 'react-router-dom'

//icons
import ReceiptIcon from '@material-ui/icons/ShoppingCartOutlined'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

import dateFormat from 'dateformat'

import RevenueCommissionSwitchTitle from '../../shared/title/revenueCommissionSwitchTitle'
import LandingContent from './landingContent'
import authFetch from '../../../lib/authFetch'
import QuickAnnounce from './quickAnnounce'
import AnnouncementViewer from './announcementViewer'

export default function landingPage(props) {

    function handleQuickClick(newLocation) {
        props.history.push(newLocation)
    }

    //on opening this page, if we have no params, redirect to the cards tab for today
    if(props.location.pathname === "/" || props.location.pathname === "") {
        //need to redirect
        //console.log("Redirecting to parameter page...")
        redirectToDefault()
    }

    function redirectToDefault() {
        authFetch("/user/userPreference/defaults/cardUsers")
        .then(r => {
            if(r.validationResult.fail || r.data === "") {
                //no mappings
                return Promise.reject("No preference")
            } else {
                //there is something
                return Promise.resolve(r.data)
            }
        })
        .then(r => {
            //send to the preference
            props.onCardUsersChange(r) //setting this makes the url of the title change
            redirectToUserIDs(r)
        })
        .catch(err => {
            //no preference, sending to the current user page
            //console.log("Err " + err)
            redirectToUserIDs(props.me.userID)
        })
    }

    function redirectToUserIDs(userIDs) {
        props.history.push("/landing/0/" + dateFormat(new Date(), "yyyy-mm-dd") + "/" + userIDs)
    }

    return(
        <div>
            <AnnouncementViewer
                me={props.me}
                myAnnouncements={props.myAnnouncements}
                refreshMyAnnouncements={props.refreshMyAnnouncements}
            />
            <RevenueCommissionSwitchTitle
                me={props.me}
                title={<>Quick Actions<QuickAnnounce me={props.me}/></>}
            />
            <Button 
                style={{margin: "2px 10px 2px 0px", width: "125px"}} 
                variant="contained" 
                color="primary" 
                onClick={() => handleQuickClick("/sales")} 
                startIcon={<ReceiptIcon />}
            >
                Sales
            </Button>
            <Button 
                style={{margin: "2px 10px 2px 0px", width: "125px"}} 
                variant="contained" 
                color="default" 
                onClick={() => handleQuickClick("/sales/calculator")} 
                startIcon={<MonetizationOnOutlinedIcon />}
            >
                Calc
            </Button>
            <Button 
                style={{margin: "2px 0px", width: "125px", whiteSpace: "nowrap"}} 
                variant="contained" 
                color="default" 
                onClick={() => handleQuickClick("/daily/dailyReport")} 
                startIcon={<AssessmentOutlinedIcon />}
            >
                Daily rep
            </Button>
            <Divider variant="middle" style={{marginTop: "16px"}} />
            {
                props.me === undefined || props.me.userID + "" === "0" ? <p>Loading user profile...</p> : //not showing the kpi stuff until we have a props.me to base it off
                    <Route 
                        path={"/landing/:tab/:date/:userID"} 
                        render={routeProps => 
                            <LandingContent
                                {...routeProps}
                                me={props.me}
                                onAddHidden={props.onAddHidden}
                                onChangeHidden={props.onChangeHidden}
                                hidden={props.hidden}
                                kpiCard={props.kpiCard}
                                onNewKpiCard={props.onNewKpiCard}
                                tableSets={props.tableSets}
                                onNewTableSet={props.onNewTableSet}
                                onCardUsersChange={props.onCardUsersChange} 
                            />
                        } 
                    />
            }
        </div>
    );
}
