import React from 'react'
import {Route, Switch} from 'react-router-dom'

import PlannerPage from './plannerPage'

export default function Rota(props) {
    return(
        <Switch>
            <Route path="/rota/plan" render={renderProps => <PlannerPage {...renderProps} me={props.me} />} />
        </Switch>
    )
}