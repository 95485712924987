import React from 'react'
import {Route} from 'react-router-dom'

import DiscountsList from './discountsList'

export default function Discounts(props) {
    return(
        <div>
            <Route path="/data/discounts" render={renderProps => <DiscountsList {...renderProps} me={props.me} />} />
        </div>
    )
}