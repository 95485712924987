import React from 'react'
import TableCell from '@material-ui/core/TableCell';

import ColsStore from './colsStore';

export default function ColsStoreSet(props) {
    if(props.rowType === "header1") {
        return (
            props.store.targetRevenue || props.store.targetQuantity ? 
                <TableCell colSpan={props.store.targetRevenue && props.store.targetQuantity ? 4 : 2} align="center" style={{backgroundColor: "rgb(241,241,241)", padding: "4px"}}>
                    <strong>
                        {props.store.name} Store
                    </strong>
                </TableCell>
            : null
        )
    }
    return (
        <>
            { //store, if store rev or store qty is ticked
                props.store.targetRevenue ? 
                    <ColsStore
                        {...props}
                        blankTarget={{
                            ...props.blankTarget,
                            targetType: "revenue"
                        }}
                        targetType="revenue"
                        targets={(props.targets || []).filter(t => t.targetType === "revenue")}
                        totalTargets={(props.totalTargets || []).filter(t => t.targetType === "revenue")}
                        hoursInput={props.hoursInput}
                    />
                : null
            }
            { //store, if store rev or store qty is ticked
                props.store.targetQuantity ? 
                    <ColsStore
                        {...props}
                        blankTarget={{
                            ...props.blankTarget,
                            targetType: "quantity"
                        }}
                        targetType="quantity"
                        targets={(props.targets || []).filter(t => t.targetType === "quantity")}
                        totalTargets={(props.totalTargets || []).filter(t => t.targetType === "quantity")}
                        hoursInput={props.hoursInput}
                    />
                : null
            }
        </>
    )
}