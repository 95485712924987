import React from 'react'
import dateFormat from 'dateformat'
import TextField from '@material-ui/core/TextField'

import DatePicker from '../../shared/form/datePicker'

export default {
    displayName: "Revenue Rec Sheet",
    idField: "recSheetID",

    getURL: id => "/recSheets?recSheetID=" + id,
    putURL: id => "/recSheet/" + id,
    deleteURL: id => "/recSheet/" + id,
    postURL: "/recSheet",
    getListURL: "/recSheets",
    maxWidth: "xs",
    recordName: formVals => {
        var dateBit = ""
        try {
            dateBit = " (" + dateFormat(new Date(formVals.recDate), "dd/mm/yyyy") + ")"
        } catch (err) {
            //could not make a date
        }
        return formVals.name + dateBit
    },

    blankForm: {
        recSheetID: 0,
        name: "", 
        recDate: dateFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), "yyyy-mm-dd") + "T00:00:00.000Z"
    },
    form : function (props) {
        function handleChange(event) {
            //merge it with the existing form vals and call the parent handler function
            props.onChange({...props.formVals, [event.target.id]: event.target.value})
        }

        //console.log("Date is " + props.formVals.fromDate.toString())
    
        return (
            <div>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    value={props.formVals.name}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("name")}
                    helperText={props.validationResult.failMessages["name"]}
                    fullWidth
                    disabled={props.disabled}
                />
                <DatePicker 
                    id="recDate"
                    label="Revenue Reconciliation Sheet Month (pick any day in month)"
                    onChange={handleChange}
                    value={props.formVals.recDate}
                    error={props.validationResult.failMessages.hasOwnProperty("recDate")}
                    helperText={props.validationResult.failMessages["recDate"]}
                    disabled={props.disabled}
                />
    
            </div>
    
        )
    },
}

