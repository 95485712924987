import React from 'react'

import LandingTable from './landingTable'
import LandingChart from './landingChart'

export default function ChartOrTable(props) {
    if(props.table === undefined || props.me === undefined) {
        return (
            <p>
                Table not found
            </p>
        )
    }
    //see if it's a table we are displaying
    if(props.table.displayAs === undefined || props.table.displayAs === "table") {
        return(
            <LandingTable
                me={props.me}
                table={props.table}
                timeSlice={props.timeSlice}
                loading={props.loading}
                kpiParams={props.kpiParams}
            />  
        )
    }
    //is it a chart we are displaying?
    if(props.table.displayAs === "barChart") {
        return(
            <LandingChart
                me={props.me}
                table={props.table}
                timeSlice={props.timeSlice}
                loading={props.loading}
                kpiParams={props.kpiParams}
            />  
        )
    }
    //if it's not one of the above types then we dont know how to display this table
    return (
        <p>
            (Cannot display the passed data)
        </p>
    )
}