import React from 'react'
import ColouredIcon from '../../shared/colouredIcon'

export default function AnnouncementTitle(props) {
    return(
        <div>
            <ColouredIcon type={props.type} />
            {props.title}
        </div>
    )
}