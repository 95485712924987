import React, {useState, useEffect} from 'react'
import {Prompt} from 'react-router-dom'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import dateFormat from 'dateformat'
import CircularProgress from '@material-ui/core/CircularProgress'
import SaveIcon from '@material-ui/icons/Save'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Backdrop from '@material-ui/core/Backdrop'

import authFetch from '../../../../lib/authFetch';
import ValidationSummary from '../../../shared/form/validationSummary';
import { Tooltip } from '@material-ui/core';
import { getDaysInMonth, formatMoney } from '../../../../lib/general'
import DailyStatCell from './dailyStatCell';

const blankTarget = {
    targetID: 0,
    storeID: 0,
    userID: 0,
    targetForUserID: 0,
    targetDate: new Date(),
    analysisFieldID: 0,
    analysisListItemID: 0,
    target: 0,
    isTarget: false,
}

const blankValidationResult = {fail: false, failSummary: '', failMessages: {}}

export default function DailyStatGrid(props) {
    const [loading, setLoading] = useState(false)
    const [dailyStats, setDailyStats] = useState([])
    const [saved, setSaved] = useState(true)
    const [saving, setSaving] = useState(false)
    const [validationResult, setValidationResult] = useState(blankValidationResult)
    const [sales, setSales] = useState([])
    //const [cols, setCols] = useState([])

    const today = new Date()
    const daysInMonth = getDaysInMonth(today.getMonth() + 1, today.getFullYear())
    //build an array of the days for mapping
    var daysArray = []
    var i
    for(i = 1; i <= daysInMonth; i++) {
        daysArray.push(i)
    }

    const showFootfall = (props.store.storeID === 0 ? false : props.store.storeType.recordFootfall)
    const showChatCount = (props.store.storeID === 0 ? false : props.store.storeType.recordChatCount)
    const showStaffCount = (props.store.storeID === 0 ? false : props.store.storeType.recordStaffCount)
    const totalCols = 1 + (showFootfall ? 1 : 0) + (showChatCount ? 1 : 0) + (showStaffCount ? 2 : 0)
    const columnWidth = (1/totalCols)

    //effect gets the data whenever any of the things driving the grid data change
    useEffect(() => {
        //there are a few API calls to make
        setLoading(true)
        setDailyStats([])
        setSales([])
        var proms = []
        const myAbortController = new AbortController()
        if(props.store.storeID === 0) {
            setLoading(false)
        } else {
            //getting the daily stats
            proms.push(
                authFetch("/dailyStats?storeID=" + props.store.storeID + "&dailyStatDateGEQ=" + dateFormat(props.dailyStatDate, "yyyy-mm-dd") + "&dailyStatDateLEQ=" + dateFormat(new Date(props.dailyStatDate.getFullYear(), props.dailyStatDate.getMonth(), daysInMonth), "yyyy-mm-dd"), {signal: myAbortController.signal})
                .then(r => {
                    setDailyStats(r.listData)
                    setSaved(true)
                    setSaving(false)
                }),
            )
            //if showingf staff then we get the sales for the month in order to display the observed staff
            if(showStaffCount) {
                proms.push(
                    authFetch("/sales?storeID=" + props.store.storeID + "&saleDateGEQ=" + dateFormat(props.dailyStatDate, "yyyy-mm-dd") + "&saleDateLEQ=" + dateFormat(new Date(props.dailyStatDate.getFullYear(), props.dailyStatDate.getMonth(), daysInMonth), "yyyy-mm-dd"), {signal: myAbortController.signal})
                    .then(r => {
                        setSales(r.listData)
                    })
                )
            }
        }
        Promise.all(proms)
        .then(na => {
            setLoading(false)
        })
        return () => {
            myAbortController.abort()
        }  
    }, [
        JSON.stringify(props.store),
        dateFormat(props.dailyStatDate, "yyyy-mm-dd"),
    ])

    function validTargets(t) {
        //only send valid dailyStats to the server
        return true
    }

    //var tempTargets = []
    function handleChange(date, statType, newValue) {
        setSaved(false)
        //we will start with an empty array
        var newDailyStats = []
        //here is what this dailyStat should look like
        var thisDailyStat = {
            dailyStatDate: dateFormat(date, "yyyy-mm-dd"), 
            storeID: props.store.storeID,
            footfall: 0,
            chatCount: 0,
            staffCount: 0,
        }
        //cycle through each entry and see if one exists to update
        var exists = false
        dailyStats.forEach(s => {
            if(dailyStatsAreEqual(s, thisDailyStat)) {
                //yes we are going to update rather than add
                exists = true
                s[statType] = newValue
            }
            newDailyStats.push(s)
        })
        //if the stat doesnt already exist lets add it
        if(!exists) {
            thisDailyStat[statType] = newValue
            newDailyStats.push(thisDailyStat)
        }
        setDailyStats(newDailyStats)
    }

    function handleSave() {
        //send the stats tot he server
        setSaving(true)
        authFetch("/dailyStats/" + props.store.storeID, {method: "PUT", body: JSON.stringify({dailyStats: dailyStats.filter(validTargets)})})
        .then(r => {
            //the server responds with the same list of stats, and any validatoin issues
            setValidationResult(r.validationResult)
            setSaving(false)
            setDailyStats(r.listData)
            if(r.validationResult.fail) {
                setSaved(false)
            } else {
                setSaved(true)
            }
        })
    }

    function handleCancel() {
        setSaving(false)
        setLoading(false)
    }

    return(
        props.store.storeID === 0 || (!showFootfall && !showChatCount && !showStaffCount) ? 
            <p>There are no stats to record.  Consider changing the settings on the store type to record staff count, footfall or chat count...</p>
        :
            <div>
                <Prompt
                    when={!saved}
                    message="You have NOT saved your changes, are you sure you want to continue?"
                />
                <TableContainer component={Paper} style={{marginTop: "10px", maxWidth: (totalCols * 125) + "px"}}>
                    <Table aria-label="Daily stats table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{padding: "4px", width: (columnWidth * 100) + "%"}}>
                                    <strong>Date</strong>
                                </TableCell>
                                {
                                    showFootfall ?
                                        <TableCell style={{padding: "4px", width: (columnWidth * 100) + "%", textAlign: "center"}}>
                                            <strong>Footfall</strong>
                                        </TableCell>
                                    : null
                                }
                                {
                                    showChatCount ?
                                        <TableCell style={{padding: "4px", width: (columnWidth * 100) + "%", textAlign: "center"}}>
                                            <strong>Chats</strong>
                                        </TableCell>
                                    : null
                                }
                                {
                                    showStaffCount ?
                                        <>
                                            <TableCell style={{padding: "4px", width: (columnWidth * 100) + "%", textAlign: "center"}}>
                                                <strong>Sales Staff</strong>
                                            </TableCell>
                                            <TableCell style={{padding: "4px", width: (columnWidth * 100) + "%", textAlign: "center"}}>
                                                <Tooltip
                                                    title="How many distinct staff recorded sales on this day"
                                                >
                                                    <div>
                                                        <strong>Observed Staff</strong>
                                                    </div>
                                                </Tooltip>
                                            </TableCell>
                                        </>
                                    : null
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                daysArray.map(dayOfMonth => 
                                    <TableRow key={dayOfMonth}>
                                        <TableCell component="th" scope="row" style={{padding: "4px"}}>
                                            {dateFormat(new Date(props.dailyStatDate.getFullYear(), props.dailyStatDate.getMonth(), dayOfMonth), "ddd dS mmm")}
                                        </TableCell>
                                        {
                                            showFootfall ?
                                                <DailyStatCell
                                                    onChange={handleChange}
                                                    dailyStats={dailyStats}
                                                    dayOfMonth={dayOfMonth}
                                                    statType="footfall"
                                                    label="Footfall"
                                                    regex="^[0-9]*$"
                                                    storeID={props.store.storeID}
                                                    dailyStatDate={props.dailyStatDate}
                                                    me={props.me}
                                                />
                                            : null
                                        }
                                        {
                                            showChatCount ?
                                                <DailyStatCell
                                                    onChange={handleChange}
                                                    dailyStats={dailyStats}
                                                    dayOfMonth={dayOfMonth}
                                                    statType="chatCount"
                                                    label="Chats"
                                                    regex="^[0-9]*$"
                                                    storeID={props.store.storeID}
                                                    dailyStatDate={props.dailyStatDate}
                                                    me={props.me}
                                                />
                                            : null
                                        }
                                        {
                                            showStaffCount ?
                                                <>
                                                    <DailyStatCell
                                                        onChange={handleChange}
                                                        dailyStats={dailyStats}
                                                        dayOfMonth={dayOfMonth}
                                                        statType="staffCount"
                                                        label="Staff"
                                                        regex="^[0-9.]*$"
                                                        storeID={props.store.storeID}
                                                        dailyStatDate={props.dailyStatDate}
                                                        me={props.me}
                                                    />
                                                    <TableCell style={{padding: "4px", textAlign: "right"}}>
                                                        {
                                                            sales.filter(s => dateFormat(s.saleDate, "yyyy-mm-dd") === dateFormat(new Date(props.dailyStatDate.getFullYear(), props.dailyStatDate.getMonth(), dayOfMonth), "yyyy-mm-dd"))
                                                                .map(s => s.attributableToUserID)
                                                                .reduce((acc, cur) => acc.includes(cur) ? acc : acc.concat(cur), []).length
                                                        }
                                                    </TableCell>
                                                </>
                                            : null
                                        }
                                    </TableRow>
                                )
                            }
                            <TableRow>
                                <TableCell component="th" scope="row" style={{padding: "4px"}}>
                                    <strong>Total</strong>
                                </TableCell>
                                {
                                    showFootfall ?
                                        <TableCell style={{padding: "4px", textAlign: "right"}}>
                                            <strong>
                                                {
                                                    formatMoney(dailyStats.map(a => a.footfall).reduce((acc, cur) => acc + (parseFloat(cur) + "" === "NaN" ? 0 : parseFloat(cur)), 0), 0)
                                                }
                                            </strong>
                                        </TableCell>
                                    : null
                                }
                                {
                                    showChatCount ?
                                        <TableCell style={{padding: "4px", textAlign: "right"}}>
                                            <strong>
                                                {
                                                    formatMoney(dailyStats.map(a => a.chatCount).reduce((acc, cur) => acc + (parseFloat(cur) + "" === "NaN" ? 0 : parseFloat(cur)), 0), 0)
                                                }
                                            </strong>
                                        </TableCell>
                                    : null
                                }
                                {
                                    showStaffCount ?
                                        <>
                                            <TableCell style={{padding: "4px", textAlign: "right"}}>
                                                <strong>
                                                    {
                                                        formatMoney(dailyStats.map(a => a.staffCount).reduce((acc, cur) => acc + (parseFloat(cur) + "" === "NaN" ? 0 : parseFloat(cur)), 0), 0)
                                                    }
                                                </strong>
                                            </TableCell>
                                            <TableCell style={{padding: "4px"}}>
                                                
                                            </TableCell>
                                        </>
                                    : null
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container spacing={3} >
                    <Grid item xs={7}>
                        <Button 
                            style={{marginTop: "10px"}} 
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            startIcon={<SaveIcon />}
                            disabled={saving || saved}
                        >
                            {
                                saving ?
                                    "Saving..."
                                :
                                    saved ?
                                        "Saved"
                                    :
                                        "Save"
                            }
                        </Button>
                        {
                            saving ?
                                <Button 
                                    style={{margin: "10px 0px 0px 10px"}} 
                                    variant="contained"
                                    color="default"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            : null
                        }
                    </Grid>
                    <Grid item xs={5} align="right">
                        <ValidationSummary validationResult={validationResult} />
                        <Backdrop open={loading || saving} style={{zIndex: "9999"}} onClick={handleCancel}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                        <   CircularProgress size={200} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Loading... click to cancel
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Backdrop>
                    </Grid>
                </Grid>
            </div>
    )
}

function dailyStatsAreEqual(t1, t2) {
    return (
        t1.storeID === t2.storeID &&
        dateFormat(t1.dailyStatDate, "yyyy-mm-dd") === dateFormat(t2.dailyStatDate, "yyyy-mm-dd")
    )
}
