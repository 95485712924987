import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField'

//components
import ValidationSummary from '../../../shared/form/validationSummary'
import { Tooltip } from '@material-ui/core';
import CheckBox from '../../../shared/checkBox'

const blankValidationResult = {fail: false, failSummary: '', failMessages: {}}
const blankForm = {
    code: "",
    description: "",
    enableTargets: false,
    dailyReportAs: ""
}

export default function AnalysisListItemModal(props) {
    const [formVals, setFormVals] = useState(blankForm)
    const [validationResult, setValidationResult] = useState(blankValidationResult)

    function handleEnter() {
        //reset the validationresult
        setValidationResult(blankValidationResult)
        //if editing get the data, or if new reset the form
        if(['read', 'update'].includes(props.action)) {
            //populate formvals with the record
            setFormVals(props.analysisListItems.filter(li => li.code === props.openCode)[0])
        } else {
            //this is a new one or delete
            setFormVals(blankForm)
        }
    }

    function handleExit() {
        //console.log("closing the modal...")
        setFormVals(blankForm)
    }

    function handleAction() {
        //the action button has been pressed
        if(props.action === "create" || props.action === "update") {
            //do validation
            //check if this code already exists
            if(props.action === "create" && props.analysisListItems.map(li => li.code).includes(formVals.code)) {
                setValidationResult({fail: true, failSummary: 'Code already exists', failMessages: {code: "Code already exists"}})
            } else {
                props.onDataChanged(formVals.code, formVals.description, formVals.enableTargets, formVals.dailyReportAs)
                props.onClose()
            }
        } else {
            //this must be a delete
            props.onDelete(props.openCode)
            props.onClose()
        }
    }

    function handleChange(event) {
        //merge it with the existing form vals and call the parent handler function
        setFormVals({...formVals, [event.target.id]: event.target.value})
    }

    const entityDisplayName = "list item"

    const recordName = props.openCode

    return(
        <Dialog open={props.open} maxWidth={"sm"} fullWidth={true} onExited={handleExit} onEnter={handleEnter} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {props.action === "delete" ? "Delete " + entityDisplayName + " " + recordName : undefined}
                {props.action === "update" ? "Edit " + entityDisplayName + " " + recordName : undefined}
                {props.action === "create" ? "New " + entityDisplayName + "" : undefined}
                {props.action === "read" ? entityDisplayName + " " + recordName : undefined}
            </DialogTitle>
            <DialogContent>
                {props.action === "delete" ? 
                    <DialogContentText>
                        <span>Are you sure you want to delete {entityDisplayName} <b>{recordName}</b>?</span>
                    </DialogContentText>
                 : undefined}
                {['create','copy','update','read'].includes(props.action) ?
                    <div>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="code"
                            label="Code"
                            type="text"
                            value={formVals.code}
                            onChange={handleChange}
                            error={validationResult.failMessages.hasOwnProperty("code")}
                            helperText={validationResult.failMessages["code"]}
                            fullWidth
                            //cannot change code after it's been created
                            disabled={props.action === 'read' || props.action === 'update'}
                        />
                        <TextField
                            margin="dense"
                            id="description"
                            label="Description (optional)"
                            type="text"
                            value={formVals.description}
                            onChange={handleChange}
                            error={validationResult.failMessages.hasOwnProperty("description")}
                            helperText={validationResult.failMessages["description"]}
                            fullWidth
                            disabled={props.action === 'read'}
                        />
                        <CheckBox 
                            id="enableTargets"
                            label="Targets are enabled"
                            onChange={handleChange}
                            value={formVals.enableTargets}
                            error={validationResult.failMessages.hasOwnProperty("enableTargets")}
                            helperText={validationResult.failMessages["enableTargets"]}
                            disabled={props.action === 'read'}
                        />
                        <Tooltip
                            title="If you want these sales to appear on the daily report, type the header they should appear under here, example CIOT"
                        >
                            <TextField
                                margin="dense"
                                id="dailyReportAs"
                                label="Daily report header (optional)"
                                type="text"
                                value={formVals.dailyReportAs}
                                onChange={handleChange}
                                error={validationResult.failMessages.hasOwnProperty("dailyReportAs")}
                                helperText={validationResult.failMessages["dailyReportAs"]}
                                fullWidth
                                disabled={props.action === 'read'}
                            />
                        </Tooltip>
                    </div>
                : undefined}
                <ValidationSummary validationResult={validationResult} />
            </DialogContent>
            <DialogActions>
            <Button color="default" onClick={props.onClose} variant="contained">
                {props.action === 'read' ? "Close" : "Cancel"}
            </Button>
            {props.action === "delete" ? 
                <Button color="secondary" onClick={handleAction} variant="contained">
                    Delete {entityDisplayName}
                </Button>
            : undefined}
            {['copy','create'].includes(props.action) ? 
                <Button color="primary" onClick={handleAction} variant="contained">
                    Create {entityDisplayName}
                </Button>
            : undefined}
            {props.action === "update" ? 
                <Button color="primary" onClick={handleAction} variant="contained">
                    Save
                </Button>
            : undefined}
            </DialogActions>
      </Dialog>
    )
}