import React, {useState} from 'react'
import Chip from '@material-ui/core/Chip'
import Link from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

//project
import AnalysisListItemModal from './analysisListItemModal'

//icons
import AddCircleIcon from '@material-ui/icons/AddCircleOutline'


export default function AnalysisListItemSelector(props) {
    const [modalOpen, setModalOpen] = useState(false)
    const [action, setAction] = useState('create')
    const [openCode, setOpenCode] = useState('')

    function handleCrudClose() {
        setModalOpen(false)
    }

    function handleCrudOpen() {
        setOpenCode('')
        setAction("create")
        setModalOpen(true)
    }

    function handleClick(code) {
        setOpenCode(code)
        setAction(props.disabled ? "read" : "update")
        setModalOpen(true)
    }

    //handles creates and updates
    function handleDataChanged(code, description, enableTargets, dailyReportAs) {
        //create a new array of analysissListItems
        var newVal = []
        //is this a new code?  if so just add it to the list
        if(!(props.value.map(ali => ali.code).includes(code))) {
            //it's a new value, add it
            props.value.forEach(e => newVal.push(e));
            newVal.push({
                analysisFieldID: props.analysisFieldID,
                code,
                description, 
                enableTargets, 
                dailyReportAs
            })
        } else {
            //not a new value.  update the appropriate entry
            props.value.forEach(e => {
                //is this the right value to modify?
                if(e.code === code) {
                    e.description = description
                    e.enableTargets = enableTargets
                    e.dailyReportAs = dailyReportAs
                }
                newVal.push(e)
            })
        }
        //console.log("New vals are " + JSON.stringify(newVal))
        //construct a pseudo event to pass up to the parent form
        var e = {
            target: {
                id: props.id,
                value: newVal
            }
        }
        props.onChange(e)
    }
    
    function handleDelete(code) {
        //create a new array of analysissListItems
        var newVal = []
        //delete the appropriate entry
        props.value.forEach(e => {
            //is this the right value to modify?
            if(e.code !== code) {
                newVal.push(e)
            }
        })
        //construct a pseudo event to pass up to the parent form
        var e = {
            target: {
                id: props.id,
                value: newVal
            }
        }
        props.onChange(e)
    }

    function handleDeleteClick(code) {
        setOpenCode(code)
        setAction("delete")
        setModalOpen(true)
    }

    return (
        <div style={{minHeight: "45px", margin: "8px 0px 4px 0px"}}>
            {props.value.map(f => {
                return (
                    <Chip key={f.code} variant="outlined" clickable onClick={() => handleClick(f.code)} label={f.code + (f.enableTargets ? " (target)" : "")} onDelete={props.disabled ? undefined : () => handleDeleteClick(f.code)}>
                    </Chip>
                )
            })}
                <Tooltip title={"Add a list option"}>
                    <IconButton style={{padding: "0px"}} onClick={props.disabled ? undefined : handleCrudOpen} aria-label={"Add a list option"}>
                        <AddCircleIcon style={{padding: "0px"}} />
                    </IconButton>
                </Tooltip>
            <AnalysisListItemModal 
                action={action}
                analysisFieldID={props.analysisFieldID}
                analysisListItems={props.value}
                open={modalOpen}
                onDataChanged={handleDataChanged}
                onDelete={handleDelete}
                onClose={handleCrudClose}
                openCode={openCode}
            />
        </div>

    )
}