import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery'

//components
import StoreTabs from '../../../shared/storeTabs'

//lib
import { FormHelperText } from '@material-ui/core';
import TickableUsers from './tickableUsers';

export default function ChooseUserModal(props) {
    const [store, setStore] = useState("all")

    const noFullscreen = useMediaQuery("(min-width:1000px)")

    return(
        <Dialog open={props.open} maxWidth="md" fullScreen={!noFullscreen} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Choose Users ({props.value.length} selected)
            </DialogTitle>
            <DialogContent style={{minHeight: "250px"}}>
                <FormHelperText>
                    Tick all the users you want to show this announcement to
                </FormHelperText>
                <StoreTabs
                    store={store}
                    onChange={s => setStore(s)}
                    me={props.me}
                    showAll={true}
                />
                <TickableUsers
                    me={props.me}
                    disabled={props.disabled}
                    store={store}
                    value={props.value}
                    onChange={props.onChange}
                    id={props.id}
                    announcementID={props.announcementID}
                />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    OK
                </Button>
            </DialogActions>
      </Dialog>
    )
}