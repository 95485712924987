import React, {useState, createRef} from 'react'
import Dropzone from 'react-dropzone'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'

export default function FileUpload(props) {
    function handleDrop(acceptedFiles) {
        props.onChange(acceptedFiles)
    }

    const maxSize = 52428800

    function handleReset() {
        props.onChange([])
    }


    return(
        <div style={{width: "100%"}}>
                <div style={{width: "100%", display: (props.value.length !== 0) ? "none" : undefined}} >
                    <Dropzone onDrop={handleDrop} accept={props.accept === "any" ? undefined : props.accept} minSize={0} maxSize={maxSize} multiple={props.multiple}>
                        {
                            ({getRootProps, getInputProps, isDragActive, rejectedFiles}) => {
                                const isFileTooLarge = (rejectedFiles === undefined ? {} : rejectedFiles).length > 0 && rejectedFiles[0].size > maxSize;

                                return (
                                        <div {...getRootProps()} style={{border: "1px dashed grey", width: "100%", height: "54px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                            <div style={{display: "flex"}}>
                                                <input {...getInputProps()} />
                                                <p>
                                                    <CloudUploadIcon style={{marginRight: "10px"}} />
                                                    {!isDragActive && 'Upload ' + props.label}
                                                    {isDragActive && "Drop the file now"}
                                                    {isFileTooLarge && (
                                                        <div className="text-danger mt-2">
                                                            File is too large.
                                                        </div>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                )
                            }
                        }
                    </Dropzone>
                </div>
            {
                props.value.length !== 0 ?
                    <div style={{width: "100%", display:"flex", flexDirection: "column", backgroundColor: "#e8f5e9", border: "1px dashed grey"}}>
                        {
                            props.value.map(acceptedFile => {
                                return(
                                    <div key={acceptedFile.path} style={{width: "100%", display: "flex", flexDirection: "row"}}>
                                        <div style={{padding: "10px 18px", display: "flex", alignItems: "center", width:"100%"}}>
                                            <CloudUploadIcon style={{marginRight: "10px"}} />
                                            File <strong style={{marginRight: "5px", marginLeft: "5px"}}>{acceptedFile.name}</strong> will be uploaded...
                                        </div>
                                    </div>
                                )
                            }
                        )}
                        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                            <Button onClick={handleReset} variant="contained">
                                Change {props.multiple ? "Files" : "File"}
                            </Button>
                        </div>
                    </div>
                :
                    null
            }
            {props.error ? <FormHelperText error={true} >{props.helperText}</FormHelperText> : undefined}
        </div>
    )
}