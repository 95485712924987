import React from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { FormHelperText } from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'

import FileUploader from '../../../shared/form/fileUploader'
import DatePicker from '../../../shared/form/datePicker'
import CheckBox from '../../../shared/checkBox'

import Announcement from '../announcement/announcement'
import ChooseUsers from './chooseUsers'


export default {
    displayName: "Announcement",
    idField: "announcementID",

    getURL: id => "/announcements?fkJoins=file,announcementUsers&announcementID=" + id,
    putURL: id => "/announcement/" + id,
    deleteURL: id => "/announcement/" + id,
    postURL: "/announcement",
    getListURL: "/announcements",
    maxWidth: "lg",
    recordName: formVals => formVals.announcementID,

    blankForm: {
        announcementID: 0,
        type: "info",
        title: "",
        message: "",
        fileID: 0,
        displayUntil: new Date(),
        acknowledgable: true,
        mustClick: false,
        clickType: "none",
        url: "https://example.com",
        announcementUsers: [],
        file: {
            originalName: "",
            fileID: 0
        }
    },
    form : function (props) {
        function handleChange(event) {
            //console.log("ID: " + event.target.id + " val: " + event.target.value)
            //check if we are allowing the change
            //merge it with the existing form vals and call the parent handler function
            props.onChange({...props.formVals, [event.target.id]: event.target.value})
        }
    
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl component="fieldset" fullWidth>
                            <RadioGroup aria-label="position" name="position" disabled={props.disabled} defaultValue="all" value={props.formVals.type} onChange={e => handleChange({target: {id: "type", value: e.target.value}})}>
                                <table style={{width: "100%"}}>
                                    <tbody>
                                        <tr>
                                            <td style={{width: "140px"}}>
                                                <FormControlLabel value="info" control={<Radio />} label="Info" />
                                            </td>
                                            <td rowSpan={4}>
                                                <Announcement
                                                    me={props.me}
                                                    announcement={props.formVals}
                                                    disabled={true} //just a preview
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <FormControlLabel value="warning" control={<Radio />} label="Warning" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <FormControlLabel value="error" control={<Radio />} label="Error" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <FormControlLabel value="success" control={<Radio />} label="Success" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <ChooseUsers
                            me={props.me}
                            value={props.formVals.announcementUsers}
                            onChange={handleChange}
                            id="announcementUsers"
                            announcementID={props.formVals.announcementID}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <h4>Announcement details</h4>
                        <TextField
                            margin="dense"
                            id="title"
                            label="Title"
                            type="text"
                            value={props.formVals.title}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("title")}
                            helperText={props.validationResult.failMessages.title}
                            fullWidth
                            disabled={props.disabled}
                        />
                        <TextField
                            margin="dense"
                            id="message"
                            label="Message"
                            type="text"
                            value={props.formVals.message}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("message")}
                            helperText={props.validationResult.failMessages.message}
                            fullWidth
                            disabled={props.disabled}
                        />
                        <DatePicker 
                            id="displayUntil"
                            label="Display until"
                            onChange={handleChange}
                            value={props.formVals.displayUntil}
                            error={props.validationResult.failMessages.hasOwnProperty("displayUntil")}
                            helperText={props.validationResult.failMessages.displayUntil}
                            disabled={props.disabled}
                        />
                        <CheckBox 
                            id="acknowledgable"
                            label="User can click OK to acknowledge/hide the announcement"
                            tooltip="If ticked the user can acknowledge the announcement by clicking OK, and it will no longer be shown"
                            onChange={handleChange}
                            value={props.formVals.acknowledgable}
                            error={props.validationResult.failMessages.hasOwnProperty("acknowledgable")}
                            helperText={props.validationResult.failMessages.acknowledgable}
                            disabled={props.disabled}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <h4>
                            Click Options
                        </h4>
                        <FormHelperText>
                            Want to include a file or a link in your announcement?  Use 'click options' below
                        </FormHelperText>
                        <FormControl component="fieldset" fullWidth>
                            <RadioGroup aria-label="position" name="position" defaultValue="all" value={props.formVals.clickType} onChange={e => handleChange({target: {id: "clickType", value: e.target.value}})}>
                                <table style={{width: "100%"}}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <FormControlLabel value="none" control={<Radio />} label="None" />
                                            </td>
                                            <td style={{width: "450px"}}>
                                                
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <FormControlLabel value="url" control={<Radio />} label="Link" />
                                            </td>
                                            <td>
                                                <TextField
                                                    margin="dense"
                                                    id="url"
                                                    label="Hyperlink (URL)"
                                                    type="text"
                                                    value={props.formVals.url}
                                                    onChange={handleChange}
                                                    error={props.validationResult.failMessages.hasOwnProperty("url")}
                                                    helperText={props.validationResult.failMessages.url}
                                                    fullWidth
                                                    disabled={props.disabled || props.formVals.clickType !== "url"}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <FormControlLabel value="file" control={<Radio />} label="File" />
                                            </td>
                                            <td>
                                                <FileUploader 
                                                    me={props.me}
                                                    onChange={handleChange} 
                                                    value={props.formVals.fileID}
                                                    id="fileID"
                                                    error={props.validationResult.failMessages.hasOwnProperty("fileID")}
                                                    helperText={props.validationResult.failMessages["fileID"]}
                                                    library="announcementUploads"
                                                    label="file for users to download"
                                                    accept="any"
                                                    urlType="any"
                                                    disabled={props.disabled || props.formVals.clickType !== "file"}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </RadioGroup>
                        </FormControl>
                        <CheckBox 
                            id="mustClick"
                            label={"Must " + (props.formVals.clickType === "url" ? "click the link" : "download the file") + " before clicking OK"}
                            tooltip="If ticked the user must click the file/link before acknowledging the announcement"
                            onChange={handleChange}
                            value={props.formVals.mustClick}
                            error={props.validationResult.failMessages.hasOwnProperty("mustClick")}
                            helperText={props.validationResult.failMessages.mustClick}
                            disabled={props.disabled || !props.formVals.acknowledgable || props.formVals.clickType === "none"}
                        />
                    </Grid>
                </Grid>
    
            </div>
    
        )
    },
}

