import React, {useState, useEffect} from "react";
import dateFormat from "dateformat";
import { Grid } from "@material-ui/core";

import LandingTable from "./tables/landingTable";
import authFetch from "../../../lib/authFetch";

const startingTables = [
    {
        name: "League Tables",
        columns: [],
        rows: [],
        params: [],
    }
]

export default function LeagueTable(props) {
    const [tables, setTables] = useState(startingTables)

    useEffect(() => {
        //get the league data from the api
        props.onLoadingChange(true)
        authFetch("/kpi/leagueTables?date=" + props.match.params.date)
        .then(r => {
            props.onLoadingChange(false)
            //check we got an array of table data like we expected
            if(Array.isArray(r.data)) {
                //yep
                setTables(r.data)
            }
        })
    }, [
        props.match.params.date //recalculate when date changes
    ])

    const timeSlice = {
        name: dateFormat(props.match.params.date, "mmm yyyy")
    }

    return (
        <Grid container spacing={2}>
            {
                tables.map((t, i) => 
                    <Grid item xs={12} md={4} lg={3} key={i}>
                        <LandingTable
                            me={props.me}
                            table={t}
                            timeSlice={timeSlice}
                            loading={props.loading}
                            kpiParams={props.kpiParams}
                            sortColumnName="Revenue"
                            sortDir="desc"
                        />  
                    </Grid>
                )
            }
        </Grid>
    )
}