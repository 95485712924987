import React from 'react'
import dateFormat from 'dateformat'

import SalesAPIData from './salesApiData'
import SalesTable from './salesTable'
import RevenueCommissionSwitchTitle from '../../shared/title/revenueCommissionSwitchTitle'


Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export default function SalesList(props) {
    function getFilterParams() {
        //are params passed in as a prop?
        if(props.params !== undefined) {
            //console.log("Params passed as props")
            return props.params
        } else if(props.match !== undefined && props.match.params.params !== undefined) {
            //console.log("URL params passed: " + atob(props.match.params.params))
            //is there a storeID?
            if(props.match.params.storeID === undefined) {
                //no store id
                return JSON.parse(atob(props.match.params.params))
            } else {
                return ({
                    ...JSON.parse(atob(props.match.params.params)),
                    storeID: props.match.params.storeID
                })
            }
        } else {
            //no default params passed
            //console.log("No params passed")
            var daysBack = 180
            try {
                daysBack = parseInt(props.me.defaultDaysBack)
            } catch (err){
                //nevermind use 180
            }
            //is there a storeID?
            if(props.match.params.storeID === undefined) {
                //no store id
                return ({
                    saleDateGEQ: dateFormat(new Date().addDays(-daysBack), "yyyy-mm-dd")
                })
            } else {
                return ({
                    saleDateGEQ: dateFormat(new Date().addDays(-daysBack), "yyyy-mm-dd"),
                    storeID: props.match.params.storeID
                })
            }
        }
    }

    const filterParams = getFilterParams()

    return(
        <div>
            <RevenueCommissionSwitchTitle
                me={props.me}
                title={//if we only have 1 store show its name
                    props.me !== undefined && filterParams.storeID !== undefined && (filterParams.storeID + "").split(",").length === 1 && props.me.accessProfile.accessProfileStores.filter(s => s.storeID + "" === filterParams.storeID + "").length === 1 ? 
                        props.me.accessProfile.accessProfileStores.filter(s => s.storeID + "" === filterParams.storeID + "")[0].store.name + " Transactions"
                    : 
                        "All Transactions"
                }
            />
            <p>
                Sales in white, refunded sales in yellow, refunds in red.
            </p>
            <SalesTable
                columnSet={(props.me === undefined || props.me.revenueCommission === undefined ? ["revenue"] : props.me.revenueCommission)}
                filters={filterParams} //these are the default filters, taken from the URL 
                title="Transactions"
                enableCrud={true} //enabling editing of sales, if the accessProfile permits it
                sort="saleID"
                sortDir="desc"
                showTopButtons={true}
                me={props.me}
                newSale={props.newSale}
                defaultFormVals={props.newSale ? JSON.parse(atob(props.match.params.defaultFormVals)) : undefined}
            />
            <SalesAPIData me={props.me} />
        </div>
    )
}