import React, {useState, useEffect} from 'react'
import { CircularProgress } from '@material-ui/core'

import MetricPartsTable from './metricPartsTable'
import metricEntityForm from './metricEntityForm'
import authFetch from '../../../../lib/authFetch'

export default function MetricParts(props) {
    const [dataRefreshTime, setDataRefreshTime] = useState(new Date())
    const [metric, setMetric] = useState(metricEntityForm.blankForm)
    const [loading, setLoading] = useState(false)
    const [selectRefreshTime, setSelectRefreshTime] = useState(new Date())

    function handleSelectRefresh() {
        setSelectRefreshTime(new Date())
    }

    //get the metric for use in the header etc
    useEffect(() => {
        setLoading(true)
        var proms = []
        //get the current rate card
        proms.push(
            authFetch("/metrics?metricID=" + props.match.params.metricID)
            .then(r => {
                setMetric(r.listData[0])
            })
        )
        Promise.all(proms)
        .then(na => {
            setLoading(false)
        })
    }, [props.match.params.metricID])

    //no user info or user not authorised
    if(props.me === undefined || !props.me.accessProfile.systemAreas.split(", ").includes("SystemSetup")) {
        return null
    }

    return(
        <div>
            {
                loading || metric.metricID === 0 ? <CircularProgress size={200} /> :
                <>
                    <h2 style={{marginTop: "12px", marginBottom: "12px"}}>
                        {metric.name} Parts
                    </h2>
                    <MetricPartsTable 
                        dataRefreshTime={dataRefreshTime}
                        metric={metric}
                        enableCrud={props.me.accessProfile.systemAreas.split(", ").includes("SystemSetup")}
                        selectRefreshTime={selectRefreshTime}
                        onSelectRefresh={handleSelectRefresh}
                        me={props.me}
                        onChange={() => setDataRefreshTime(new Date())}
                    />
                </>
            }
        </div>
    )
}