import React, {useEffect, useState} from "react"
import CircularProgress from '@material-ui/core/CircularProgress'

import authFetch from '../../lib/authFetch'


export default function LongRunningOperationSpinner(props) {
    const [message, setMessage] = useState("Loading...")
    
    var loading = false

    useEffect(() => {
        const interval = setInterval(() => {
            //only get it if we are ok to get it (ie last rquests finished)
            if(!loading) {
                getLROFromAPI()
            }
        }, 500);
        return () => {
            clearInterval(interval);
        };
    }, []);

    function getLROFromAPI() {
        loading = true
        //contact the api and get the LRO status
        authFetch("/longRunningOperation")
        .then(r => {
            loading = false
            if(r.data.message !== undefined && r.data.message !== "") {
                setMessage(r.data.message)
            } else {
                setMessage("Loading...")
            }
            
        })
    }

    return (
        <div style={{width: "100%", textAlign: "center"}}>
            <CircularProgress size={100} />
            <br/>
            {message}
        </div>
    )
}