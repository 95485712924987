import React from 'react'


export default {
    displayName: "Announcement User",
    idField: "announcementUserID",

    getURL: id => "/announcementUsers?announcementUserID=" + id,
    putURL: id => "/announcementUser/" + id,
    deleteURL: id => "/announcementUser/" + id,
    postURL: "/announcementUser",
    getListURL: "/announcementUsers",
    maxWidth: "md",
    recordName: formVals => formVals.userName,

    blankForm: {
        announcementUserID: 0,
        announcementID: 0,
        userID: 0,
        acknowledgedDate: new Date("2000-01-01"),
        clickedDate: new Date("2000-01-01"),
        lastSeenDate: new Date("2000-01-01"),
        firstSeenDate: new Date("2000-01-01"),
        clicked: false,
        acknowledged: false,
    },
    form : function (props) {
        return (
            <div>
                To do    
            </div>
    
        )
    },
}

