import React from 'react'
import TextField from '@material-ui/core/TextField'
import TableCell from '@material-ui/core/TableCell'
import dateFormat from 'dateformat'

export default function dailyStatCell(props) {

    function handleChange(e) {
        //check the value against the regex
        var check = new RegExp(props.regex)
        if(check.test(e.target.value)) {
            //it's ok.  call the handler function in the parent
            props.onChange(
                new Date(props.dailyStatDate.getFullYear(), props.dailyStatDate.getMonth(), props.dayOfMonth),
                props.statType,
                e.target.value
            )
        }
    }

    const matchingDSs = (props.dailyStats || []).filter(ds => ds.storeID === props.storeID && dateFormat(new Date(props.dailyStatDate.getFullYear(), props.dailyStatDate.getMonth(), props.dayOfMonth), "yyyy-mm-dd") === dateFormat(new Date(ds.dailyStatDate), "yyyy-mm-dd"))
    const value = matchingDSs.length === 1 ? (matchingDSs[0])[props.statType] === undefined ? "0" : (matchingDSs[0])[props.statType] : "0"
    const error = matchingDSs.length === 1 ? (matchingDSs[0])[props.error] === undefined ? "" : (matchingDSs[0])[props.error] : ""
    //console.log("Matching dss: " + JSON.stringify(matchingDSs))
    //console.log("Value: " + value)

    return(
        <TableCell style={{padding: "4px"}}>
            <TextField 
                inputProps={{
                    style: {textAlign: "right"}
                }}
                margin="dense"
                label={props.label}
                type="text"
                value={value}
                onChange={handleChange}
                error={error !== ""}
                helperText={error}
                fullWidth
                disabled={props.me === undefined || !props.me.accessProfile.systemAreas.includes("RecordDailyStats")}
            />
        </TableCell>
    )
}