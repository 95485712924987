import React, {useEffect, useState} from 'react'
import { Grid, Paper, TextField } from '@material-ui/core'
import dateFormat from 'dateformat'
import {differenceInMinutes} from 'date-fns'

import {accessibleUsers} from '../../../../lib/general'
import DatePicker from '../../../shared/form/datePicker'
import TimePicker from '../../../shared/form/timePicker'
import AutoCompleteNative from '../../../shared/autoCompleteNative'
import RepeatSelector from './repeatSelector'

export default {
    displayName: "Rota entry",
    idField: "rotaLineID",

    //getURL: id => "/sale/" + id,
    getURL: id => "/rotaLines?fkJoins=" + encodeURIComponent(["user"].join(",")) + "&rotaLineID=" + id,
    putURL: id => "/rotaLine/" + id,
    //deleteURL: id => "/sale/" + id,
    postURL: "/rotaLine",
    getListURL: "/rotaLines",
    maxWidth: "sm",
    recordName: formVals => formVals.rotaLineID,
    // overrideOnCopy: {
    //     //orderRef: "", no becasue main use for copy is where you have made multiple sales same order number
    //     currentHistoryID: 0,
    //     currentHistoryStep: "recording",
    //     isRefund: false,
    //     isRefunded: false,
    // },

    blankForm: {
        rotaLineID: 0,
        rotaDate: dateFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), "yyyy-mm-dd") + "T00:00:00.000Z",
        plannedFrom: "09:00",
        plannedTo: "17:00",
        userID: 0,
        storeID: 0,
        rotaLineTypeID: 0,
        currentHistoryID: 0,
        currentHistoryStep: "planning",
        notes: "",
        workedFrom: "00:00",
        workedTo: "00:00",
        viewedByUser: 0,
        viewedByUserDate: dateFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), "yyyy-mm-dd") + "T00:00:00.000Z",
        unpaidHours: 0,
        repeatUsers: [],
        repeatDays: []
    },
    form : function (props) {

        function handleChange(e) {
            var newFormVals = {
                ...props.formVals,
                [e.target.id]: e.target.value
            }
            props.onChange(newFormVals)
        }

        function handleRepeaterChange(newRepeatUsers, newRepeatDays) {
            props.onChange({
                ...props.formVals,
                repeatUsers: newRepeatUsers,
                repeatDays: newRepeatDays
            })
        }

        function calculateHours() {
            var pf = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                props.formVals.plannedFrom.split(":")[0],
                props.formVals.plannedFrom.split(":")[1],
                0
            )
            var pt = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                props.formVals.plannedTo.split(":")[0],
                props.formVals.plannedTo.split(":")[1],
                0
            )
            //return the differenc in hours 
            return differenceInMinutes(pt, pf) / 60
        }

        function calculateHoursString() {
            return "" + (parseFloat(calculateHours()) - parseFloat(props.formVals.unpaidHours)) + " paid hours"
        }

        return (
            <div>
                <Paper style={{padding: "14px"}} elevation={3}>
                    <AutoCompleteNative
                        id="storeID"
                        value={props.formVals.storeID}
                        label="Store"
                        onChange={handleChange}
                        optionLabel={o => o.name}
                        optionValue={o => o.storeID}
                        clearValue={0}
                        error={props.validationResult.failMessages.hasOwnProperty("storeID")}
                        helperText={props.validationResult.failMessages["storeID"]}
                        placeholder={"Store..."}
                        disabled={props.disabled || props.me.accessProfile.accessProfileStores.length <= 1}
                        options={props.me.accessProfile.accessProfileStores.map(s => s.store)}
                    />
                    <DatePicker 
                        id="rotaDate"
                        label="Date"
                        onChange={handleChange}
                        value={props.formVals.saleDate}
                        error={props.validationResult.failMessages.hasOwnProperty("saleDate")}
                        helperText={props.validationResult.failMessages["saleDate"]}
                        disabled={props.disabled}
                    />
                    <AutoCompleteNative
                        id="userID"
                        value={props.formVals.userID}
                        label="User"
                        onChange={handleChange}
                        optionLabel={o => o.name}
                        optionValue={o => o.userID}
                        clearValue={0}
                        //options={uniqueUsers(props.me.accessProfileStores)}
                        error={props.validationResult.failMessages.hasOwnProperty("userID")}
                        helperText={props.validationResult.failMessages["userID"]}
                        placeholder={"Who's working..."}
                        disabled={props.disabled}
                    />
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <TimePicker 
                                id="plannedFrom"
                                label="From"
                                onChange={handleChange}
                                value={props.formVals.plannedFrom}
                                error={props.validationResult.failMessages.hasOwnProperty("plannedFrom")}
                                helperText={props.validationResult.failMessages["plannedFrom"]}
                                disabled={props.disabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TimePicker 
                                id="plannedTo"
                                label="To"
                                onChange={handleChange}
                                value={props.formVals.plannedTo}
                                error={props.validationResult.failMessages.hasOwnProperty("plannedTo")}
                                helperText={props.validationResult.failMessages["plannedTo"]}
                                disabled={props.disabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                id="unpaidHours"
                                label="Unpaid hours"
                                type="text"
                                value={props.formVals.unpaidHours}
                                onChange={handleChange}
                                error={props.validationResult.failMessages.hasOwnProperty("unpaidHours")}
                                helperText={props.validationResult.failMessages["unpaidHours"]}
                                fullWidth
                                disabled={props.disabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{display: "flex", alignItems: "center"}}>
                            {calculateHoursString()}
                        </Grid>
                    </Grid>
                </Paper>
                { //only showing the repeater if we're in an edit mode at an appropriate status
                    props.disabled || !['planning', 'planned'].includes(props.formVals.currentHistoryStep) ? null :
                        <RepeatSelector
                            onChange={handleRepeaterChange}
                            formVals={props.formVals}
                        />
                }
            </div>
        )
    },
}

