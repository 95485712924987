import React from 'react'
import {Route, Switch} from 'react-router-dom'

import AnnouncementsList from './announcementsList'
import AnnouncementUsersList from './announcementUsersList'

export default function RateCards(props) {
    return(
        <Switch>
            <Route path="/announcements/:announcementID/announcementUsers" render={renderProps => <AnnouncementUsersList {...renderProps} me={props.me} />} />
            <Route path="/announcements" render={renderProps => <AnnouncementsList {...renderProps} me={props.me} />} />
        </Switch>
    )
}