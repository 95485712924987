import React, {useState, useEffect} from 'react'
import dateFormat from 'dateformat'

import MonthHeader from '../../shared/monthHeader'
import TargetGrid from './targetGrid'
import StoreTabs from '../../shared/storeTabs'
import storeEntityForm from '../data/stores/storeEntityForm'
import TargetsApiData from './targetsApiData'
import { getDefaultStoreID } from '../../../lib/general'

export default function TargetConsole(props) {
    const [loading, setLoading] = useState(false)

    const defaultParams = {
        view: "set",
        storeID: getDefaultStoreID(props.me),
        targetDate: dateFormat(new Date(), "yyyy-mm-01") //first day of this month
    }
    //checking for missing params
    //routes dictate either all or none are supplied
    useEffect(() => {
        //check for if none is supplier
        if(props.match.params.targetDate === undefined || props.match.params.storeID === undefined || props.match.params.view === undefined) {
            //no params passed, redirect to the defaults
            props.history.push("/targets/" + defaultParams.view + "/" + defaultParams.targetDate + "/" + defaultParams.storeID)
        }
    }, [
        props.match.params.targetDate,
        props.match.params.storeID,
        props.match.params.view,
    ])

    const store = (props.me.accessProfile.accessProfileStores.filter(a => a.storeID + "" === props.match.params.storeID + "")[0] || {store: storeEntityForm.blankForm}).store
    //console.log("Param storeid is " + JSON.stringify(props.me.accessProfile.accessProfileStores))

    function handleTargetDateChange(newDate) {
        props.history.push("/targets/" + props.match.params.view + "/" + dateFormat(newDate, "UTC:yyyy-mm-dd") + "/" + props.match.params.storeID)
    }

    function handleStoreIDChange(newStoreID) {
        props.history.push("/targets/" + props.match.params.view + "/" + props.match.params.targetDate + "/" + newStoreID)
    }

    if(props.match.params.targetDate === undefined || props.match.params.storeID === undefined || props.match.params.view === undefined) {
        //no params passed, redirecting to defaults, in the meantime return null
        return null
    } else {
        return (
            <div>
                <MonthHeader
                    monthDate={new Date(props.match.params.targetDate)}
                    onChange={handleTargetDateChange}
                    loading={loading}
                />
                <StoreTabs
                    store={store}
                    onChange={s => handleStoreIDChange(s.storeID)}
                    me={props.me}
                />
                <TargetGrid
                    me={props.me}
                    store={store}
                    targetDate={new Date(props.match.params.targetDate)}
                    view={props.match.params.view}
                    highlightTargetID={props.match.params.highlightTargetID}
                    loading={loading}
                    setLoading={setLoading}
                    //disabled={!props.me.accessProfile.systemAreas.includes("RecordTargets")}
                />
                <TargetsApiData 
                    me={props.me}
                />
            </div>
        )
    }

    
}