import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import authFetch from '../../../lib/authFetch'


  const MenuProps = {
    PaperProps: {
      style: {
        //maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
        width: 250,
        height: "350px"
      },
    },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null
  };


export default function MultiSelect(props) {
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)

    function getOptions() {
        //if no manual list of options is supplied, get the list from the optionsURL
        if(props.options === undefined) {
            setLoading(true)
            authFetch(props.optionsURL)
            .then(r => {
                //is there a function to apply after getting the options?
                if(props.optionsFn === undefined) {
                    setOptions(r.listData)
                } else {
                    setOptions(props.optionsFn(r.listData))
                }
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        //get the options on first load or if url changes
        getOptions()
    }, [props.optionsURL])

    useEffect(() => {
        //if options change or are passed, these take priority over the URL ones
        if(props.options !== undefined) {
            setOptions(props.options)
        }
    }, [JSON.stringify(props.options)])

    function handleChange(event)  {
        //start by taking the passed value
        var newVal = [...event.target.value]
        //check if xxxallxxxis fired
        if(newVal.includes("xxxallxxx")) {
            //being handled by the onclick of this special menu item
            return
        }
        //construct and return a pseudo event
        var e = {
            target: {
                id: props.id,
                value: newVal
            }
        }
        props.onChange(e)
    };

    function handleSelectAll(allSelected) {
        var newVal
        if(allSelected){
            newVal = []
        } else {
            newVal = [
                ...options.map(props.optionValue)
            ]
        }
        //construct and return a pseudo event
        var e = {
            target: {
                id: props.id,
                value: newVal
            }
        }
        props.onChange(e)
    }

    function renderValue(selected) {
        if(loading) {
            return "Loading..."
        }
        var selectArray = (Array.isArray(selected) ? selected : [])
        //console.log("Select array is " + JSON.stringify(selectArray))
        return selectArray.length === 1 ? 
            //exactly one is selected
            options.filter(o => props.optionValue(o) === selected[0]).length === 1 ? //and it's one in our list
                props.optionLabel(options.filter(o => props.optionValue(o) === selected[0])[0])
            : //one is selected but it's not one in the list of options
                "" 
        : //not exactly one is selected.  check if all are selected
            options.length === selectArray.length ?
                "(All)"
            :
                //check if none are selected
                selectArray.length === 0 ?
                    "(None)"
                :
                    ("(" + selectArray.length + " selected)")
    }

    return (
        <FormControl fullWidth margin="dense">
            <InputLabel id={props.id}>{props.label}</InputLabel>
            <Select
                labelId="mutiple-checkbox-label"
                id="mutiple-checkbox"
                multiple
                value={(Array.isArray(props.value) ? props.value : [])}
                onChange={handleChange}
                input={<Input />}
                renderValue={renderValue}
                MenuProps={MenuProps}
            >
                <MenuItem value={"xxxallxxx"} onClick={() => handleSelectAll((Array.isArray(props.value) ? props.value : []).length === options.length)} style={{paddingTop: "0px", paddingBottom: "0px"}}>
                    <ListItemText primary={"(Select " + ((Array.isArray(props.value) ? props.value : []).length === options.length ? "none" : "all") + ")"} />
                </MenuItem>
                {
                    options.map((option) => (
                        <MenuItem key={props.optionValue(option)} value={props.optionValue(option)} style={{paddingTop: "0px", paddingBottom: "0px"}}>
                            <Checkbox checked={(Array.isArray(props.value) ? props.value : []).indexOf(props.optionValue(option)) > -1} margin="dense" />
                            <ListItemText primary={props.optionLabel(option)} />
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
}