import React from 'react'
import {Link} from 'react-router-dom'

import DataTable, {addAuditFields} from '../../../shared/dataTable/dataTable'
import metricEntityForm from './metricEntityForm';
import { renderCB } from '../../../shared/dataTable/dataTable';
import { formatMoney } from '../../../../lib/general';

const filters = [
]

const columnData = [
    { fieldName: 'metricID', default: true, name: 'ID', description: "The unchanging identifier of the metric"},
    { fieldName: 'name', default: true, name: 'Name', description: "Name of the metric"},
    { fieldName: 'showCard', default: true, name: 'Card', description: "Whether a card is shown to the user for this metric", renderCell: dr => renderCB(dr.showCard)},
    { fieldName: 'showInTables', default: true, name: 'Tables', description: "Whether the metric is shown in the tables", renderCell: dr => renderCB(dr.showInTables)},
    { fieldName: 'successValue', default: true, name: 'Success', description: 'The value indicating this metric is a success', renderCell: dr => dr.successValue === 0 ? "-" : ((dr.successIsLess ? "<=" : ">=") + getPrefixSuffix(dr.metricType)[0] + formatMoney(dr.metricType === "percent" ? dr.successValue * 100 : dr.successValue, 0) + getPrefixSuffix(dr.metricType)[1])},
    { fieldName: 'metricPartCount', default: true, name: 'Parts', description: 'Number of parts needed to calculate this metric', renderCell: dr => <Link to={'/data/metrics/' + dr.metricID}>{dr.metricPartCount} parts</Link>}
];

export function getPrefixSuffix(fromMetricType) {
    if(fromMetricType === "percent") {
        return ["", "%"]
    }
    if(fromMetricType === "money") {
        return ["£", ""]
    }
    return ["", ""]
}

export default function MetricsList(props) {
    return(
        <div>
            <h1>
                Metrics
            </h1>
            <p>
                Metrics are a percentage based measurement based on the sales data.  The calculation consists of parts, some numerators and some denominators, which together define the calculation of the metric.
            </p>
            <DataTable
                columnData={addAuditFields(columnData)}
                entityForm={metricEntityForm}
                filters={filters} 
                title="Metrics"
                enableCrud={props.me.accessProfile.systemAreas.split(", ").includes("SystemSetup")}
                sort="name"
                sortDir="asc"
                me={props.me}
            />
        </div>
    )
}