import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import AutoCompleteNative from '../../../shared/autoCompleteNative'
import { ensureArray } from '../../../../lib/general'


function byCode(a, b) {
    if(a.code > b.code) {return 1}
    if(a.code < b.code) {return -1}
    return 0
}

function byLabel(a, b) {
    if(a.label > b.label) {return 1}
    if(a.label < b.label) {return -1}
    return 0
}

export default function AnalysisFields(props) {
    const [analysisFields, setAnalysisFields] = useState([])

    //console.log("There are " + props.value.length + " saleAnalysisValues")

    //when the store changes, get the applicable analysis fields that will need to be filled in
    useEffect(() => {
        if(props.storeID !== 0) {
            //a ratecardlinestore has been selected
            var analysisFields = []
            if(props.me !== undefined && props.me.analysisFields !== undefined) {
                //console.log("Looking for af's in me")
                analysisFields = props.me.analysisFields.filter(x => x.analysisFieldStores.map(s => s.storeID + "").includes(props.storeID + "")).sort(byLabel)
            } else {
                //console.log("Undef")
            }
            setAnalysisFields(analysisFields)
            //if the saleID is 0 then we need to update formVals with the default values, if a value does not already exist (eg a copy)
            if(props.saleID === 0) {
                var defaultedSans = []
                analysisFields.forEach(a => {
                    //if a value does NOT already exist for this then set the defaul value
                    if(props.value.filter(san => san.analysisFieldID === a.analysisFieldID).length === 0) {
                        //putting the default in
                        if(a.defaultValue !== "") {
                            defaultedSans.push({
                                saleAnalysisValueID: 0,
                                analysisFieldID: a.analysisFieldID,
                                analysisValue: a.defaultValue
                            })
                        }
                    } else {
                        //a value already exists for this SAN
                        defaultedSans.push(props.value.filter(san => san.analysisFieldID === a.analysisFieldID)[0])
                    }
                })
                //raise the psuedo event
                var e = {
                    target: {
                        id: props.id,
                        value: defaultedSans
                    }
                }
                props.onChange(e)
            }
        } else {
            //no store seelected so no anaylsis fields
            setAnalysisFields([])
        }
    }, [props.storeID])

    //when the rcls changes, check for autoFill fields
    useEffect(() => {
        //only if this field is not disabled
        if(!props.disabled) {
            if(props.rateCardLineStore === undefined || props.rateCardLineStore.rateCardLineStoreID + "" === "0") {
                //blank.  clear any autofill fields
                ensureArray(analysisFields).forEach(a => {
                    //is this an autoFill field?
                    if(a.inputType === "single" && a.autoFillFrom !== "") {
                        //yes it's autofill. autofill it if possible
                        updateFormVals(a.analysisFieldID, "")
                    }
                })
            } else {
                //valid rcls selected
                //go through each analysisField
                ensureArray(analysisFields).forEach(a => {
                    //is this an autoFill field?
                    if(a.inputType === "single" && a.autoFillFrom !== "") {
                        //yes it's autofill
                        //going through all the different hard coded autoFillFrom values
                        var matchingLIs
                        //rateCardLineBandName
                        if(a.autoFillFrom === "rateCardLineBandName") {
                            //See if one of the anaylis field values matches
                            matchingLIs = ensureArray(a.analysisListItems).filter(li => (li.code + "").toLowerCase() === (props.rateCardLineStore.rateCardLine.bandName + "").toLowerCase())
                            if(matchingLIs.length > 0) {
                                updateFormVals(a.analysisFieldID, matchingLIs[0].code)
                            } else {
                                //make it blank
                                updateFormVals(a.analysisFieldID, "")
                            }
                        }
                        //rateCardLineDescriptionContains
                        if(a.autoFillFrom === "rateCardLineDescriptionContains") {
                            //See if one of the anaylis field values matches
                            matchingLIs = ensureArray(a.analysisListItems).filter(li => (props.rateCardLineStore.rateCardLine.description + "").toLowerCase().includes((li.code + "").toLowerCase()))
                            if(matchingLIs.length > 0) {
                                updateFormVals(a.analysisFieldID, matchingLIs[0].code)
                            } else {
                                //make it blank
                                updateFormVals(a.analysisFieldID, "")
                            }
                        }
                    }
                })
            }
        }
    }, [(props.rateCardLineStore === undefined ? "" : props.rateCardLineStore.rateCardLineStoreID)]) //when rateCardLineStore changes, we check if any autoFill fields need to be filled in

    function handleTextAndSingleChange(analysisFieldID, event) {
        //the value is just whatever's in the text box
        updateFormVals(analysisFieldID, event.target.value)
    }

    function handleMultiChange(analysisFieldID, event) {
        //make a comma separated list of the DDL's value array
        updateFormVals(analysisFieldID, event.target.value.filter(i => i !== "").join(", "))
    }

    function updateFormVals(analysisFieldID, newValue) {
        var newVals = []
        //is this a new or updated value?
        if(props.value.map(a => a.analysisFieldID).includes(analysisFieldID)) {
            //it's an update
            props.value.forEach(a => {
                //making sure we only add it if it does not already exist
                if(!newVals.map(u => u.analysisFieldID).includes(a.analysisFieldID)) {
                    newVals.push({
                        ...a,
                        analysisValue: (a.analysisFieldID === analysisFieldID ? newValue : a.analysisValue)
                    })
                }
            })
        } else {
            //it's an addition
            newVals.push(...props.value)
            newVals.push({
                saleAnalysisValueID: 0,
                analysisFieldID,
                analysisValue: newValue
            })
        }
        //raise the psuedo event
        var e = {
            target: {
                id: props.id,
                value: newVals
            }
        }
        //console.log("Setting afs to " + JSON.stringify(e))
        props.onChange(e)
    }

    function getMultiVal(analysisField) {
        var saleAnalysisValues = props.value.filter(v => v.analysisFieldID === analysisField.analysisFieldID)
        if(saleAnalysisValues.length > 0) {
            //we have a sav for this analysis field.  But if it's an empty string we need to return an empty array
            var thisVal = saleAnalysisValues[0].analysisValue
            if(thisVal === "") {
                return []
            } else {
                //split thwe value into an array
                return thisVal.split(", ")
            }
        } else {
            //no sales analysis value
            return []
        }
    }

                    
    //console.log("props.value = " + JSON.stringify(props.value))


    return (
        <Grid spacing={3} container style={{minHeight: "81px"}}>
            {
                analysisFields.map(af => {
                    var thisAFVals = props.value.filter(v => v.analysisFieldID === af.analysisFieldID)

                    //console.log("Rendering length " + thisAFVals.length + " afval " + (thisAFVals[0] || {}).analysisValue)
                    return (
                    //depending on the analysis field type
                        af.inputType === "single" && !props.disabled ? 
                            <Grid item xs={12} md={6} lg={4} key={"single" + af.analysisFieldID}>
                                <AutoCompleteNative
                                    id={"single" + af.analysisFieldID}
                                    value={thisAFVals.length > 0 ? thisAFVals[0].analysisValue : ""}
                                    label={af.label}
                                    onChange={e => handleTextAndSingleChange(af.analysisFieldID, e)}
                                    optionLabel={o => o.code  + ((o.description + "").length === 0 ? "" : " - " + o.description)}
                                    optionValue={o => o.code}
                                    clearValue={""}
                                    options={af.analysisListItems.sort(byCode)}
                                    error={props.validationResult.failMessages.hasOwnProperty("analysisField" + af.analysisFieldID)}
                                    helperText={props.validationResult.failMessages["analysisField" + af.analysisFieldID]}
                                    placeholder={"Choose..."}
                                    disabled={af.autoFillFrom !== ""} //if disabled then show the text value, in case the analysis field value has been deleted.  However if it's an autofill dont let them change it
                                    placement="top"
                                />
                            </Grid>
                        : af.inputType === "multi" && !props.disabled ? 
                            <Grid item xs={12} md={6} lg={4}key={"multi" + af.analysisFieldID}>
                                <AutoCompleteNative
                                    id={"multi" + af.analysisFieldID}
                                    value={getMultiVal(af)}
                                    label={af.label}
                                    onChange={e => handleMultiChange(af.analysisFieldID, e)}
                                    optionLabel={o => o.code + ((o.description + "").length === 0 ? "" : " - " + o.description)}
                                    optionValue={o => o.code}
                                    clearValue={""}
                                    options={af.analysisListItems.sort(byCode)}
                                    error={props.validationResult.failMessages.hasOwnProperty("analysisField" + af.analysisFieldID)}
                                    helperText={props.validationResult.failMessages["analysisField" + af.analysisFieldID]}
                                    placeholder={"Choose..."}
                                    disabled={false} //if disabled then show the text value, in case the analysis field value has been deleted
                                    multiple
                                    placement="top"
                                />
                            </Grid>
                        : 
                            <Grid item xs={12} md={6} lg={4} key={"text" + af.analysisFieldID}>
                                <TextField
                                    margin="dense"
                                    id={"text" + af.analysisFieldID}
                                    label={af.label}
                                    type="text"
                                    value={thisAFVals.length  > 0 ? thisAFVals[0].analysisValue : ""}
                                    onChange={e => handleTextAndSingleChange(af.analysisFieldID, e)}
                                    error={props.validationResult.failMessages.hasOwnProperty("analysisField" + af.analysisFieldID)}
                                    helperText={props.validationResult.failMessages["analysisField" + af.analysisFieldID]}
                                    fullWidth
                                    disabled={props.disabled}
                                />
                            </Grid>
                    )
                })
            }
        </Grid>
    );
}