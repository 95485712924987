import React, {useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import YesIcon from '@material-ui/icons/CheckCircleOutline'
import NoIcon from '@material-ui/icons/CancelOutlined'
import Alert from '@material-ui/lab/Alert'

import AutoCompleteNative from '../../../shared/autoCompleteNative'

export default function Step1(props) {
    const [tabValue, setTabValue] = useState(0)

    function handleChange(e, uploadField, sheet) {
        //merge the new value into the new form vals
        var newArr = []
        props.formVals.mappings.forEach(a => {
            newArr.push({
                sheet: a.sheet,
                mappings: (
                    a.sheet === sheet ? 
                        a.mappings.map(m => {
                            return {
                                uploadField: m.uploadField,
                                excelField: m.uploadField === uploadField ? e.target.value : m.excelField
                            }
                        })
                    : a.mappings
                )
            })
        })
        //build the new formVals
        var newVals = {
            ...props.formVals,
            mappings: newArr
        }
        //handle the change
        props.onChange(newVals)
    }

    //console.log(JSON.stringify(props.formVals.mappings))

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return(
        <div>
            <p>
                NB: it is helpful during the manual reconciliation process to map 'Store' to the store name.  However it is not essential and the store code can be mapped instead, this will not affect the automatic reconciliation.
            </p>
            <Paper square>
                <Tabs
                    variant="scrollable" scrollButtons="auto"
                    value={tabValue}
                    //indicatorColor="primary"
                    //textColor="primary"
                    onChange={handleTabChange}
                >
                    {
                        props.excelFields.map(sheet => {
                            var mVal = props.formVals.mappings.filter(s => s.sheet === sheet.name)
                            var mappings = mVal.length === 0 ? [] : mVal[0].mappings
                            return ( 
                                <Tab 
                                    style={{minWidth: "80px"}} 
                                    label={sheet.name} 
                                    icon={mappings.filter(m => m.excelField === "").length === 0 ? <YesIcon style={{color: "#4caf50"}} /> : <NoIcon color="secondary" />}
                                    key={sheet.name} 
                                />
                            )
                        })
                        
                    }
                </Tabs>
            </Paper>
            {
                props.excelFields.map((sheet, i) => {
                    if(tabValue === i) {
                        var mVal = props.formVals.mappings.filter(s => s.sheet === sheet.name)
                        var mappings = mVal.length === 0 ? [] : mVal[0].mappings
                        //console.log("Drawing sheet " + sheet.name + " with mappings: " + JSON.stringify(mappings))
                        return (
                            <div key={sheet.name}>
                                <h4>
                                    Sheet '{sheet.name}' mappings
                                </h4>

                                { //showing message if mapping not complete
                                    mappings.filter(m => m.excelField === "").length === 0 ?
                                    <Alert severity="success" style={{margin: "10px"}}>
                                        Mapping is complete, sheet will be imported
                                    </Alert>
                                    :
                                    <Alert severity="error" style={{margin: "10px"}}>
                                        This sheet will not be imported because the mapping has not been completed.  To import the sheet, make sure every field is mapped
                                    </Alert>
                                }
                                <p>Match the fields in the system (left) to the fields in the Excel worksheet (right) for each worksheet</p>
                                {
                                    mappings.map(uf => {
                                        //console.log("Drawing field " + JSON.stringify(uf))
                                        return (
                                            <Grid container spacing={3} key={uf.uploadField}>
                                                <Grid item xs={4} style={{textAlign: "right", paddingTop: "26px"}}>
                                                    {uf.uploadField}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <AutoCompleteNative
                                                        id={"excel" + uf.uploadField}
                                                        value={uf.excelField}
                                                        label={"Excel field for " + uf.uploadField}
                                                        onChange={e => handleChange(e, uf.uploadField, sheet.name)}
                                                        optionLabel={x => x.name + " (" + (x.sample === undefined ? "Sample not available" : x.sample) + ")"}
                                                        optionValue={o => o.name}
                                                        clearValue={""}
                                                        placeholder={"Choose..."}
                                                        options={sheet.headers}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </div>
                        )
                    } else {
                        //this tab is not showing
                        return null
                    }
                })
            }


        </div>
    )
}