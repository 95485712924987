import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'

import authFetch from '../../../lib/authFetch';
import ValidationSummary from '../../shared/form/validationSummary';


export default function recChangesModal(props) {
    //passed in props is a sale object
    //and a recSheetLine object
    const [loading, setLoading] = useState(false)
    const [changes, setChanges] = useState([])
    const [storeValid, setStoreValid] = useState(false)
    const [skuValid, setSkuValid] = useState(false)
    const [recNotes, setRecNotes] = useState("")
    const [haveClicked, setHaveClicked] = useState(false) //for deciding if to show the full validationsummary instead of just the ones we show up front like changesStoreName etc

    function handleEnter() {
        //on calling, create the default changes object from what is passed
        setRecNotes("")
        setStoreValid(false)
        setSkuValid(false)
        setLoading(true)
        setHaveClicked(false)
        var newChanges = [...props.changes]
        //some api calls need to be made to see if the changes are valid or not
        //ie if the new sku is valid, if the new store is valid
        var setupProms = []
        //it's all far more complicated than this, and best handled server side
        //if the storeName is one of the changes then make an API call to make sure there is a store with the new name
        // if(props.changes.map(c => c.name).includes("storeName")) {
        //     //they want to rec to a different store
        //     setupProms.push(authFetch("/stores?nameLower=" + encodeURIComponent(props.changes.filter(c => c.name === "storeName")[0].newVal.toLowerCase()))
        //         .then(r => {
        //             //is there a store with this name?
        //             if(r.listData.length === 1) {
        //                 setStoreValid(true)
        //             } else {
        //                 setStoreValid(false)
        //                 newChanges.forEach(c => {
        //                     if(c.name === "storeName") {
        //                         c.change = false
        //                     }
        //                 })
        //             }
        //         })
        //     )
        // }
        //check the new sku exists on the rate card of the sale
        // if(props.changes.map(c => c.name).includes("sku")) {
        //     //they want to rec to a different sku
        //     setupProms.push(authFetch("/rateCardLines?rateCardID=" + props.sale.rateCardID)
        //         .then(r => {
        //             //is there a line with this sku?
        //             if(r.listData.map(s => s.sku.toLowerCase()).includes(props.changes.filter(c => c.name === "sku")[0].newVal.toLowerCase())) {
        //                 setSkuValid(true)
        //             } else {
        //                 setSkuValid(false)
        //                 newChanges.forEach(c => {
        //                     if(c.name === "sku") {
        //                         c.change = false
        //                     }
        //                 })
        //             }
        //         })
        //     )
        // }
        //resolve these proms
        Promise.all(setupProms)
        .then(na => {
            setLoading(false)
            setChanges(newChanges)
        })
    }

    function handleChange(e, changeName) {
        var newVals = []
        changes.forEach(c => {
            newVals.push({
                ...c,
                change: c.name === changeName ? e.target.checked : c.change
            })
        })
        setChanges(newVals)
    }

    function handleAction() {
        //they are making the changes
        setHaveClicked(true)
        props.onAction(props.sale.saleID, changes, recNotes)
    }

    function handleRecNotesChange(e) {
        setRecNotes(e.target.value)
    }

    return(
        <Dialog open={props.open} maxWidth="md" fullWidth={true} aria-labelledby="form-dialog-title" onEnter={handleEnter}>
            <DialogTitle id="form-dialog-title">
                Fix the Sale?
            </DialogTitle>
            <DialogContent>
                {
                    loading ?
                        <div style={{width: "100%", textAlign: "center", minHeight: "140px"}}>
                            <CircularProgress size={100} />
                        </div>
                    :
                        <div>
                            {
                                props.sale.unreconciledRevenue === 0 && props.sale.recSheetLinesCount > 0 ?
                                    <Alert severity="error" >
                                        This sale is already perfectly reconciled, with an unreconciled revenue amount of 0.  Are you sure you want to reconcile this revenue sheet line with it?
                                    </Alert>
                                : null
                            }
                            {
                                changes.map(c => {
                                    //a checkbox for each potential change
                                    return (
                                        <Grid container spacing={3} key={c.name}>
                                            <Grid item xs={1} style={{display: "flex", alignItems: "center"}}>
                                                <Checkbox checked={c.change} onChange={e => handleChange(e, c.name)} name={c.name} disabled={(c.name === "sku" && !skuValid) || (c.name === "store" && !storeValid) || c.name === "revenue"} />
                                            </Grid>
                                            <Grid item xs={11} style={{display: "flex", alignItems: "center"}}>
                                                {c.label}
                                            </Grid>
                                            {
                                                c.name === "storeName" && Object.keys(props.validationResult.failMessages).includes("changesStoreName") ?
                                                    <Alert severity="error" >
                                                        {props.validationResult.failMessages.changesStoreName}
                                                    </Alert>
                                                : null
                                            }
                                            {
                                                c.name === "sku" && Object.keys(props.validationResult.failMessages).includes("changesSKU") ?
                                                    <Alert severity="error" >
                                                        {props.validationResult.failMessages.changesSKU}
                                                    </Alert>
                                                : null
                                            }
                                        </Grid>
                                    )
                                })
                            }
                            <h4>
                                Notes
                            </h4>
                            <TextField
                                margin="dense"
                                id="notes"
                                label="Notes to record with changes"
                                type="text"
                                value={recNotes}
                                onChange={handleRecNotesChange}
                                fullWidth
                                disabled={props.disabled}
                                error={props.validationResult.failMessages.hasOwnProperty("recNotes")}
                                helperText={props.validationResult.failMessages["recNotes"]}
                            />

                        </div>
                }
                { 
                    haveClicked ?
                        <ValidationSummary validationResult={props.validationResult} /> 
                    : null
                }
                
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Cancel
                </Button>
                <Button color="primary" onClick={handleAction} variant="contained">
                    Make Changes
                </Button>
            </DialogActions>
      </Dialog>
    )
}