import React from 'react'
import Button from '@material-ui/core/Button'

export default function DateButton(props) {
    return (
        <Button
            onClick={() => props.onClick(props.dateSet)}
        >
            {props.dateSet.name}
        </Button>
    )
}
