import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

//components
import UploadStepper from './uploadStepper'
import Step0 from './step0'
import Step1 from './step1'
import Step2 from './step2'
import MappingIncompleteModal from './mappingIncompleteModal'

//lib
import authFetch from '../../../../lib/authFetch';
import LongRunningOperationSpinner from '../../../shared/longRunningOperationSpinner';

const blankValidationResult = {fail: false, failSummary: '', failMessages: {}}
const blankStep0FormVals = {
    fileID: 0,
}
const blankStep1FormVals = {
    fileID: 0,
    mappings: [],
    recSheetID: 0,
    commit: false
}
const blankStep2FormVals = {
    validationReport: []
}

export default function LineUploadModal(props) {
    //0 - choose file
    //1 - map fields
    //2 - conform upload
    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [excelFields, setExcelFields] = useState([])
    const [step1ModalOpen, setStep1ModalOpen] = useState(false)
    const [autoRec, setAutoRec] = useState(true)
    //formvals and validation result
    const [step0FormVals, setStep0FormVals] = useState(blankStep0FormVals)
    const [step1FormVals, setStep1FormVals] = useState(blankStep1FormVals)
    const [step2FormVals, setStep2FormVals] = useState(blankStep2FormVals)
    const [step0ValidationResult, setStep0ValidationResult] = useState(blankValidationResult)
    const [step1ValidationResult, setStep1ValidationResult] = useState(blankValidationResult)

    function handleEnter() {
        //start from the beginning
        setStep0FormVals(blankStep0FormVals)
        setStep(0)
        setLoading(false)
    }

    //will return an empty array if there are no mappings
    function getUserPreferenceMappings() {
        return authFetch("/user/userPreference/defaults/recSheetMap")
        .then(r => {
            if(r.validationResult.fail) {
                //no mappings
                return Promise.resolve([])
            } else {
                //there is something, parse it as JSON
                var pot = JSON.parse(r.data)
                //console.log("Found up: " + JSON.stringify(pot))
                //console.log("Got up: " + JSON.stringify(pot))
                if(Array.isArray(pot)) {
                    //console.log("It's an array")
                    return Promise.resolve(pot)
                } else {
                    //not what we expected
                    return Promise.resolve([])
                }
            }
        })
        .catch(err => {
            console.log(err)
            //probably a JSON parse error
            return Promise.resolve([])
        })
    }

    function processStep1() {
        getUserPreferenceMappings()
        .then(upm => {
            var newUPM = []
            //first add the values we used now, so that they are the most recently used
            step1FormVals.mappings.forEach(s => {
                newUPM.push({
                    sheet: s.sheet,
                    mappings: s.mappings.filter(f => f.excelField !== "").map(g => ({
                        uploadField: g.uploadField,
                        excelField: [g.excelField]
                    }))
                })
            })
            //console.log("newUPM after adding current: " + JSON.stringify(newUPM))
            //now merge in each of the existing mappings
            upm.forEach(s => {
                //is this the right shape?
                if(s.sheet !== undefined && s.sheet !== "") {
                    //yes its the right shape to keep as a user preference
                    //does the sheet already exist?
                    var existingSheet = newUPM.filter(d => d.sheet === s.sheet)
                    if(existingSheet.length > 0) {
                        //sheet already exists, merge in these mappings
                        s.mappings.forEach(u => {
                            //is it an array that can be added?
                            if(Array.isArray(u.excelField)) {
                                //does the uploadField already exist as one we can add to?
                                var existing = existingSheet[0].mappings.filter(n => n.uploadField === u.uploadField)
                                if(existing.length > 0) {
                                    //console.log("Already dealt with " + u.uploadField)
                                    //it is one we have just dealt with.  Add the values used in the past in case they come up again in future
                                    existing[0].excelField.push(...(u.excelField.filter(x => x !== "")))
                                } else {
                                    //the uploadField is not one we have just dealt with, add it in case it comes up in future
                                    //console.log("Not seen this one")
                                    existingSheet[0].mappings.push(u)
                                }
                            }
                        })
                        //remove any dupes
                        existingSheet[0].mappings = existingSheet[0].mappings.map(d => ({
                            uploadField: d.uploadField,
                            excelField: [...new Set(d.excelField)]
                        }))
                    } else {
                        //the sheet has not been used this time, add it though in case it comes up in future
                        //console.log("Pushing sheet not used this time: " + s.sheet)
                        newUPM.push(s)
                    }
                }
                
            })
            //return with the call to save the mappings for next time
            return authFetch("/user/userPreference/defaults/recSheetMap", {method: "PUT", body: JSON.stringify({value: newUPM})})
        })
        //now do the actual upload
        .then(na => authFetch("/recSheetLines/excel", {
            method: "POST",
            body: JSON.stringify(step1FormVals)
        }))
        .then(r => {
            //the response contains a validation report in the shape row, column, error, value
            setStep2FormVals({
                validationReport: r.listData.sort(sortSheetsSheet)
            })
            //now more onto the next page
            setLoading(false)
            setStep(2)
        })
    }

    function step1ModalClose() {
        //they want to go back and try the mapping again
        setLoading(false)
        setStep1ModalOpen(false)
    }

    function step1ModalContinue() {
        //they want to continue despite the incomplete mapping
        setLoading(true)
        setStep1ModalOpen(false)
        processStep1()
    }

    const sortSheets = function(a, b) {
        if(a.name > b.name) {return 1}
        if(a.name < b.name) {return -1}
        return 0
    }

    const sortSheetsSheet = function(a, b) {
        if(a.sheet > b.sheet) {return 1}
        if(a.sheet < b.sheet) {return -1}
        return 0
    }

    function handleAction() {
        //the action button has been pressed
        setLoading(true)
        //move to next step, or do the upload, depending on the current step
        if(step === 0) {
            //get the column headers found in the file
            var xlf
            var uploadFields
            authFetch("/file/" + step0FormVals.fileID + "/excelHeaders/allSheets")
            .then(r => {
                //we got the file headers
                setExcelFields(r.listData.sort(sortSheets))
                //also store it in a local variable so we can use it to apply user preferences if available
                xlf = r.listData
                //get the list of uploadFields from the api, so we can populate step1FormVals
                return authFetch("/recSheetLine/uploadFields")
            })
            .then(uf => {
                //save the upload fields for use later on
                uploadFields = uf.listData
                //get the user preference which might contain a preset mapping from last time
                return getUserPreferenceMappings()
            })
            .then(upm => {
                //set the fileID and fields in step 1 formVals
                //also set the default from the user preferences if available
                var newStep1 = {
                    fileID: step0FormVals.fileID,
                    recSheetID: props.recSheetID,
                    commit: false,
                    mappings: xlf.map(s => {
                        //making an entry for each sheet
                        return {
                            sheet: s.name,
                            mappings: uploadFields.map(x => {
                                //is there a user profile value to use?
                                var upSheet = upm.filter(u => u.sheet === s.name)
                                var prefsArray = (upSheet.length === 0 ? [] : upSheet[0].mappings).filter(u => u.uploadField === x)
                                var upVal = prefsArray.length > 0 && Array.isArray(prefsArray[0].excelField) ? 
                                        prefsArray[0].excelField
                                        //only the ones that are valid in this excel file
                                        .filter(u => s.headers.map(y => y.name).includes(u))
                                        //reducing it down to the first (most recent) one
                                        .reduce((acc, cur) => acc === "" ? cur : acc, "")
                                    : ""


                                //is there a user profile value to use?
                                //var upSheet = upm.filter(u => u.sheet === s.name)
                                //console.log("upSheet: " + JSON.stringify(upSheet))
                                //console.log("Looking for x val " + x)
                                // var upSheetVal = (upSheet.length === 0 ? [] : upSheet[0].mappings).filter(u => {
                                //     //console.log("Checking u.uploadField " + u.uploadField + " = " + x + " and whether headers includes " + u.excelField + ": " + s.headers.map(h => h.name).includes(u.excelField))
                                //     return u.uploadField === x && s.headers.map(h => h.name).includes(u.excelField)
                                // })
                                return {
                                    uploadField: x,
                                    excelField: upVal
                                }
                            })
                        }
                        
                    })
                }
                //console.log("Built new step 1 formVals: " + JSON.stringify(newStep1))
                setStep1FormVals(newStep1)
                //move to next step
                setLoading(false)
                setStep(1)                
            })
        }
        //if it's step 1 lets see what the API makes of the mapping that's been given to it
        if(step === 1) {
            //depending on whether the mapping is complete or not, we might go straight to processing or show the dialogue
            if(incompleteMappingsExist()) {
                //we need to show the modal
                setStep1ModalOpen(true)
                setLoading(false) //flip flopping it back.  Should probably be structured better
            } else {
                //mapping is complete, go straight to processing
                processStep1()
            }
        }
        //if step is 2 it's time to commit these to the database!
        if(step === 2) {
            setLoading(true)
            //post the step 1 formVals but this time set commit to true
            //and pass through the desire to autoRec or not
            authFetch("/recSheetLines/excel", {
                method: "POST",
                body: JSON.stringify({
                    ...step1FormVals,
                    commit: true,
                    autoRec
                })
            })
            .then(na => {
                //all done.  Close the disloage and register the data change
                setLoading(false)
                props.onDataChange()
                props.onClose()
            })
        }
    }

    function handleExit() {
        setStep(0) //so that next time it starts from the beginning
        setLoading(false)
    }

    function handleStep0Change(newFormVals) {
        //called by the entityForm when the values of the form change
        setStep0FormVals(newFormVals)
    }    
    
    function handleStep1Change(newFormVals) {
        //called by the entityForm when the values of the form change
        setStep1FormVals(newFormVals)
    }

    function incompleteMappingsExist() {
        //return true or false whether incomplete mappings exist on any sheet
        var r = false
        //cycle through each sheet
        step1FormVals.mappings.forEach(s => {
            //now cycle through each mapping
            s.mappings.forEach(m => {
                if(m.excelField === "") {
                    //this is an incomplete mapping
                    r = true
                }
            })
        })
        return r
    }

    function handleAutoRec() {
        setAutoRec(!autoRec)
    }

    return(
        <Dialog open={props.open} maxWidth="lg" fullWidth={true} onEnter={handleEnter} onExited={handleExit} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Upload Rec Sheet Lines
            </DialogTitle>
            <DialogContent style={{minHeight: "250px"}}>
                <UploadStepper step={step} />
                { //if loading, show the spinner, else show the wizard content
                    loading ?
                        <LongRunningOperationSpinner/>
                    :
                        //content goes here
                        <div>
                            {step === 0 ? <Step0 
                                formVals={step0FormVals}
                                onChange={handleStep0Change}
                                validationResult={step0ValidationResult}
                            /> : undefined}
                            {step === 1 ? <Step1 
                                formVals={step1FormVals}
                                onChange={handleStep1Change}
                                validationResult={step1ValidationResult}
                                excelFields={excelFields}
                            /> : undefined}
                            {step === 2 ? <Step2 
                                formVals={step2FormVals}
                                autoRec={autoRec}
                                onAutoRec={handleAutoRec}
                            /> : undefined}
                        </div>
                }
                <MappingIncompleteModal
                    open={step1ModalOpen}
                    onClose={step1ModalClose}
                    onContinue={step1ModalContinue}
                />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Cancel
                </Button>
                <Button 
                    color={step === 2 ? "primary" : "default"} 
                    onClick={handleAction} 
                    variant="contained" 
                    disabled={loading || (step === 0 && step0FormVals.fileID === 0)}
                >
                    {step === 2 ? "Confirm Upload" : "Next"}
                </Button>
            </DialogActions>
      </Dialog>
    )
}