import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import Select from 'react-select';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import Popper from '@material-ui/core/Popper'
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear'

//project lib
import authFetch from '../../lib/authFetch'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        //height: 250,
        minWidth: 350,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
        backgroundColor: emphasize(
        theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
        0.08,
        ),
    },
    noOptionsMessage: {
        padding: theme.spacing(1, 2),
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        bottom: 6,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
}));

function NoOptionsMessage(props) {
    return (
        <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
        >
        {props.children}
        </Typography>
    );
}


function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}




function Option(props) {
    return (
        <MenuItem
        ref={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
            fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
        >
        {props.children}
        </MenuItem>
    );
}


function Placeholder(props) {
    const { selectProps, innerProps = {}, children } = props;
    return (
        <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
        {children}
        </Typography>
    );
}


function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}


function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}


function MultiValue(props) {
  return (
    <Chip
        tabIndex={-1}
        label={props.children}
        className={clsx(props.selectProps.classes.chip, {
            [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
        onDelete={props.removeProps.onClick}
        deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}



export default function AutoCompleteManual(props) {
    const classes = useStyles();
    const theme = useTheme();

    //const [options, setOptions] = useState([])

    var anchor = null
    var selectAnchor = null

    function handleClear() {
        handleChange({value: (props.clearValue === undefined ? 0 : props.clearValue)})
    }

    function Menu(props) {
        //console.log("Anchor is " + anchor)
        return (
            <Popper open={true} anchorEl={anchor} style={{zIndex: 1400}} placement="bottom-start">
                <Paper square className={props.selectProps.classes.paper} style={{ marginTop: 8, width: anchor ? anchor.clientWidth : undefined }} {...props.innerProps}>
                    {props.children}
                </Paper>
            </Popper>
        );
    }

    function Control(props) {
        const {
            children,
            innerProps,
            innerRef,
            selectProps: { classes, TextFieldProps },
        } = props;
        return (
            <TextField
                ref={node => anchor = node}
                fullWidth
                style={{marginTop: "0px"}}
                margin="dense"
                InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    style: {overflow: "hidden", height: "36px"},
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
                }}
                {...TextFieldProps}
            />
        );
    }


    const components = {
        Control,
        Menu,
        MultiValue,
        NoOptionsMessage,
        Option,
        Placeholder,
        SingleValue,
        ValueContainer,
    };

    // useEffect(() => {
    //     fetchData();
    // }, [props.optionsAuthFetch.url])

    // function fetchData() {
    //     //buuld an authfetch from the passed options
    //     authFetch(props.optionsAuthFetch.url, props.optionsAuthFetch.data, props.optionsAuthFetch.returnURL)
    //     .then(res => {
    //         //now build a list of options for state
    //         setOptions(res.listData.map(o => 
    //         {
    //             //the label can be built from a simple labelField or label (function)
    //             return {
    //                 value: o[props.optionsAuthFetch.valueField], 
    //                 label: props.optionsAuthFetch.labelField ? o[props.optionsAuthFetch.labelField] : props.optionsAuthFetch.label(o)
    //             }
    //         }))
    //     })
    // }

    const selectStyles = {
        input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
            font: 'inherit',
        },
        }),
    };

    function handleChange(newVal) {
        //console.log("Automcomplete says newVal is " + JSON.stringify(newVal))
        var setting = (props.multi ? 
                newVal === null ? [] : newVal.filter(e => e.value !== undefined && e.value !== "").map(e => e.value) 
            : newVal.value)
        //console.log("Setting new value to " + JSON.stringify(setting))
        //create a phony event object to pass back to the parent so it can handle it as if it's a text box
        var e = {
            target: {
                id: props.id,
                value: setting
            }
        }
        props.onChange(e)
    }

    //console.log("Rendering MANUAL autocomplete with values " + JSON.stringify(props.options))

    function getValueLabel(val) {
        //return the label
        var matches = props.options.filter(s => s.value + "" === val + "")
        if(matches.length > 0) {
            return matches[0].label
        } else {
            return props.placeholder
        }
    }

    return (
        <div style={{width: "100%", display: "flex", flexDirection: "row"}}>
            <div style={{overflow: "hidden", flexGrow: 1}}>

                <Select
                    ref={node => selectAnchor = node}
                    classes={classes}
                    styles={selectStyles}
                    inputId={"react-select" + props.id}
                    TextFieldProps={{
                        label: props.label,
                        InputLabelProps: {
                            htmlFor: "react-select" + props.id,
                            shrink: true,
                        },
                        error: props.error,
                        helperText: props.helperText,
                        disabled:props.disabled,
                        //id: props.id + "text"
                        placeholder: props.placeholder
                    }}
                    isDisabled={props.disabled}
                    options={props.options}
                    components={components}
                    value={props.multi ? props.value.map(e => {return {value: e, label: getValueLabel(e)}}) : {value: props.value, label: getValueLabel(props.value)}}
                    onChange={handleChange}
                    isMulti={props.multi}
                />
            </div>

            {
                props.clearable ? 
                    <div style={{width:"35px", display: "flex", alignItems:"center"}}>
                        <div >
                            <IconButton style={{marginTop: "20px"}} size="small" onClick={handleClear} disabled={props.disabled} >
                                <ClearIcon size="small" />
                            </IconButton>
                        </div>
                    </div>
                : null
            }
        </div>

    );
}