import React from 'react'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'

import {formatMoney} from '../../../../lib/general'

export default function RateCardChangedAlert(props) {
    return (
        props.disabled || props.formVals.rateCardLineStoreID + "" === "0" || props.me === undefined || props.me.accessProfile === undefined || !(props.me.accessProfile[props.showField]) || props.formVals.rateCardLineStore[props.type] === 0 || props.formVals.rateCardLineStore[props.type] === props.formVals[props.type] || props.formVals.discountStoreID !== 0 ? null :
            <Grid item xs={12}>
                <Alert severity="info" style={{margin: "8px"}}>
                    The {props.type} on the rate card has been modified since this sale was made.  Was {formatMoney(props.formVals[props.type])} now {props.formVals.rateCardLineStore.rateCardLineStoreID === 0 ? null : formatMoney(props.formVals.rateCardLineStore[props.type])} ({formatMoney(props.formVals.rateCardLineStore[props.type] - props.formVals[props.type])}).  <strong>The {props.type} on this sale is {formatMoney(props.formVals[props.type])} but this will be updated if you click Save!</strong>
                </Alert>
            </Grid>
    )
}

