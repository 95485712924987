import React, {useState} from 'react'
import DateRangeIcon from '@material-ui/icons/DateRange'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import {Link} from 'react-router-dom'

import DataTable, {addAuditFields, renderCB} from '../../../shared/dataTable/dataTable'
import StoreEntityForm from './storeEntityForm';
import StoreTypeEntityForm from '../storeTypes/storeTypeEntityForm'
import LookupCell from '../../../shared/dataTable/lookupCell';

const filters = [
]

const columnData = [
    { fieldName: 'storeID', default: true, name: 'ID', description: "The unchanging identifier of the store"},
    { fieldName: 'name', default: true, name: 'Name', description: "Name of the store"},
    { fieldName: 'storeTypeName', default: true, name: 'Type', description: "The store type", renderCell: (dr) => 
        <LookupCell 
            id={dr.storeTypeID} 
            entityForm={StoreTypeEntityForm}
            label={dr.storeTypeName}
        />
    },
    { fieldName: 'targetRevenue', default: true, name: 'Target revenue', description: "Whether this store can have revenue targets recorded against it", renderCell: dr => renderCB(dr.targetRevenue)},
    { fieldName: 'targetQuantity', default: true, name: 'Target quantity', description: "Whether this store can have quantity targets recorded against it", renderCell: dr => renderCB(dr.targetQuantity)},
    { fieldName: 'postCode', default: true, name: 'Postcode', description: "Postcode of the store, for reporting"},
    { fieldName: 'squareFeet', default: true, name: 'Square feet', description: "Square feet, for reporting"},
];


export default function StoresList(props) {

    return(
        <div>
            <h1>
                Stores
            </h1>
            <p>
                Stores are the physical locations where you make sales.
            </p>
            <DataTable
                columnData={addAuditFields(columnData)}
                entityForm={StoreEntityForm}
                
                filters={filters} 
                title="Stores List"
                enableCrud={true}
            />
        </div>
    )
}