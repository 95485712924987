import React from 'react'
import Switch from '@material-ui/core/Switch'
import { Checkbox } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

export default function isUserTargetCell(props) {
    return (
        <Tooltip
            title={props.value ? "This is a target for " + props.user.name : props.disabled ? "This is not a target" : "Click to make this a target for " + props.user.name}
        >
            <div>
                <Checkbox 
                    size="small"
                    checked={props.value}
                    disabled={props.disabled}
                    onChange={props.onChange}
                />
            </div>
        </Tooltip>
    )
}