import React from 'react'
import TextField from '@material-ui/core/TextField'

import CheckBox from '../../../shared/checkBox'

export default {
    displayName: "Store Type",
    idField: "storeTypeID",

    getURL: id => "/storeTypes?storeTypeID=" + id,
    putURL: id => "/storeType/" + id,
    deleteURL: id => "/storeType/" + id,
    postURL: "/storeType",
    getListURL: "/storeTypes",
    maxWidth: "sm",
    recordName: formVals => formVals.name,

    blankForm: {
        name: "",
        recordFootfall: false,
        recordChatCount: false,
        recordStaffCount: false,
    },
    form : function (props) {
        function handleChange(event) {
            //merge it with the existing form vals and call the parent handler function
            props.onChange({...props.formVals, [event.target.id]: event.target.value})
        }
    
        return (
            <div>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    value={props.formVals.name}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("name")}
                    helperText={props.validationResult.failMessages["name"]}
                    fullWidth
                    disabled={props.disabled}
                />
                <h3>
                    Daily statistics recording
                </h3>
                <CheckBox 
                    id="recordFootfall"
                    label="Record footfall"
                    onChange={handleChange}
                    value={props.formVals.recordFootfall}
                    error={props.validationResult.failMessages.hasOwnProperty("recordFootfall")}
                    helperText={props.validationResult.failMessages["recordFootfall"]}
                    disabled={props.disabled}
                />
                <CheckBox 
                    id="recordChatCount"
                    label="Count chats"
                    onChange={handleChange}
                    value={props.formVals.recordChatCount}
                    error={props.validationResult.failMessages.hasOwnProperty("recordChatCount")}
                    helperText={props.validationResult.failMessages["recordChatCount"]}
                    disabled={props.disabled}
                />
                <CheckBox 
                    id="recordStaffCount"
                    label="Count staff"
                    onChange={handleChange}
                    value={props.formVals.recordStaffCount}
                    error={props.validationResult.failMessages.hasOwnProperty("recordStaffCount")}
                    helperText={props.validationResult.failMessages["recordStaffCount"]}
                    disabled={props.disabled}
                />
            </div>
    
        )
    },
}

