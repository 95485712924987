import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress'
import useMediaQuery from '@material-ui/core/useMediaQuery'

//components
import ValidationSummary from '../shared/form/validationSummary'

//lib
import authFetch from '../../lib/authFetch';

const blankValidationResult = {fail: false, failSummary: '', failMessages: {}}

export default function CrudModal(props) {
    const [formVals, setFormVals] = useState(props.entityForm.blankForm)
    const [isLoading, setIsLoading] = useState(true) //loading by default when first opened
    const [validationResult, setValidationResult] = useState(blankValidationResult)
    const [loadingAction, setLoadingAction] = useState(false)

    const noFullscreen = useMediaQuery("(min-width:1000px)")

    function handleEnter() {
        //reset the validationresult
        setValidationResult(blankValidationResult)
        //if editing get the data, or if new reset the form
        if(['delete','copy','update','read'].includes(props.action)) {
            //load the data
            //setIsLoading(true) //no need as loading by default
            getData()
        } else {
            //this is a new form
            //do we have some defaults to prepopulate with?
            if(props.defaultFormVals === undefined) {
                //no defaults, use the standard entity form blank
                setFormVals(props.entityForm.blankForm)
            } else {
                //have some defaults, use these
                setFormVals(props.defaultFormVals)
            }
            setIsLoading(false)
        }
    }

    function handleExit() {
        //console.log("closing the modal...")
        setIsLoading(true)
        setFormVals(props.entityForm.blankForm) //prevents api requests of the old form vals when reopening
    }

    function handleDataChanged() {
        setIsLoading(true)
        getData()
        if(props.onDataChanged !== undefined) {
            props.onDataChanged()
        }
    }

    function getData() {
        authFetch(props.entityForm.getURL(props.id))
        .then(r => {
            //console.log("Fetch " + props.entityName + " returned...")
            if(!r.validationResult.fail) {
                var newFormVals = r.data
                //sometimes the data is passed throguh as listData, depending on the getURL
                if(r.listData.length === 1) {
                    //passed as listData
                    newFormVals = r.listData[0]
                }
                //console.log("Crud modal " + props.entityName + " loaded: " + JSON.stringify(newFormVals))
                //if copy are we ignoring any fields?
                if(props.action === 'copy' && props.entityForm.overrideOnCopy !== undefined) {
                    newFormVals = {...newFormVals, ...props.entityForm.overrideOnCopy}
                }
                //we always ignore the id field when copying
                if(props.action === 'copy') {
                    newFormVals[props.entityForm.idField] = 0
                }
                setFormVals(newFormVals)
                setIsLoading(false)
            } else {
                console.log("Error loading data")
                setIsLoading(false)
            }
        })
        .catch(err => {
            console.log("Modal fetch error:" + err)
            setIsLoading(false)
        })
    }

    function handleAction() {
        //make sure we are not already handling an action - prevents duplicate records being created
        if(loadingAction) {
            return;
        }
        //the action button has been pressed
        setLoadingAction(true)
        //generate the URL
        var url = props.entityForm.postURL //valid for create and copy actions
        //if it's update or delete then we need to call the function that generates the URL
        url = props.action === 'delete' ? props.entityForm.deleteURL(props.id) : url
        url = props.action === 'update' ? props.entityForm.putURL(props.id) : url
        //now decide the method
        var method = "POST" //valid for create and copy
        //override for update or delete
        method = props.action === 'delete' ? "DELETE" : method
        method = props.action === 'update' ? "PUT" : method
        //make the API call
        authFetch(url, 
            {
                method, 
                //headers: { "Content-Type": "application/json"}, 
                body: JSON.stringify(formVals)
            })
        .then(res => {
            setLoadingAction(false)
            if(res.validationResult.fail) {
                //call was not successful, show the validation messages
                //console.log("Received validation result from API: " + JSON.stringify(res.validationResult))
                setValidationResult(res.validationResult)
            } else {
                //call was successful, close the modal
                if(props.onDataChanged !== undefined) {
                    props.onDataChanged()
                }
                props.onClose()
            }
        })
    }

    function handleFormValsChange(newFormVals) {
        //called by the entityForm when the values of the form change
        setFormVals(newFormVals)
    }

    function cancelAction() {
        setLoadingAction(false)
    }

    const entityDisplayName = props.entityForm.displayName

    const EntityForm = props.entityForm.form

    const recordName = props.entityForm.recordName === undefined ? formVals.id : props.entityForm.recordName(formVals)

    return(
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth={(props.entityForm.maxWidth === undefined || props.action === "delete") ? "sm" : props.entityForm.maxWidth} fullWidth={true} onExited={handleExit} onEnter={handleEnter} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" style={noFullscreen ? undefined : {paddingLeft: "5px", paddingRight: "5px"}}>
                {props.action === "delete" ? "Delete " + entityDisplayName + " " + recordName : undefined}
                {props.action === "update" ? "Edit " + entityDisplayName + " " + recordName : undefined}
                {props.action === "copy" ? "Copy " + entityDisplayName : undefined}
                {props.action === "create" ? "New " + entityDisplayName + "" : undefined}
                {props.action === "read" ? entityDisplayName + " " + recordName : undefined}
            </DialogTitle>
            <DialogContent style={noFullscreen ? undefined : {paddingLeft: "5px", paddingRight: "5px"}}>
                {props.action === "delete" ? 
                    <DialogContentText>
                        <span>Are you sure you want to delete {entityDisplayName} <b>{recordName}</b>?</span>
                    </DialogContentText>
                 : undefined}
                    {/* {props.action === "update" ? "Edit details of " + entityDisplayName + " " + props.id : undefined}
                    {props.action === "copy" ? "New " + entityDisplayName + " details (copied from " + entityDisplayName + " " + props.id + ")" : undefined}
                    {props.action === "create" ? "New " + entityDisplayName + " details" : undefined}
                    {props.action === "read" ? "Showing details of " + entityDisplayName + " " + props.id : undefined}
                 */}
                {['create','copy','update','read'].includes(props.action) ?
                    isLoading ? <CircularProgress size={250} /> : 
                    <EntityForm 
                        formVals={formVals} 
                        onChange={handleFormValsChange} 
                        validationResult={validationResult} 
                        disabled={props.action === 'read'} 
                        me={props.me}
                        onDataChanged={handleDataChanged} //if the form data needs to be updated without closing the modal
                        //action={props.action}
                        //dataFromID={props.id} //useful when the action is copy, for copying ancillary data from the entity
                    />
                : undefined}
                <ValidationSummary validationResult={validationResult} />
            </DialogContent>
            <DialogActions>
            <Button color="default" onClick={loadingAction ? cancelAction : props.onClose} variant="contained">
                {props.action === 'read' ? "Close" : "Cancel"}
            </Button>
            {props.action === "delete" ? 
                <Button color="secondary" onClick={handleAction} variant="contained" disabled={loadingAction}>
                    {
                        loadingAction ? "Deleting..." : "Delete " + entityDisplayName
                    }
                </Button>
            : undefined}
            {['copy','create'].includes(props.action) ? 
                <Button color="primary" onClick={handleAction} variant="contained" disabled={loadingAction}>
                    {
                        loadingAction ? "Creating..." : "Create " + entityDisplayName
                    }
                </Button>
            : undefined}
            {props.action === "update" ? 
                <Button color="primary" onClick={handleAction} variant="contained" disabled={loadingAction}>
                    {
                        loadingAction ? "Saving..." : "Save"
                    }
                </Button>
            : undefined}
            </DialogActions>
      </Dialog>
    )
}