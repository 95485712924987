import React, {useState} from 'react'
import authFetch from '../../../lib/authFetch';
import HistoryModal from '../../shared/form/steps/historyModal';
import ColouredIcon from '../../shared/colouredIcon'

export default function CurrentStepCell(props) {
    const [open, setOpen] = useState(false)
    const [steps, setSteps] = useState([])

    function handleClick() {
        //load the steps
        authFetch("/sale/steps/" + props.dataRow.saleID)
        .then(r => {
            //whether validation passed or not, use the listData return as the steps
            setSteps(r.listData)
            setOpen(true)
        })
    }

    function handleClose() {
        setOpen(false)
    }

    function getType(fromStep) {
        //translate the step into an appropriate icon
        if(fromStep === "recorded") {return "info"}
        if(fromStep === "modified") {return "warning"}
        if(fromStep === "cancelled") {return "error"}
        if(fromStep === "reconciled") {return "success"}
        return ""
    }

    return (
        <div>
            <span onClick={handleClick} >
                <ColouredIcon type={getType(props.dataRow.currentHistoryStep)} />
                {
                    props.statusText ?
                        (props.dataRow.currentHistoryStep + "").substring(0,1).toUpperCase() + (props.dataRow.currentHistoryStep + "").substring(1)
                    :
                        null
                }
            </span>
            <HistoryModal 
                me={props.me}
                steps={steps} 
                documentID={props.dataRow.saleID} 
                open={open} 
                onClose={handleClose} 
                documentType={"sales"}
                entityDisplayName="Transaction"
            />
        </div>
        
    )
}