import React from 'react'
import APIFooter from '../../shared/api/apiFooter'

const details = {
    name: "Targets",
    url: (window.location.href + "salt").replace(window.location.pathname + "salt", "/api") + "/targets/actuals",
    params: [
        {
            param: "targetDate",
            name: "Target date (first of month)",
            format: "yyyy-mm-dd",
            example: "2020-04-01",
            regex: "^[12][019][0-9][0-9]-[01]{0,1}[0-9]-[0-3]{0,1}[0-9](T[0-9][0-9]:[0-9][0-9]:[0-9][0-9]\.[0-9][0-9][0-9]Z)?$",
        },        
        {
            param: "storeNameLower",
            name: "Store name lower case",
            format: "text",
            example: "london",
            regex: "^.{0,50}$",
        },
        {
            param: "targetType",
            name: "Quantity or revenue",
            format: "quantity or revenue",
            example: "quantity",
            regex: "^(quantity|revenue)$",
        },
        {
            param: "targetForUserNameLower",
            name: "Target for user (lower case)",
            format: "text",
            example: "1234abc",
            regex: "^.{0,25}$",
        },
    ]
}

export default function TargetsAPIData(props) {
    return (
        <APIFooter
            me={props.me}
            details={details}
        />
    )
}