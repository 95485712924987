import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import dateFormat from 'dateformat'

import DatePicker from '../../../shared/form/datePicker'
import DateButton from './dateButton'

export default function RotaDateSelectorModal(props) {
    const [rotaDateGEQ, setRotaDateGEQ] = useState(props.rotaDateGEQ)
    const [rotaDateLEQ, setRotaDateLEQ] = useState(props.rotaDateLEQ)

    function handleEnter() {
        //set the dates to what has been passed
        console.log("Resetting")
        setRotaDateGEQ(props.rotaDateGEQ)
        setRotaDateLEQ(props.rotaDateLEQ)
    }

    const noFullscreen = useMediaQuery("(min-width:1000px)")

    function handleAction() {
        props.onDatesChange(rotaDateGEQ, rotaDateLEQ)
        props.onClose()
    }

    function handleDateButtonClick(newDateSet) {
        //console.log("hr " + JSON.stringify(newDateSet))
        setRotaDateGEQ(newDateSet.params.rotaDateGEQ)
        setRotaDateLEQ(newDateSet.params.rotaDateLEQ)
    }

    return(
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title" onEnter={handleEnter}>
            <DialogTitle id="form-dialog-title">
                Choose dates
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <DateButton
                            onClick={handleDateButtonClick}
                            dateSet={props.rotaDateParamSets.thisWeek}
                        />
                        <DateButton
                            onClick={handleDateButtonClick}
                            dateSet={props.rotaDateParamSets.nextWeek}
                        />
                        <DateButton
                            onClick={handleDateButtonClick}
                            dateSet={props.rotaDateParamSets.weekAfter}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateButton
                            onClick={handleDateButtonClick}
                            dateSet={props.rotaDateParamSets.thisMonth}
                        />
                        <DateButton
                            onClick={handleDateButtonClick}
                            dateSet={props.rotaDateParamSets.nextMonth}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker 
                            id="geq"
                            label="From"
                            onChange={e => setRotaDateGEQ(e.target.value)}
                            value={rotaDateGEQ}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker 
                            id="leq"
                            label="To"
                            onChange={e => setRotaDateLEQ(e.target.value)}
                            value={rotaDateLEQ}
                        />
                    </Grid>
                </Grid>
                
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
                <Button 
                    color="primary" 
                    onClick={handleAction} 
                    variant="contained"
                >
                    Apply
                </Button>
            </DialogActions>
      </Dialog>
    )
}