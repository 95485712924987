import React, {useState} from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery'

import DataTable from '../../../shared/dataTable/dataTable'
import RateCardLineEntityForm from './rateCardLineEntityForm';
import SKUTypeEntityForm from '../skuTypes/skuTypeEntityForm'
import BandEntityForm from '../bands/bandEntityForm'
import LookupCell from '../../../shared/dataTable/lookupCell';
import {formatMoney, initialsFromName} from '../../../../lib/general'
import SKUHistoryCell from './skuHistoryCell'
import DeleteModal from './deleteModal'


function buildColumns(columnSet, me, geq700) {
    function nameLabel(fromName) {
        if(geq700) {
            //full name
            return fromName
        } else {
            //initials
            return initialsFromName(fromName)
        }
    }
    
    var columnData = [{ fieldName: 'rateCardLineID', default: false, name: 'ID', description: "The unchanging identifier of the rate card line"},]
    if(geq700) {
        columnData.push(...[
            { fieldName: 'skuTypeName', default: true, name: 'Type', description: "The SKU type", renderCell: (dr) => 
                <LookupCell 
                    id={dr.skuTypeID} 
                    entityForm={SKUTypeEntityForm}
                    label={dr.skuTypeName}
                />
            },
        ])
    }
    columnData.push(...[
        { fieldName: 'sku', default: true, name: 'SKU', description: "The SKU", renderCell: dr => <SKUHistoryCell sku={dr.sku} /> },
        { fieldName: 'description', default: true, name: 'Description', description: "Description of the SKU"},
    ])
    if(geq700) {
        columnData.push(...[
            { fieldName: 'customerRRP', default: true, name: 'RRP', description: "RRP payable by the customer", renderCell: dr => dr.customerRRP + "" === "0" ? "" : formatMoney(dr.customerRRP), alignRight: true},
            { fieldName: 'customerMonthly', default: true, name: 'Monthly', description: "Monthly amount payable by the customer", renderCell: dr => dr.customerMonthly + "" === "0" ? "-" : formatMoney(dr.customerMonthly), alignRight: true},
            { fieldName: 'contractMonths', default: true, name: 'Contract length', description: "Number of months long the contract is", renderCell: dr => dr.contractMonths + "" === "0" ? "-" : dr.contractMonths + "m", alignRight: true},
            { fieldName: 'legacySKU', default: false, name: 'Legacy SKU', description: "The legacy SKU for holding an alternative SKU reference"},
            { fieldName: 'bandName', default: true, name: 'Band', description: "The band of this item", renderCell: (dr) => 
                <LookupCell 
                    id={dr.bandID} 
                    entityForm={BandEntityForm}
                    label={dr.bandName}
                />
            },
        ])
    } 
    //now add in the columns for the stores
    //depends on the columnSet
    if((columnSet || []).includes("revenue")) {
        //showing revenue
        if(me !== undefined && me.accessProfile.accessProfileStores !== undefined) {
            //using the stores in the access profile, creating a column for each
            columnData.push(...me.accessProfile.accessProfileStores.map(s => s.store).map(ns => ({
                    fieldName: "revenueStoreID" + ns.storeID,
                    default: true,
                    name: "Rev " + nameLabel(ns.name),
                    description: "Revenue at " + ns.name,
                    renderCell: dr => {
                        var rows = (dr.rateCardLineStores || []).filter(rcls => rcls.storeID === ns.storeID)
                        return <span style={rows.length === 0 ? undefined : rows[0].revenue + "" === "0" ? undefined : rows[0].active === false ? {color: "pink"} : undefined}>
                            {rows.length === 0 ? "-" : rows[0].revenue + "" === "0" ? "-" : formatMoney(rows[0].revenue)}
                        </span>
                    },
                    alignRight: true
                })
            ))
        }
    }
    if((columnSet || []).includes("commission")) {
        if(me !== undefined && me.accessProfile.accessProfileStores !== undefined) {
            //using the stores in the access profile, creating a column for each
            columnData.push(...me.accessProfile.accessProfileStores.map(s => s.store).map(ns => ({
                    fieldName: "commissionStoreID" + ns.storeID + "",
                    default: true,
                    name: "Com " + nameLabel(ns.name),
                    description: "Commission at " + ns.name,
                    renderCell: dr => {
                        var rows = (dr.rateCardLineStores || []).filter(rcls => rcls.storeID === ns.storeID)
                        return <span style={rows.length === 0 ? undefined : rows[0].commission + "" === "0" ? undefined : rows[0].active === false ? {color: "pink"} : undefined}>
                            {rows.length === 0 ? "-" : rows[0].commission + "" === "0" ? "-" : formatMoney(rows[0].commission)}
                        </span>
                    },
                    alignRight: true
                })
            ))
        }
    } 
    return columnData
}

export default function RateCardLinesTable(props) {
    const geq700 = useMediaQuery("(min-width:700px)")

    const [deleteOpen, setDeleteOpen] = useState(false)
    const [deleteIDs, setDeleteIDs] = useState([])

    const filters={
        rateCardID: props.rateCardID,
        saleTypeID: props.saleType.saleTypeID,
        fkJoins: "rateCardLineStores"
    }

    //function for rendering the actions area when some items are selected
    function selectActions(props) {
        return (
            <Tooltip title="Delete the selected rate card lines">
                <IconButton aria-label="Delete the selected rate card lines" onClick={() => handleDeleteOpen(props.selected)}>
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            </Tooltip>
        );
    }

    function handleDeleteOpen(deleteIDs) {
        setDeleteOpen(true)
        setDeleteIDs(deleteIDs)
    }

    function handleDeleteClose() {
        setDeleteOpen(false)
    }

    function handleDeleteChange() {
        setDeleteOpen(false)
        props.onSelectRefresh()
        props.onChange()
    }

    return(
        <>
            <DataTable
                columnData={buildColumns(props.me.revenueCommission, props.me, geq700)}
                //overriding the rateCardID param on blankform to be the current one
                entityForm={{
                    ...RateCardLineEntityForm,
                    blankForm: {
                        ...RateCardLineEntityForm.blankForm,
                        rateCardID: props.rateCardID,
                        saleTypeID: props.saleType.saleTypeID
                    }
                }}
                selectRefreshTime={props.selectRefreshTime}
                selectActions={props.enableCrud ? selectActions : undefined}
                dataRefreshTime={props.dataRefreshTime}
                filters={filters} 
                title={props.saleType.name + " Rate Card Lines"}
                enableCrud={props.enableCrud}
                me={props.me}
            />
            {
                props.enableCrud ? 
                    <DeleteModal 
                        open={deleteOpen}
                        rateCardLineIDs={deleteIDs}
                        onClose={handleDeleteClose}
                        onChange={handleDeleteChange}
                    />
                : null
            }
        </>
    )
}