import React, {useState, useEffect} from 'react'
import Grid from '@material-ui/core/Grid'
import AutoCompleteNative from '../../../shared/autoCompleteNative'

import DatePicker from '../../../shared/form/datePicker'
import SaleOcrButton from '../saleOcr/saleOcrButton'
import {formatMoney, accessibleUsers, ensureArray, accessibleStores} from '../../../../lib/general'
import OrderRefField from './orderRefField'
import OptionalTooltip from '../../../shared/optionalTooltip'

//lib
import authFetch from '../../../../lib/authFetch';
import Alert from '@material-ui/lab/Alert'

const discountPercentages = [
    {
        value: 0,
        label: "0%"
    },
    {
        value: 5,
        label: "5%"
    },
    {
        value: 10,
        label: "10%"
    },
    {
        value: 15,
        label: "15%"
    },
    {
        value: 20,
        label: "20%"
    },
    {
        value: 25,
        label: "25%"
    },
    {
        value: 30,
        label: "30%"
    },
    {
        value: 35,
        label: "35%"
    },
    {
        value: 40,
        label: "40%"
    },
]


export default function SaleDetailFields(props) {
    function handleOcrChanges(newOrderRef, newSaleDate, newRateCardLineStoreID, saleAnalysisValues) {
        //the params are undefined if not found
        var newVals = {
            ...props.formVals
        }
        //was the sale date found?
        if(newSaleDate !== undefined) {
            newVals.saleDate = newSaleDate
        }
        //was the order ref found?
        if(newOrderRef !== undefined) {
            newVals.orderRef = newOrderRef
        }
        //were any saleAnalysisField values found?
        if(Array.isArray(saleAnalysisValues)) {
            saleAnalysisValues.filter(s => Array.isArray(s.values) && s.values.length > 0).forEach(s => {
                //set the value of the analysisfield to this value
                newVals.saleAnalysisValues = [
                    ...(Array.isArray(props.formVals.saleAnalysisValues) ? props.formVals.saleAnalysisValues : []).filter(x => x.analysisFieldID !== s.analysisFieldID), //adding all the existing ones, except for the one we're updating
                    {
                        saleAnalysisValueID: 0,
                        analysisFieldID: s.analysisFieldID,
                        analysisValue: s.values[0]
                    }
                ]
            })
        }
        //now the rateCardLineStore.  This one requires looking up the new details
        if(newRateCardLineStoreID + "" !== "undefined") {
            props.onLoadingNewRCLS()
            authFetch("/rateCardLineStores?fkJoins=" + encodeURIComponent(["rateCardLine", "rateCardLine.rateCard"].join(",")) + "&rateCardLineStoreID=" + newRateCardLineStoreID)
            .then(r => {
                props.loadNewRCLS(newVals, (r.listData.length === 1 ? r.listData[0] : props.blankRateCardLineStore), props.formVals.discountPercent, props.formVals.discountStoreID)
            })
        }
        props.onChange(newVals)
    }

    function makeLabel(fromRCLS) {
        var price = ""
        if(fromRCLS.customerRRP !== 0) {
            price = "RRP " + formatMoney(fromRCLS.customerRRP)
        }
        if(fromRCLS.customerMonthly !== 0) {
            price = (price === "" ? "" : " + ") + formatMoney(fromRCLS.customerMonthly) + " monthly"
        }
        if(price !== "") {
            price = " (" + price + ")"
        }
        return fromRCLS.sku + " - " + fromRCLS.description + price
    }

    var usersStores = accessibleStores(props.me)

    return (
        <Grid container spacing={1}>
            {props.calculatorOnly ? null :
                <Grid item xs={12} sm={6} md={4}>
                    <AutoCompleteNative
                        id="attributableToUserID"
                        value={props.formVals.attributableToUserID}
                        label="Sale made by"
                        onChange={props.handleChange}
                        optionLabel={o => o.name}
                        optionValue={o => o.userID}
                        clearValue={0}
                        options={accessibleUsers(props.me)}
                        error={props.validationResult.failMessages.hasOwnProperty("attributableToUserID")}
                        helperText={props.validationResult.failMessages.attributableToUserID}
                        placeholder={"Who made the sale..."}
                        disabled={props.disabled}
                    />
                </Grid>
            }
            <Grid item xs={12} sm={props.calculatorOnly ? 12 : 6} md={props.calculatorOnly ? 12 : 4}>
                <AutoCompleteNative
                    id="saleTypeID"
                    value={props.formVals.saleTypeID}
                    label="Sale type"
                    onChange={props.handleChange}
                    optionLabel={o => o.name}
                    optionValue={o => o.saleTypeID}
                    clearValue={0}
                    options={ensureArray(props.me.saleTypes)}
                    error={props.validationResult.failMessages.hasOwnProperty("saleTypeID")}
                    helperText={props.validationResult.failMessages.saleTypeID}
                    placeholder={"Choose sale type..."}
                    disabled={props.disabled}
                />
            </Grid>
            <Grid item xs={12} sm={props.calculatorOnly ? 12 : 3} md={props.calculatorOnly ? 12 : 2} >
                <OptionalTooltip
                    tooltip={props.formVals.storeID + "" === "0" ? "Please choose store first" : (props.formVals.saleTypeID + "" === "0" ? "Please choose sale type first" : "")}
                >
                    <AutoCompleteNative
                        id="discountStoreID"
                        value={props.formVals.discountStoreID}
                        label="Applied discount"
                        onChange={props.handleChange}
                        optionLabel={o => o.discountName}
                        optionValue={o => o.discountStoreID}
                        clearValue={"unselected"}
                        options={props.discountOptions}
                        //optionsURL={"/discountStores?sort=name&sortDir=asc&enabled=1&storeID=" + props.formVals.storeID + "&discountSaleTypeID=" + props.formVals.saleTypeID}
                        error={props.validationResult.failMessages.hasOwnProperty("discountStoreID")}
                        helperText={props.validationResult.failMessages.discountStoreID}
                        placeholder={"Discount..."}
                        disabled={
                            props.disabled || 
                            props.formVals.saleTypeID + "" === "0" || 
                            props.formVals.storeID + "" === "0" || 
                            props.formVals.rateCardLineStore.excludeDiscount
                        }
                    />
                </OptionalTooltip>
            </Grid>
            <Grid item xs={12} sm={props.calculatorOnly ? 12 : 3} md={props.calculatorOnly ? 12 : 2}>
                <AutoCompleteNative
                    id="discountPercent"
                    value={props.formVals.discountPercent}
                    label={"Discount percent"}
                    onChange={props.handleChange}
                    optionLabel={o => o.label}
                    optionValue={o => o.value}
                    clearValue={0}
                    options={discountPercentages}
                    error={props.validationResult.failMessages.hasOwnProperty("discountPercent")}
                    helperText={props.validationResult.failMessages.discountPercent}
                    placeholder={"Percent..."}
                    disabled={
                        props.disabled || 
                        !props.formVals.discountStore.captureVariablePercent || 
                        props.formVals.rateCardLineStore.excludeDiscount 
                    }
                />
            </Grid>
            {props.calculatorOnly ? null :
                <>
                    {props.disabled ? null :
                    <Grid item xs={12} style={{display: "flex", alignItems: "flex-end"}}>
                        <SaleOcrButton
                            onOcrChanges={handleOcrChanges}
                            id={"saleOcr"}
                            formVals={props.formVals}
                            currentRateCardID={props.currentRateCardID}
                        />
                    </Grid>
                    }   
                    <Grid item sm={6} md={4} xs={12}>
                        <DatePicker 
                            id="saleDate"
                            label="Sale date"
                            onChange={props.handleChange}
                            value={props.formVals.saleDate}
                            error={props.validationResult.failMessages.hasOwnProperty("saleDate")}
                            helperText={props.validationResult.failMessages.saleDate}
                            disabled={props.disabled}
                        />
                    </Grid>
                </>
            }
            <Grid item sm={props.calculatorOnly ? 12 : 6} md={props.calculatorOnly ? 12 : 4} xs={12}>
                <AutoCompleteNative
                    id="storeID"
                    value={props.formVals.storeID}
                    label="Store"
                    onChange={props.handleChange}
                    optionLabel={o => o.name}
                    optionValue={o => o.storeID}
                    clearValue={0}
                    error={props.validationResult.failMessages.hasOwnProperty("storeID")}
                    helperText={props.validationResult.failMessages.storeID}
                    placeholder={"Store..."}
                    disabled={props.disabled || (usersStores.length <= 1 && (usersStores[0].storeID + "" === props.formVals.storeID + ""))}
                    options={usersStores}
                />
            </Grid>
            {props.calculatorOnly ? null :
                <Grid item xs={12} sm={6} md={4}>
                    <OrderRefField
                        id="orderRef"
                        label="Order reference (20 digits)"
                        value={props.formVals.orderRef}
                        onChange={props.handleChange}
                        error={props.validationResult.failMessages.hasOwnProperty("orderRef")}
                        helperText={props.validationResult.failMessages.orderRef}
                        disabled={props.disabled}
                    />
                </Grid>
            }
            <Grid item xs={12}>
                <AutoCompleteNative
                    id="rateCardLineStoreID"
                    value={props.formVals.rateCardLineStoreID}
                    label={"SKU" + (props.formVals.storeID + "" === "0" ? " (please choose store first)" : (props.formVals.saleTypeID + "" === "0" ? " (please choose sale type first)" : ""))}
                    onChange={props.handleChange}
                    optionLabel={o => makeLabel(o)}
                    optionValue={o => o.rateCardLineStoreID}
                    clearValue={0}
                    optionsURL={"/rateCardLineStores?sort=sku&sortDir=asc&active=1&rateCardID=" + (props.formVals.rateCardID === 0 ? props.currentRateCardID : props.formVals.rateCardID) + "&storeID=" + props.formVals.storeID + "&saleTypeID=" + props.formVals.saleTypeID}
                    error={props.validationResult.failMessages.hasOwnProperty("rateCardLineStoreID")}
                    helperText={props.validationResult.failMessages.rateCardLineStoreID}
                    placeholder={"Choose SKU..."}
                    disabled={props.disabled || props.formVals.saleTypeID + "" === "0" || props.formVals.storeID + "" === "0"}
                />
            </Grid>
            {props.calculatorOnly ? 
                <Grid item xs={12}>
                    <Alert 
                        severity={props.isHighestRevenue ? "success" : "warning"}
                    >
                        Revenue £{formatMoney(props.formVals.revenue)}
                    </Alert>
                </Grid>
                : null
            }
        </Grid>
    )
}