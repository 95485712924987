import React from 'react'
import TextField from '@material-ui/core/TextField'

import CheckBox from '../../../shared/checkBox'
import AutoComplete from '../../../shared/autoComplete'

export default {
    displayName: "SKU Type",
    idField: "skuTypeID",

    getURL: id => "/skuTypes?skuTypeID=" + id,
    putURL: id => "/skuType/" + id,
    deleteURL: id => "/skuType/" + id,
    postURL: "/skuType",
    getListURL: "/skuTypes",
    maxWidth: "sm",
    recordName: formVals => formVals.name,

    blankForm: {
        name: "", 
        description: "",
        excludeDiscount: false
    },
    form : function (props) {
        function handleChange(event) {
            //merge it with the existing form vals and call the parent handler function
            props.onChange({...props.formVals, [event.target.id]: event.target.value})
        }
    
        return (
            <div>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    value={props.formVals.name}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("name")}
                    helperText={props.validationResult.failMessages["name"]}
                    fullWidth
                    disabled={props.disabled}
                />
                <TextField
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    value={props.formVals.description}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("description")}
                    helperText={props.validationResult.failMessages["description"]}
                    fullWidth
                    disabled={props.disabled}
                />
                <CheckBox 
                    id="excludeDiscount"
                    label="Sale type discounts cannot be applied"
                    onChange={handleChange}
                    value={props.formVals.excludeDiscount}
                    error={props.validationResult.failMessages.hasOwnProperty("excludeDiscount")}
                    helperText={props.validationResult.failMessages["excludeDiscount"]}
                    disabled={props.disabled}
                />
            </div>
    
        )
    },
}

