import React from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import AutoCompleteNative from '../../../shared/autoCompleteNative'

export default function SliceSelector(props) {
    //are there enough timeSlices to bother displaying a selector?
    if(props.timeSlices.length <= 1) {
        //nope
        return null
    }
    //show ddl on mobile (2 click, smaller).  radio on laptop (1 click, more space)
    const useRadio = useMediaQuery("(min-width:1000px)")

    if(useRadio) {
        //big screen, show radio buttons
        return (
            <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" value={props.timeSlice.name} onChange={props.onChange}>
                    {
                        props.timeSlices.map(t => 
                            <FormControlLabel value={t.name} control={<Radio />} label={t.name} key={t.name} />
                        )
                    }
                </RadioGroup>
            </FormControl>
        )
    }
    
    //small screen, show drop down
    return (
        <AutoCompleteNative
            id="timeSliceDDL"
            value={props.timeSlice.name}
            label={"Time period"}
            onChange={props.onChange}
            optionLabel={o => o.name}
            optionValue={o => o.name}
            //clearValue={props.timeSlices[0].name}
            options={props.timeSlices}
            placeholder={"Time period..."}
        />
    )
}