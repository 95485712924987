import 'date-fns';
import React from 'react';
import dateFormat from 'dateformat'
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DatePicker as ADatePicker,
} from '@material-ui/pickers';

export default function DatePicker(props) {
    function handleChange(date) {
        try {
            //console.log("You selected (type) " + typeof date)
            //console.log("You selected (raw) " + date.toString())
            //console.log("You selected (ISO) " + date.toISOString())
        } catch (err) {

        }
        //build an event object to return
        var e = {
            target: {
                id: props.id,
                value: dateFormat(date, "yyyy-mm-dd") + "T00:00:00.000Z"
            }
        }
        props.onChange(e)
    }

    function getValue() {
        //value can be string or date.  If it's a string that looks like a date, convert it
        if(props.value === "" || props.value === undefined) {
            return ""
        } else {
            if(props.value instanceof Date) {
                return props.value
            } else {
                //console.log("Value is a string: " + props.value)
                return new Date(props.value)
            }
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ADatePicker
                margin="dense"
                id={props.id}
                label={props.label}
                format="dd/MM/yyyy"
                value={getValue()}
                onChange={handleChange}
                error={props.error}
                fullWidth
                helperText={props.helperText}
                disabled={props.disabled}
            />
        </MuiPickersUtilsProvider>
    );
}