import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import dateFormat from 'dateformat'

import storeEntityForm from '../../data/stores/storeEntityForm'
import StoreTabs from '../../../shared/storeTabs'
import authFetch from '../../../../lib/authFetch';
import DailyReportGrid from './dailyReportGrid'
import DatePicker from '../../../shared/form/datePicker'

export default function dailyReport(props) {
    const [sales, setSales] = useState([])
    const [analysisFieldStores, setAnalysisFieldStores] = useState([])
    const [loading, setLoading] = useState(false)

    //decide what date we are using for this daily report table.  If it is unspecified then use todays date
    var saleDate = new Date() //using today by default
    if(props.match.params.saleDate !== undefined) { //using the date provided in the params
        saleDate = new Date(props.match.params.saleDate)
    }

    //create the api url and also start a params object.
    //api - for getting the data
    //params - for making the link clickable
    //so they must be the same to return the same results
    var apiURL = "/sales?isRefund=0&isRefunded=0&currentHistoryStep=recorded,modified,reconciled" + (props.match.params.storeID === "all" ? "" : "&storeID=" + props.match.params.storeID) + "&saleDate=" + dateFormat(saleDate, "yyyy-mm-dd")
    var params = {
        isRefund: 0,
        isRefunded: 0,
        currentHistoryStep: "recorded,modified,reconciled",
        saleDate: dateFormat(saleDate, "yyyy-mm-dd"),
    }
    //change the storeID param if it is specified
    if(props.match.params.storeID !== "all") {
        params.storeID = props.match.params.storeID
    }

    function getDefaultStoreID() {
        //have a look if they have a default store first
        if(props.me !== undefined && props.me.storeID !== 0 && props.me.accessProfile.accessProfileStores.map(s => s.storeID).includes(props.me.storeID)) {
            return props.me.storeID
        }
        //just return the first store in their access profile
        if(props.me.accessProfile.accessProfileStores.map(s => s.storeID).length > 0) {
            return props.me.accessProfile.accessProfileStores.map(s => s.storeID)[0]
        } else {
            return "all"
        }
    }

    function handleSaleDateChange(e) {
        //new date has been selected
        //redirect to this new date
        props.history.push("/daily/dailyReport/" + props.match.params.storeID + "/" + dateFormat(e.target.value, "yyyy-mm-dd"))
    }

    //checking for missing store param
    useEffect(() => {
        setSales([])
        setAnalysisFieldStores([])
        //check if store id is supplied
        if(props.match.params.storeID === undefined) {
            //storeid is missing, redirect to the default
            props.history.push("/daily/dailyReport/" + getDefaultStoreID())
        } else {
            setLoading(true)
            //getting the sales and analysisListItem data from the server
            const myAbortController = new AbortController()
            var proms = []
            //let's get the sales
            proms.push(
                authFetch(apiURL, {signal: myAbortController.signal})
                .then(r => {
                    setSales(r.listData)
                })
            )
            //and now the analysisListItems
            proms.push(
                authFetch("/analysisFieldStores?storeID=" + props.match.params.storeID + "&fkJoins=" + encodeURIComponent(["analysisField", "analysisField.analysisListItems"].join(",")), {signal: myAbortController.signal})
                .then(r => {
                    //console.log("Got " + JSON.stringify(r.listData))
                    setAnalysisFieldStores(
                        //get all the analysisListItems where dailyReportAs is not blank (these are not appliccable!)
                        r.listData //filter out any for which the analysis field was not sent - these are deleted
                        .filter(afs => afs.analysisField !== undefined && afs.analysisField.analysisListItems !== undefined)
                    )
                })
            )
            Promise.all(proms)
            .then(na => {
                setLoading(false)
            })
        }
    }, [
        props.match.params.storeID,
        props.match.params.saleDate
    ])

    const store = props.match.params.storeID === "all" ? "all" : (props.me.accessProfile.accessProfileStores.filter(a => a.storeID + "" === props.match.params.storeID + "")[0] || {store: storeEntityForm.blankForm}).store

    function handleStoreIDChange(newStoreID) {
        props.history.push("/daily/dailyReport/" + newStoreID + (props.match.params.saleDate === undefined ? "" : "/" + props.match.params.saleDate))
    }

    return (
        <div>
            <h2>
                Daily Report
            </h2>
            <p>
                Use the <strong>'Daily report header'</strong> field in <Link to="/data/analysisFields">analysis fields</Link> to indicate which totals you report on a daily basis.
            </p>
            <StoreTabs
                store={store}
                onChange={s => handleStoreIDChange(s === "all" ? "all" : s.storeID)}
                me={props.me}
                showAll={true}
            />
            <DatePicker 
                id="saleDate"
                label="Date"
                onChange={handleSaleDateChange}
                value={saleDate}
                error={saleDate > new Date()}
                helperText={saleDate > new Date() ? "This date is in the future" : ""}
            />
            {
                analysisFieldStores
                .map(afs => afs.analysisField) //a section for each analysisfield
                .reduce((acc, cur) => acc.map(a => a.analysisFieldID).includes(cur.analysisFieldID) ? acc : acc.concat(cur), []) //deduplicating the alaysis fields
                .map(af => {
                    var listItems = analysisFieldStores
                        .map(afs => afs.analysisField)
                        .filter(taf => taf.analysisFieldID === af.analysisFieldID) //only including this analysisfield
                        .reduce((acc, cur) => acc.concat(cur.analysisListItems), [])
                        .filter(ali => ali.dailyReportAs + "" !== "") //removing any that are not to be shown on the daily report

                    //if there are no list items then dont show anything
                    if(listItems.length === 0) {
                        return null
                    } else {
                        return (
                            <React.Fragment key={af.analysisFieldID}>
                                <h3>
                                    By {af.name}
                                </h3>
                                <DailyReportGrid
                                    me={props.me}
                                    store={store}
                                    sales={sales}
                                    analysisListItems={listItems}
                                    loading={loading}
                                    params={params}
                                />
                            </React.Fragment>
                        )
                    }
                })
            }
            
        </div>
    )
}