import React from 'react'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { Button } from '@material-ui/core';

import {getToken} from '../../../../lib/auth'
import ChartOrTable from './chartOrTable'

export default function TimeSlice(props) {
    if(props.timeSlice === undefined || props.me === undefined || !Array.isArray(props.timeSlice.tables) || props.timeSlice.tables.length === 0) {
        return (
            <p>
                Tables not found for this time period
            </p>
        )
    }

    function handleDownload() {
        //which url are we going to use?
        var url, dateParam
        if(props.drhOnly) {
            //only the drh tables.  no targets or anything
            //so this is the daily report
            url = "/kpi/drhTable/excel"
            dateParam = "&fromDate=" + props.fromDate + "&toDate=" + props.toDate
        } else {
            //this si the whole lot, for the landing page
            url = "/kpi/tables/excel"
            dateParam = "&date=" + props.date
        }
        window.location.href = (window.location.href + "salt").replace(window.location.pathname + "salt", "/api") + url + "?token=" + getToken() + "&fileName=" + encodeURIComponent((props.date === undefined ? props.fromDate + "to" + props.toDate : props.date) + "kpi") + "&storeID=" + props.storeIDs.join(",") + "&splitBy=" + props.splitBy + dateParam
    }

    return (
        <>
            {
                props.timeSlice.tables.map(t => 
                    <ChartOrTable
                        me={props.me}
                        table={t}
                        timeSlice={props.timeSlice}
                        loading={props.loadingKPI}
                        kpiParams={props.kpiParams}
                        key={t.name}
                    />    
                )
            }
            {
                props.me !== undefined && props.me.accessProfile.systemAreas.includes("AccessAPI") ?
                    <Button
                        startIcon={<CloudDownloadIcon/>}
                        onClick={handleDownload}
                        style={{marginTop: "10px"}}
                    >
                        Download Excel
                    </Button>
                : null
            }

        </>
    )
}