import React, {useState, useEffect} from 'react'

//project
import CheckBox from '../../../shared/checkBox'
import authFetch from '../../../../lib/authFetch'


//icons


export default function AccessProfileStoreSelector(props) {
    const [stores, setStores] = useState([])

    useEffect(() => {
        authFetch("/stores")
        .then(r => setStores(r.listData))
    }, [])

    function buildEvent(withNewVal) {
        var e = {
            target: {
                id: props.id,
                value: withNewVal
            }
        }
        //console.log("Calling change with " + JSON.stringify(withNewVal))
        props.onChange(e)
    }

    function handleCheckboxChange(event, storeID) {
        //decide if we're adding or removing it
        var newVals = []
        if(event.target.value) {
            //they have ticked, add it
            newVals.push(...props.value, {storeID: storeID, accessProfileID: props.accessProfileID})
        } else {
            //they have unticked
            //delete it
            props.value.forEach(s => {
                var thisOne = {...s}
                if(s.storeID === storeID) {
                    //skip this one, deleting it from the list
                } else {
                    newVals.push(thisOne)
                }
            })
        }
        //construct a pseudo event to pass up to the parent form
        buildEvent(newVals)
    }

    return (
        <div>
            {
                props.me === undefined ? null :
                stores.map(s => {
                    return (
                        <CheckBox key={s.name} disabled={props.disabled} label={s.name} onChange={e => handleCheckboxChange(e, s.storeID)} value={props.value.filter(ts => s.storeID === ts.storeID).length === 1} />
                    )
                })
            }
        </div>

    )
}