import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import authFetch from '../../../lib/authFetch';
import Grid from '@material-ui/core/Grid'
import dateFormat from 'dateformat'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import LookupCell from '../../shared/dataTable/lookupCell'
import recSheetLineEntityForm from './recSheetLineEntityForm'
import saleEntityForm from '../sales/entityForm/saleEntityForm';
import {formatMoney} from '../../../lib/general'
import AutoCompleteAsync from '../../shared/autoCompleteAsync'
import MatchIcon from './matchIcon'
import { Tooltip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import RecChangesModal from './recChangesModal'

const blankValidationResult = {fail: false, failSummary: '', failMessages: {}}

export default function ManualModal(props) {
    const [loading, setLoading] = useState(false)
    const [loadingSuggestions, setLoadingSuggestions] = useState(false)
    const [ids, setIDs] = useState([])
    const [currentID, setCurrentID] = useState(0)
    const [currentRSL, setCurrentRSL] = useState(recSheetLineEntityForm.blankForm)
    const [manualSaleID, setManualSaleID] = useState(0)
    const [manualSale, setManualSale] = useState(saleEntityForm.blankForm)
    const [recSuggest, setRecSuggest] = useState([])
    const [loadingRec, setLoadingRec] = useState(false)
    const [recChangesModalOpen, setRecChangesModalOpen] = useState(false)
    const [recChangesModalSale, setRecChangesModalSale] = useState(0)
    const [recChangesModalChanges, setRecChangesModalChanges] = useState([])
    const [validationResult, setValidationResult] = useState(blankValidationResult)
    const [auto, setAuto] = useState(false)
    const [preloaded, setPreloaded] = useState({recSheetLineID: 0, recSuggest: []})

    function handleEnter() {
        setPreloaded({recSheetLineID: 0, recSuggest: []})
        setLoading(true)
        resetForm()
        //console.log("RSLids " + JSON.stringify(props.recSheetLineIDs))
        //which lines are we trying to manual reconile?  If it's a blank array call the api to get all lines 
        if(props.recSheetLineIDs === undefined || props.recSheetLineIDs.length === 0) {
            //we have no ids so call the api
            //console.log("Going to API")
            authFetch("/recSheetLines?saleID=0&recSheetID=" + props.recSheetID)
            .then(r => {
                setNewIDs(r.listData.map(x => x.recSheetLineID))
            })
        } else {
            //we have some id's just use those
            setNewIDs(props.recSheetLineIDs)
        }
    }

    function resetForm() {
        setManualSale(saleEntityForm.blankForm)
        setManualSaleID(0)
        setRecSuggest([])
        setValidationResult(blankValidationResult)
        setRecChangesModalChanges([])
    }

    function setNewIDs(newIDs) {
        setIDs(newIDs)
        //set the first of our ids as the one we wnat to look at now
        if(newIDs.length > 0) {
            setNewCurrentID(newIDs[0])
        } else {
            setNewCurrentID(0)
        }
    }

    function handleBack() {
        setPreloaded({recSheetLineID: 0, recSuggest: []}) //clear down preloaded items
        setLoading(true)
        resetForm() //resetting the form
        //set the previous of the id's as the new current id
        var currentI = 0
        ids.forEach((c, i) => {
            if(c === currentID) {
                currentI = i
            }
        })
        var newC = 0 //this will be the new value if we can't find i+1 eg if we've come to the end of the list
        ids.forEach((x, i) => {
            if(i === currentI - 1) {
                newC = x
            }
        })
        setNewCurrentID(newC)
    }

    function handleNext() {
        setLoading(true)
        resetForm() //resetting the form
        //set the next of the id's as the new current id
        setNewCurrentID(getNextRSLID())
    }

    function getNextRSLID(lookForward) {
        var currentI = 0
        ids.forEach((c, i) => {
            if(c === currentID) {
                currentI = i
            }
        })
        var newC = 0 //this will be the new value if we can't find i+1 eg if we've come to the end of the list
        ids.forEach((x, i) => {
            if(i === currentI + (lookForward ? lookForward : 1)) {
                newC = x
            }
        })
        return newC
    }

    function setNewCurrentID(newRSLID) {
        setLoading(true)
        //do we have a preloaded recSuggest we can use?
        if(preloaded.recSheetLineID === newRSLID) {
            //yes we preloaded it
            //console.log("Preload found: " + JSON.stringify(preloaded))
            setRecSuggest(preloaded.recSuggest)
            setLoadingSuggestions(false)
        } else {
            //no they will have to wait for it to load
            //console.log("No preload: " + JSON.stringify(preloaded))
            setRecSuggest([])
            setLoadingSuggestions(true)
        }
        setManualSaleID(0)
        setManualSale(saleEntityForm.blankForm)
        setCurrentID(newRSLID)
        const myAbortController = new AbortController()
        if(newRSLID === 0) {
            //nothing to get from API
            setLoading(false)
            setCurrentRSL({})
        } else {
            var nextRSLID
            //there is a new vlaid ID.  Get the details from the API
            authFetch("/recSheetLines?recSheetLineID=" + newRSLID, {signal: myAbortController.signal})
            .then(r => {
                setLoading(false)
                if(r.listData.length === 1) {
                    setCurrentRSL(r.listData[0])
                } else {
                    //no idea what happened
                    setCurrentRSL(recSheetLineEntityForm.blankForm)
                }
                //now get the suggestions
                //so we are reloading them anyway, even though we might have preloaded them, just in case something in the previous rec has an effect on this one
                return authFetch("/recSheetLine/" + newRSLID + "/recSuggest", {signal: myAbortController.signal})
            })
            .then(r => {
                setRecSuggest(r.listData)
                setLoadingSuggestions(false)
                //now preload the next one.
                nextRSLID = getNextRSLID(2)
                if(nextRSLID === 0) {
                    return Promise.resolve("none")
                } else {
                    //console.log("Preloading " + nextRSLID)
                    return authFetch("/recSheetLine/" + nextRSLID + "/recSuggest", {signal: myAbortController.signal})
                }
            })
            .then(r => {
                if(r === "none") {
                    //nothing to do
                } else {
                    //store the preload
                    setPreloaded({
                        recSheetLineID: nextRSLID,
                        recSuggest: r.listData
                    })
                    //console.log("Preloaded " + nextRSLID)
                }
            })
        }
    }

    function handleAutoChange() {
        setAuto(!auto)
    }

    function handleReconcileClick(toSale) {
        setLoadingRec(true)
        //if everything matches no modal
        //but if anything is different open a modal which will ask whether the sale should be updated
        if(
            revenueMatches(toSale.unreconciledRevenue) &&
            skuMatches(toSale.sku) &&
            orderRefMatches(toSale.orderRef) &&
            saleDateMatches(toSale.saleDate) 
        ) {
            //no modal becasue everything matches
            doReconciliation(toSale.saleID, [], "")
        } else {
            //build the changes object
            //it;s an array of [{name: "storeName", displayName: "store", change: true, label: "xxx"}]
            var changes = buildChangesArray(toSale)
            //do we need to show the modal?  Check if auto accept changes is true
            if(auto) {
                //skip straight to the reconciliation
                doReconciliation(toSale.saleID, changes, "changes auto-accepted")
            } else {
                //no auto. show the modal to accept the changes
                //set up the modal change sale details
                setRecChangesModalSale(toSale)
                //built from the passed sale, and the currentRSL
                setRecChangesModalChanges(changes)
                //perform a vlaidation only api call so that any validation result messages are visible to the user (ie invalid sku)
                authFetch("/recSheetLine/" + currentID + "/recSale/" + toSale.saleID + "/validate", {method: "PUT", body: JSON.stringify({
                    recNotes: "",
                    changes
                })})
                .then(r => {
                    setValidationResult(r.validationResult)
                    setRecChangesModalOpen(true)
                })
            }
        }
    }

    function buildChangesArray(toSale) {
        var r = []
        //build the array of changes to pass to the changes modal
        if(!revenueMatches(toSale.unreconciledRevenue)) {
            r.push({
                name: "revenue",
                change: true,
                label: <span>Adjust revenue by {formatMoney(currentRSL.revenue - toSale.unreconciledRevenue)} (this must always be changed so that revenue is accurate)</span>
            })
        }
        // if(!storeNameMatches(toSale.storeName)) {
        //     r.push({
        //         name: "storeName",
        //         change: true,
        //         newVal: currentRSL.storeName, //providing so the modal can check this is valid with the api
        //         label: <span>Change store from <strong>{toSale.storeName}</strong> to <strong>{currentRSL.storeName}</strong>.  Note: the system will automatically change the adjustment amount if the new store has a different revenue</span>
        //     })
        // }
        if(!orderRefMatches(toSale.orderRef)) {
            r.push({
                name: "orderRef",
                change: true,
                label: <span>Change order ref from <strong>{toSale.orderRef}</strong> to <strong>{currentRSL.orderRef}</strong></span>
            })
        }
        if(!skuMatches(toSale.sku)) {
            //we don't suggest a change if the sku is blank.  this is to help reconcile PAYG and TOPUP
            if(currentRSL.sku + "" === "") {
                //this is a row with a blank sku.  we are ignoring sku for this one.  do not suggest the change

            } else {
                //sku is different so change it
                r.push({
                    name: "sku",
                    change: true,
                    newVal: currentRSL.sku, //providing so the modal can check this is valid with the api
                    label: <span>Change SKU from <strong>{toSale.sku}</strong> to <strong>{currentRSL.sku}</strong>.  Note: the system will automatically change the adjustment amount if the new SKU has a different revenue</span>
                })
            }
        }
        if(!saleDateMatches(toSale.saleDate)) {
            r.push({
                name: "saleDate",
                change: true,
                label: <span>Change sale date from <strong>{dateFormat(toSale.saleDate, "dd/mm/yyyy")}</strong> to <strong>{dateFormat(currentRSL.saleDate, "dd/mm/yyyy")}</strong></span>
            })
        }
        //console.log("Changes for modal: " +JSON.stringify(r))
        return r
    }

    function handleRecChangesModalClose() {
        //they want to cancel making the rec
        setRecChangesModalOpen(false)
        setLoadingRec(false)
    }

    function handleRecChangesModalAction(toSaleID, changes, recNotes) {
        doReconciliation(toSaleID, changes, recNotes)
    }

    function doReconciliation(toSaleID, changes, recNotes) {
        //make the API call to do the reconciliation (making changes to the sale as per the changes array) [{name: "storeName", displayName: "store", change: true, label: "rrr"}]
        authFetch("/recSheetLine/" + currentID + "/recSale/" + toSaleID, {method: "PUT", body: JSON.stringify({
            recNotes,
            changes
        })})
        .then(r => {
            setValidationResult(r.validationResult)
            setLoadingRec(false)
            //if success then close the modal (if it was open)
            if(r.validationResult.fail) {
                //leave the modal open so the user can see the problem
            } else {
                //success, close the modal
                setRecChangesModalOpen(false)
                //move the user on to the next id
                handleNext()
            }
        })
        
    }

    //returns where we are in the sequence of manual rec lines, 1 based
    function placeInSequence() {
        return ids.reduce((acc, cur, i) => cur === currentID ? i + 1 : acc, 0)
    }

    function handleManualSaleChange(e) {
        //console.log("Changed MS to " + e.target.value)
        setManualSaleID(e.target.value)
        //is it zero?
        if(e.target.value === 0) {
            //yes
            setManualSale(saleEntityForm.blankForm)
        } else {
            //no it's an actual sale ID
            authFetch("/sales?saleID=" + e.target.value)
            .then(r => {
                setManualSale(r.listData[0])
            })
        }
    }

    function makeLabel(fromSale) {
        return fromSale.saleID + " - " + fromSale.storeName + " - " + dateFormat(fromSale.saleDate, "dd/mm/yyyy") + " - " + fromSale.sku + " - " + fromSale.orderRef + " - " + formatMoney(fromSale.adjustedRevenue)
    }

    function revenueMatches(saleVal) {
        return (saleVal !== undefined && currentRSL.revenue !== undefined && saleVal + "" === currentRSL.revenue + "")
    }

    // function storeNameMatches(saleVal) {
    //     return (saleVal !== undefined && currentRSL.storeName !== undefined && (saleVal + "").toLowerCase() === (currentRSL.storeName + "").toLowerCase())
    // }

    function historyStepMatches(currentHistoryStep) {
        return (["recorded","modified"].includes(currentHistoryStep))
    }


    function skuMatches(saleVal) {
        return (saleVal !== undefined && currentRSL.sku !== undefined && (saleVal + "").toLowerCase() === (currentRSL.sku + "").toLowerCase())
    }

    function orderRefMatches(saleVal) {
        return (saleVal !== undefined && currentRSL.orderRef !== undefined && (saleVal + "").toLowerCase() === (currentRSL.orderRef + "").toLowerCase())
    }

    function saleDateMatches(saleVal) {
        return (saleVal !== undefined && currentRSL.saleDate !== undefined && dateFormat(saleVal, "dd/mm/yyyy") === dateFormat(currentRSL.saleDate, "dd/mm/yyyy"))
    }

    //console.log("Manual sale id " + manualSaleID)

    return(
        <Dialog open={props.open} maxWidth="xl" fullWidth={true} aria-labelledby="form-dialog-title" onEnter={handleEnter}>
            <DialogTitle id="form-dialog-title">
                Manual reconciliation {
                    currentID === 0 ? "" :
                    "(" + placeInSequence() + " of " + ids.length + ")"
                }
            </DialogTitle>
            <DialogContent style={{minHeight: "550px"}}>
                {
                    loading ?
                        <div style={{width: "100%", textAlign: "center"}}>
                            <CircularProgress size={100} />
                        </div>
                    :
                        currentID === 0 ?
                            <p>There are no more unreconciled rec sheet lines.  Click close.</p>
                        :
                            currentRSL.saleID === 0 ? 
                                <div>
                                    <h4>
                                        Reconcile this revenue sheet line:
                                    </h4>
                                    <Paper style={{width: "100%", minHeight: "32px"}} elevation={3}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={1}>
                                                <LookupCell 
                                                    id={currentID} 
                                                    entityForm={recSheetLineEntityForm}
                                                    label={currentRSL.recSheetLineID}
                                                    me={props.me}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                {currentRSL.storeName}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {dateFormat(currentRSL.saleDate, "dd/mm/yyyy")}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {currentRSL.sku}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {currentRSL.orderRef}
                                            </Grid>
                                            <Grid item xs={1}>
                                                {formatMoney(currentRSL.revenue)}
                                            </Grid>
                                            <Grid item xs={2}>
                                                
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                    <h4>
                                        To one of these suggested sale/refund transactions (closest match first):
                                    </h4>
                                    <div style={{minHeight: "165px"}}>
                                        {
                                            loadingSuggestions ? 
                                                <Paper style={{width: "100%"}} elevation={3}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <CircularProgress size={20} /> Searching for similar sales...
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            :
                                                recSuggest.length === 0 ?
                                                    <Paper style={{width: "100%"}} elevation={3}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Alert severity="info" style={{margin: "8px"}} >
                                                                    The system cannot suggest any sales or refunds which look like they could be matches.  Please use the manual search below instead.
                                                                </Alert>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                :
                                                    recSuggest.map(s => {
                                                        return (
                                                            <Paper style={{width: "100%"}} elevation={3} key={s.saleID}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={1}>
                                                                        <LookupCell 
                                                                            id={s.saleID} 
                                                                            entityForm={saleEntityForm}
                                                                            label={s.saleID}
                                                                            me={props.me}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Tooltip
                                                                            title="Store name is only a memo - the system is not taking it into account when deciding the closeness of the match"
                                                                        >
                                                                            <span>
                                                                                {s.storeName} 
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        {dateFormat(s.saleDate, "dd/mm/yyyy")} <MatchIcon value={saleDateMatches(s.saleDate)} />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        {s.sku}  <MatchIcon value={skuMatches(s.sku)} />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        {s.orderRef} <MatchIcon value={orderRefMatches(s.orderRef)} />
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Tooltip title={"Revenue " + formatMoney(s.revenue) + " - already reconciled " + formatMoney(s.recSheetLinesTotal) + " = unreconciled " + formatMoney(s.unreconciledRevenue)}>
                                                                            <div>
                                                                                {formatMoney(s.unreconciledRevenue)} <MatchIcon value={revenueMatches(s.unreconciledRevenue)} />
                                                                            </div>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        {s.currentHistoryStep.substring(0,1).toUpperCase() + s.currentHistoryStep.substring(1)} <MatchIcon value={historyStepMatches(s.currentHistoryStep)} />
                                                                    </Grid>
                                                                    <Grid item xs={2} style={{textAlign: "right"}}>
                                                                        <Button color="primary" onClick={() => handleReconcileClick(s)} variant="contained" disabled={loadingRec} style={{marginRight: "5px"}}>
                                                                            Reconcile
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        )
                                                    })
                                        }
                                    </div>
                                    <h4>
                                        Or manually search for a transaction to reconcile to:
                                    </h4>
                                    <Paper style={{width: "100%"}} elevation={3}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <div style={{padding: "7px"}}>
                                                    <AutoCompleteAsync 
                                                        multi={false}
                                                        id="manualSaleID"
                                                        label="Search for a transaction"
                                                        onChange={handleManualSaleChange}
                                                        placeholder="Search..."
                                                        value={manualSaleID}
                                                        getURL={input => (input === "" || input === undefined) ? "" : "/sales?pageLength=50&page=0&sort=saleID&saleDateGEQ=" + dateFormat(new Date().setFullYear(new Date().getFullYear() - 1), "yyyy-mm-dd") + "&unreconciledRevenueNot=0&quickFilter=" + encodeURIComponent(input)}
                                                        valueField="saleID"
                                                        labelFn={v => makeLabel(v)}
                                                        getURLValues={"/sales?saleID=" + manualSaleID}
                                                        labelValues={v => makeLabel(v)}
                                                        idField="saleID"
                                                        clearable
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <LookupCell 
                                                    id={manualSaleID} 
                                                    entityForm={saleEntityForm}
                                                    label={manualSale.saleID}
                                                    me={props.me}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                {
                                                    manualSaleID === 0 ?
                                                        "-"
                                                    :
                                                        <div>
                                                            {manualSale.storeName} 
                                                        </div>
                                                }
                                            </Grid>
                                            <Grid item xs={2}>
                                                {
                                                    manualSaleID === 0 ?
                                                        "-"
                                                    :
                                                        <div>
                                                            {dateFormat(manualSale.saleDate, "dd/mm/yyyy")} <MatchIcon value={saleDateMatches(manualSale.saleDate)} />
                                                        </div>
                                                }
                                            </Grid>
                                            <Grid item xs={2}>
                                                {
                                                    manualSaleID === 0 ?
                                                        "-"
                                                    :
                                                        <div>
                                                            {manualSale.sku} <MatchIcon value={skuMatches(manualSale.sku)} />
                                                        </div>
                                                }
                                            </Grid>
                                            <Grid item xs={2}>
                                                {
                                                    manualSaleID === 0 ?
                                                        "-"
                                                    :
                                                        <div>
                                                            {manualSale.orderRef} <MatchIcon value={orderRefMatches(manualSale.orderRef)} />
                                                        </div>
                                                }
                                            </Grid>
                                            <Grid item xs={1}>
                                                {
                                                    manualSaleID + "" === "0" ?
                                                        "-"
                                                    :   
                                                        <Tooltip title={"Revenue " + formatMoney(manualSale.revenue) + " - already reconciled " + formatMoney(manualSale.recSheetLinesTotal) + " = unreconciled " + formatMoney(manualSale.unreconciledRevenue)}>
                                                            <div>
                                                                {formatMoney(manualSale.unreconciledRevenue)} <MatchIcon value={revenueMatches(manualSale.unreconciledRevenue)} />
                                                            </div>
                                                        </Tooltip>
                                                }
                                            </Grid>
                                            <Grid item xs={1}>
                                                {
                                                        manualSaleID + "" === "0" ?
                                                            "-"
                                                        :  
                                                            <div>
                                                                {manualSale.currentHistoryStep.substring(0,1).toUpperCase() + manualSale.currentHistoryStep.substring(1)} <MatchIcon value={historyStepMatches(manualSale.currentHistoryStep)} />
                                                            </div>
                                                }
                                            </Grid>
                                            <Grid item xs={2} style={{textAlign: "right"}}>
                                                <Button color="primary" onClick={() => handleReconcileClick(manualSale)} variant="contained" disabled={manualSaleID === 0 || loadingRec} style={{marginRight: "5px"}}>
                                                    Reconcile
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                    <RecChangesModal
                                        open={recChangesModalOpen}
                                        onAction={handleRecChangesModalAction}
                                        onClose={handleRecChangesModalClose}
                                        sale={recChangesModalSale}
                                        changes={recChangesModalChanges}
                                        validationResult={validationResult}
                                    />
                                </div>
                            :
                                <Alert
                                    severity="success"
                                >
                                    Rec sheet line <strong>{currentID}</strong> is already reconciled to sale <strong>{currentRSL.saleID}.</strong>
                                </Alert>
                }
            </DialogContent>
            <DialogActions>
                <FormControlLabel
                    control={<Switch checked={auto} onChange={handleAutoChange} name="autoCheck" />}
                    label="Auto accept changes"
                />
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
                <Button color="default" onClick={handleBack} variant="contained" disabled={placeInSequence() === 1 || currentID === 0}>
                    Back
                </Button>
                <Button color="default" onClick={handleNext} variant="contained" disabled={placeInSequence() === ids.length || currentID === 0}>
                    Skip
                </Button>
            </DialogActions>
      </Dialog>
    )
}