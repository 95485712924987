import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery'

import JustifyStepsModal from './justifyStepsModal'
import HistoryDataTable from './historyDataTable';

export default function HistoryModal(props) {
    const [justifyOpen, setJustifyOpen] = useState(false);

    function handleJustifyOpen() {
        setJustifyOpen(true)
    }

    function handleJustifyClose() {
        setJustifyOpen(false)
    }
    
    const noFullscreen = useMediaQuery("(min-width:1000px)")


    return (
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth={"lg"} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {props.entityDisplayName} {props.documentID} History
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Here is the complete history of the {props.entityDisplayName.toLowerCase()}.  Click <a onClick={handleJustifyOpen} style={{color: "blue", textDecoration: "underline"}}>here</a> for an explanation of why the {props.entityDisplayName.toLowerCase()} is taking the steps it is.
                </DialogContentText>
                {
                    //if the ticket ID is 0 this implies it's a new ticket, no history will be available
                    props.documentID === 0 ?
                        "This is a new " + props.entityDisplayName + ", no history is available yet..."
                    :
                        <HistoryDataTable 
                            documentID={props.documentID} 
                            steps={props.steps} 
                            //entityName={props.entityName}
                            entityDisplayName={props.entityDisplayName}
                            documentType={props.documentType}
                        />
                }
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
            <JustifyStepsModal 
                steps={props.steps} 
                open={justifyOpen} 
                onClose={handleJustifyClose}
                //entityName={props.entityName}
                entityDisplayName={props.entityDisplayName}
            />
        </Dialog>
    )
}