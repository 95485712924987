import React from 'react'
import dateFormat from 'dateformat'
import {Link} from 'react-router-dom'

import DataTable, {addAuditFields} from '../../shared/dataTable/dataTable'
import { formatMoney } from '../../../lib/general'
import AnnouncementEntityForm from './entityForm/announcementEntityForm'
import AnnouncementTitle from './announcementTitle'

const filters = [
]

const columnData = [
    { fieldName: 'announcementID', default: true, name: 'ID', description: "The unchanging identifier of the announcement"},
    { fieldName: 'title', default: true, name: 'Title', description: "Title", renderCell: dr => <AnnouncementTitle type={dr.type} title={dr.title} />},
    { fieldName: 'announcementUserCount', default: true, name: 'Users', description: 'How many users this announcement is being made to', renderCell: dr => <Link to={'/announcements/' + dr.announcementID + '/announcementUsers'}>{formatMoney(dr.announcementUserCount, 0)}</Link>},
    { fieldName: 'seenUserCount', default: true, name: 'Seen', description: 'How many users have been shown this announcement', renderCell: dr => <Link to={'/announcements/' + dr.announcementID + '/announcementUsers'}>{formatMoney(dr.seenUserCount, 0) + (dr.announcementUserCount === 0 ? "" : " (" + formatMoney(100 * dr.seenUserCount / dr.announcementUserCount, 0) + "%)")}</Link>},
    { fieldName: 'clickedUserCount', default: true, name: 'Clicked', description: 'How many users have clicked this announcement (the link or the file)', renderCell: dr => dr.clickType === "none" ? "N/a" : <Link to={'/announcements/' + dr.announcementID + '/announcementUsers'}>{formatMoney(dr.clickedUserCount, 0) + (dr.announcementUserCount === 0 ? "" : " (" + formatMoney(100 * dr.clickedUserCount / dr.announcementUserCount, 0) + "%)")}</Link>},
    { fieldName: 'acknowledgedUserCount', default: true, name: "OK'd", description: 'How many users have clicked OK to acknowledge this announcement (so it no longer appears)', renderCell: dr => dr.acknowledgable ? <Link to={'/announcements/' + dr.announcementID + '/announcementUsers'}>{formatMoney(dr.acknowledgedUserCount, 0) + (dr.announcementUserCount === 0 ? "" : " (" + formatMoney(100 * dr.acknowledgedUserCount / dr.announcementUserCount, 0) + "%)")}</Link> : "N/a"},
    { fieldName: 'displayUntil', default: true, name: 'Until', description: "The announcement will be displayed until this date", renderCell: dr => dateFormat(new Date(dr.displayUntil), "dd/mm/yyyy") },
];

export default function AnnouncementsList(props) {

    return(
        <div>
            <h1>
                Announcements
            </h1>
            <p>
                Use announcements to make users aware of messages, files, and links.  Announcements will appear at the top of the page when the user logs in.
            </p>
            <DataTable
                columnData={addAuditFields(columnData)}
                entityForm={AnnouncementEntityForm}
                filters={filters} 
                title="Announcements"
                enableCrud={true}
                sort="announcementID"
                sortDir="desc"
                me={props.me}
            />
        </div>
    )
}