import React from 'react'

export default function RotaStore(props) {
    

    return(
        <div>
            Rota store
        </div>
    )
}