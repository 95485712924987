import React from 'react'

import FileUploader from '../../../../shared/form/fileUploader'

export default function Step0(props) {
    function handleChange(event) {
        //merge it with the existing form vals and call the parent handler function
        var newFormVals = {...props.formVals, [event.target.id]: event.target.value}
        //console.log(JSON.stringify(newFormVals))
        props.onChange(newFormVals)
    }


    return(
        <div>
            <h4>
                Choose the file
            </h4>
            <p>Pick the Excel file containing the rate card lines to upload.  Here are the rules:</p>
            <ul>
                <li>
                    The system will look at the first worksheet in the file
                </li>
                <li>
                    The first row must contain the headers
                </li>
                <li>
                    Different stores can be uploaded in different files, BUT
                </li>
                <li>
                    Revenue and commission for a given store must be uploaded in the same file
                </li>
            </ul>
            <FileUploader 
                onChange={handleChange} 
                value={props.formVals.fileID}
                id="fileID"
                disabled={false}
                error={props.validationResult.failMessages.hasOwnProperty("fileID")}
                helperText={props.validationResult.failMessages["fileID"]}
                library="rateCardLineUploads"
                label="Rate card line Excel file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                urlType="excel"
            />
        </div>
    )
}