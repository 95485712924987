import React, {useState} from 'react'
import Button from '@material-ui/core/Button'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Badge from '@material-ui/core/Badge';
import ChooseUserModalModal from './chooseUserModal';

export default function ChooseUsers(props) {
    const [open, setOpen] = useState(false)

    return(
        <>
            <Badge
                max={999} 
                badgeContent={props.value.length} 
                color={props.value.length === 0 ? "secondary" : "primary"}
                showZero
            >
                <Button
                    variant="outlined"
                    endIcon={<OpenInNewIcon />}
                    onClick={e => setOpen(true)}
                >
                    Choose users who will see this announcement
                </Button>
            </Badge>
            <ChooseUserModalModal
                me={props.me}
                onClose={e => setOpen(false)}
                value={props.value}
                onChange={props.onChange}
                open={open}
                id={props.id}
                announcementID={props.announcementID}
            />
        </>
    )
}