import React, {useState, useEffect} from 'react'
import { TextField } from '@material-ui/core'
import dateFormat from 'dateformat'

import { ensureArray } from '../../../lib/general'
import {blankTarget} from './targetsTable'

//does the bare minimum in order to switch modes to hoursInput
function setDefaultHoursWeights(targets, users, store, analysisFields, targetDate) {
    //returns new set of targets witht he changes applied
    var newTargets = [ //mutatable targets copy
        ...targets
    ]
    //will apply values to the first user in the list
    if(users.length > 0) {
        //set hours and weight to 1
        newTargets = setUserHoursField(newTargets, users[0], store, analysisFields, targetDate, "hours", 1)
        newTargets = setUserHoursField(newTargets, users[0], store, analysisFields, targetDate, "weight", 100)
    }
    //unable to make the change
    return newTargets
}

//sets the value for a row (user) in the targets array, and returns the new targets array
function setUserHoursField(targets, user, store, analysisFields, targetDate, hoursField, newValue) {
    //console.log("Targets befroe switch: " + JSON.stringify(targets))
    //make sure there is a target for every column for this user, setting the weight or hours accordingly
    var newTargets = [ //mutatable targets copy
        ...targets
    ]
    //store
    newTargets = setStoreHoursForUser(newTargets, user, store, targetDate, "targetQuantity", "quantity", hoursField, newValue)
    newTargets = setStoreHoursForUser(newTargets, user, store, targetDate, "targetRevenue", "revenue", hoursField, newValue)
    //analysisFields
    newTargets = setAnalysisFieldHoursForUser(newTargets, user, store, analysisFields, targetDate, "targetQuantity", "quantity", hoursField, newValue)
    newTargets = setAnalysisFieldHoursForUser(newTargets, user, store, analysisFields, targetDate, "targetRevenue", "revenue", hoursField, newValue)
    //set this as the new list of targets
    return newTargets
}

function recalculateHoursTargets(targets, store, targetDate, analysisFields) {
    //calculate the new targets for the user based on the total, their hours, and the weight
    var newTargets = [ //mutatable targets copy
        ...targets
    ]
    //store
    recalculateStoreHoursTargets(targets, store, targetDate)
    //analysisFields
    recalculateAnalysisFieldsHoursTargets(targets, store, targetDate, analysisFields)
    //return this targets array
    return newTargets
}

function recalculateStoreHoursTargets(targets, store, targetDate) {
    //all store targets first
    var storeTargets = targets.filter(t => 
        t.storeID === store.storeID && //is this store
        dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(targetDate, "yyyy-mm-dd") && //is this month
        t.userID !== 0 && //is a user specific target (top half of the table, not store totals)
        t.analysisFieldID === 0 && //is not an anlaysisfield target
        t.analysisListItemID === 0 //ditto
    )
    //first revenue
    recalculateColumnHoursTargets(storeTargets.filter(t => t.targetType === "revenue"))
    //then quantity
    recalculateColumnHoursTargets(storeTargets.filter(t => t.targetType === "quantity"))
}

function recalculateAnalysisFieldsHoursTargets(targets, store, targetDate, analysisFields) {
    analysisFields.forEach(af => {
        //go through each potential column (value) in the analyssifield
        af.analysisListItems.filter(ali => ali.enableTargets).forEach(ali => {
            //get all targets related to this ali column
            var aliTargets = targets.filter(t => 
                t.storeID === store.storeID && //is this store
                dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(targetDate, "yyyy-mm-dd") && //is this month
                t.userID !== 0 && //is a user specific target (top half of the table, not store totals)
                t.analysisFieldID === af.analysisFieldID && //is the right af
                t.analysisListItemID === ali.analysisListItemID //is the right analysislistitem
            )
            //first revenue
            recalculateColumnHoursTargets(aliTargets.filter(t => t.targetType === "revenue"))
            //then quantity
            recalculateColumnHoursTargets(aliTargets.filter(t => t.targetType === "quantity"))
        })
    })
    
}

function recalculateColumnHoursTargets(targets, usingTargetTotal) {
    //recalculates the targets for a particular store column
    //ONLY PASS IN applicable targets to this column, they will be mutated
    //do we actually have any?
    if(targets.length === 0) {return}
    //if usingTotal is not passed, then work out the total from the taregts
    if(usingTargetTotal === undefined) {
        //calculate the total since it is not passed
        //sum of targets
        usingTargetTotal = targets.reduce((acc, cur) => acc + parseInt(cur.target), 0)
    }
    //now distribute the values so that they are in proportion to the weighted hours, but still add up to the usingTotal
    //get the total weighted hours
    var totalWeightedHours = targets.map(t => 
            t.hours * t.weight
        ).reduce((acc, cur) => acc + cur, 0)
    //guard against zero
    if(totalWeightedHours === 0) {totalWeightedHours = 1} //shouldnt happen since we guard against this int he UI
    //what is the target value per weighted hour
    var targetPerWeightedHour = (usingTargetTotal / totalWeightedHours)
    //console.log("Targets per weighted hour: " + targetPerWeightedHour)
    //recalculate each target
    targets.forEach(t => {
        //round UP (per booth and others who say this is how they do it)
        t.target = Math.ceil(t.hours * t.weight * targetPerWeightedHour)
    })
    //now we need to distribute the difference between usingTargetTotal and our total
    //what is the difference?
    var newTargetTotal = targets.map(t => t.target).reduce((acc, cur) => acc + cur, 0)
    var differenceToAdd = usingTargetTotal - newTargetTotal
    //console.log("Difference to add is " + differenceToAdd)
    //if the difference to add is negative, add it to the lowest first (so that small numbers dont result in targets only for least-working staff!).
    targets.sort(byWeightedHours)
    //do we need to reverse it?  If it's a positive difference then yes, give the positive difference to the highest workers
    if(differenceToAdd > 0) {
        targets.reverse()
    }
    //console.log("Final sort is: " + targets.map(t => t.weight * t.hours).join(","))
    //console.log("Targets are: " + targets.map(t => t.target).join(","))
    //now loop through each one, adding or removing as necessary
    targets
    .filter(t => (t.hours * t.weight) !== 0) //dont affect any with 0 weightedHours
    .forEach(t => {
        //have wea lready done enough yet?
        if(differenceToAdd !== 0) {
            //no, still more needed
            //we will only subtract from this target, if it's not already 0
            if(differenceToAdd < 0 && t.target === 0) {
                //cant do this one it would result in a negative target
            } else {
                //modify this target by this amount
                var changeBy = (differenceToAdd < 0 ? -1 : 1)
                //make it so
                t.target = t.target + changeBy
                //update our running difference
                differenceToAdd = differenceToAdd + (changeBy * -1)
            }
        }
    })
    //finally - set isTarget based on whether the target is 0 or not
    targets.forEach(t => {
        if(t.target === 0) {
            t.isTarget = false
        } else {
            t.isTarget = true
        }
    })
}

function byWeightedHours(a, b) {
    //calculate the working hours for a and b
    var aWH = a.hours * a.weight
    var bWH = b.hours * b.weight
    //sort by these working hours
    if (aWH < bWH) {
        return -1;
    }
    if (aWH > bWH) {
        return 1;
    }
    // a must be equal to b
    return 0;
}

function setAnalysisFieldHoursForUser(targets, user, store, analysisFields, targetDate, analysisFieldTargetField, targetType, hoursField, newValue) {
    var newTargets = [ //mutatable targets copy
        ...targets
    ]
    //do this for each analysisField
    analysisFields.forEach(analysisField => {
        //does the store capture this type of target?
        if(analysisField[analysisFieldTargetField]) {
            //yes it does. go through each targetable analysisListItem to set the value on each
            analysisField.analysisListItems.filter(ali => ali.enableTargets).forEach(ali => {
                //set the value for this ali
                //is there already a target of this type for this user?
                var matchingTargets = newTargets.filter(t => 
                    t.storeID === store.storeID && //is this store
                    dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(targetDate, "yyyy-mm-dd") && //is this month
                    t.targetType === targetType && //is this target type (rev or qty)
                    t.userID === user.userID && //is the value for this user
                    t.analysisFieldID === analysisField.analysisFieldID && //is for this analysisField
                    t.analysisListItemID === ali.analysisListItemID && //is this list item
                    t.targetForUserID === user.userID //is a target for this user
                )
                //if we have it, change it
                if(matchingTargets.length > 0) {
                    //update it (should only be one!)
                    matchingTargets.forEach(mt => {
                        mt[hoursField] = newValue
                    })
                    //console.log("Updated " + matchingTargets.length + " af " + hoursField + " targets")
                } else {
                    //does not already exist, create this target
                    newTargets.push({
                        ...blankTarget,
                        storeID: store.storeID,
                        targetDate: dateFormat(targetDate, "yyyy-mm-dd"),
                        targetType,
                        userID: user.userID,
                        analysisFieldID: analysisField.analysisFieldID,
                        analysisListItemID: ali.analysisListItemID,
                        targetForUserID: user.userID,
                        [hoursField]: newValue,
                    })
                    //console.log("Created af " + hoursField + " target with val " + newValue)
                }
            })
        }
    })
    //return what we have done
    return newTargets
}

function setStoreHoursForUser(targets, user, store, targetDate, storeTargetField, targetType, hoursField, newValue) {
    var newTargets = [ //mutatable targets copy
        ...targets
    ]
    //does the store capture this type of target?
    if(store[storeTargetField]) {
        //yes it does
        //is there already a target of this type for this user?
        var matchingTargets = newTargets.filter(t => 
            t.storeID === store.storeID && //is this store
            dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(targetDate, "yyyy-mm-dd") && //is this month
            t.targetType === targetType && //is this target type (rev or qty)
            t.userID === user.userID && //is the value for this user
            t.analysisFieldID === 0 && //is not a value for a particular analysisField
            t.targetForUserID === user.userID //is a target for this user
        )
        //if we have it, change it
        if(matchingTargets.length > 0) {
            //update it (should only be one!)
            matchingTargets.forEach(mt => {
                mt[hoursField] = newValue
            })
            //console.log("Updated " + matchingTargets.length + " store " + hoursField + " targets")
        } else {
            //does not already exist, create this target
            newTargets.push({
                ...blankTarget,
                storeID: store.storeID,
                targetDate: dateFormat(targetDate, "yyyy-mm-dd"),
                targetType,
                userID: user.userID,
                analysisFieldID: 0,
                targetForUserID: user.userID,
                [hoursField]: newValue,
            })
            //console.log("Created store " + hoursField + " target with val " + newValue)
        }
    }
    //return what we have done
    return newTargets
}

function hoursValueFromTargets(targets, hoursField, targetDate, store, user) {
    //the relevent targets which match the current cell
    var matchingTargets = ensureArray(targets).filter(t => 
        t.storeID === store.storeID &&
        dateFormat(t.targetDate, "yyyy-mm-dd") === dateFormat(targetDate, "yyyy-mm-dd") &&
        t.targetForUserID === user.userID &&
        t.userID === user.userID
    )
    return matchingTargets.length === 0 ? "0" : ("" + matchingTargets[0][hoursField])
}

export default function HoursCell(props) {
    const [valueDisplayed, setValueDisplayed] = useState("")

    //on first load the value displayed is from the targets
    useEffect(() => {
        setValueDisplayed(
            hoursValueFromTargets(props.allTargets, props.id, props.targetDate, props.store, props.user)
        )
    }, [hoursValueFromTargets(props.allTargets, props.id, props.targetDate, props.store, props.user)])


    var tempValue = ""

    function handleChange(e) {
        //cache it for half a second or so before recalculating everything
        //otherwise use isnt going to be able to type anything
        tempValue = e.target.value //variable stored globally to capture chagnes from all renders.  State didnt work for this purpose
        setValueDisplayed(tempValue)
        setTimeout(function() {
            //is it still the same now?
            if(tempValue === e.target.value) {
                //no change in the time so this is the new value
                var checkPat = new RegExp("^[0-9]+([.][0-9]{0,2})?$")
                if(checkPat.test(tempValue)) {
                    //calculate a new list of targets based on the change that has been made
                    var newTargets = setUserHoursField(
                        props.allTargets,
                        props.user,
                        props.store,
                        props.analysisFields,
                        props.targetDate,
                        props.id,
                        parseFloat(tempValue)
                    )
                    //check: bear in mind if all hours and weights are 0 then we are in the other mode: non-hoursInput
                    var weightedHours = newTargets.map(t => parseFloat(t.hours) * parseFloat(t.weight)).reduce((acc, cur) => acc + cur, 0)
                    console.log("Weighted hours total: " + weightedHours)
                    //we need to avoid inadvertently switching back
                    //are all hours and weights now 0?
                    if(weightedHours === 0) {
                        //yes they are all zero, we need to avoid inadvertently switching modes
                        newTargets = setDefaultHoursWeights(newTargets, props.users, props.store, props.analysisFields, props.targetDate)
                    }
                    //recalculate all tagets
                    recalculateHoursTargets(newTargets, props.store, props.targetDate, props.analysisFields)
                    //set this as the new list of targets
                    props.onTargetsChange(newTargets)
                }
            }
        }, 750)
    }

    return (
        <TextField
            inputProps={{
                style: {textAlign: "right"}
            }}
            margin="dense"
            label={props.label}
            type="text"
            value={valueDisplayed}
            onChange={handleChange}
            fullWidth
            disabled={props.disabled}
        />
    )
}

export {setDefaultHoursWeights, hoursValueFromTargets, recalculateHoursTargets, recalculateColumnHoursTargets}