import React from 'react'

import DataTable, {addAuditFields} from '../../../shared/dataTable/dataTable'
import discountEntityForm from './discountEntityForm';

const filters = [
]

const columnData = [
    { fieldName: 'discountID', default: true, name: 'ID', description: "The unchanging identifier of the discount"},
    { fieldName: 'name', default: true, name: 'Name', description: "Name of the discount"},
    { fieldName: 'saleTypeName', default: true, name: 'Sale type', description: "Sale type this discount can be used on"},
];



export default function DiscountsList(props) {

    return(
        <div>
            <h1>
                Discounts
            </h1>
            <p>
                Enter the different discount details.
            </p>
            <DataTable
                columnData={addAuditFields(columnData)}
                entityForm={discountEntityForm}
                filters={filters} 
                title="Discounts"
                enableCrud={true}
                me={props.me}
            />
        </div>
    )
}