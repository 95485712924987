import React from 'react'
import TextField from '@material-ui/core/TextField'
import dateFormat from 'dateformat'
import DatePicker from "../../../shared/form/datePicker"

export default {
    displayName: "Daily stats",
    idField: "dailyStatID",

    getURL: id => "/dailyStats?dailyStatID=" + id,
    getListURL: "/dailyStats",
    maxWidth: "xs",
    recordName: formVals => dateFormat(formVals.dailyStatDate, "dd/mm/yyyy"),

    blankForm: {
        dailyStatID: 0,
        dailyStatDate: dateFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), "yyyy-mm-dd") + "T00:00:00.000Z",
        footfall: 0,
        staffCount: 0,
        chatCount: 0,
        storeName: "",
    },
    form : function (props) {

        function handleChange(e) {
            var event = {
                target: {
                    id: e.target.id,
                    value: e.target.value
                }
            }
            var newVals = {...props.formVals}
            props.onChange({...newVals, [event.target.id]: event.target.value})
        }

        return (
            <div>
                <DatePicker 
                    id="dailyStatDate"
                    label="Date"
                    onChange={handleChange}
                    value={props.formVals.dailyStatDate}
                    error={props.validationResult.failMessages.hasOwnProperty("dailyStatDate")}
                    helperText={props.validationResult.failMessages["dailyStatDate"]}
                    disabled={props.disabled}
                />
                <TextField
                    margin="dense"
                    id="storeName"
                    label="Store"
                    type="text"
                    value={props.formVals.storeName}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("storeName")}
                    helperText={props.validationResult.failMessages["storeName"]}
                    fullWidth
                    disabled={props.disabled}
                />
                <TextField
                    margin="dense"
                    id="footfall"
                    label="Footfall"
                    type="text"
                    value={props.formVals.footfall}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("footfall")}
                    helperText={props.validationResult.failMessages["footfall"]}
                    fullWidth
                    disabled={props.disabled}
                />
                <TextField
                    margin="dense"
                    id="chatCount"
                    label="Chats"
                    type="text"
                    value={props.formVals.chatCount}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("chatCount")}
                    helperText={props.validationResult.failMessages["chatCount"]}
                    fullWidth
                    disabled={props.disabled}
                />
                <TextField
                    margin="dense"
                    id="staffCount"
                    label="Staff"
                    type="text"
                    value={props.formVals.staffCount}
                    onChange={handleChange}
                    error={props.validationResult.failMessages.hasOwnProperty("staffCount")}
                    helperText={props.validationResult.failMessages["staffCount"]}
                    fullWidth
                    disabled={props.disabled}
                />
            </div>
        )
    },
}

