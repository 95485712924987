import React, {useState} from 'react'
import Alert from '@material-ui/lab/Alert'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

export default function Step2(props) {
    const [showDupeSKU, setShowDupeSKU] = useState(true)

    function sortRows(a, b){
        if(a.row < b.row) { return -1; }
        if(a.row > b.row) { return 1; }
        return 0;
    }

    return(
        <div>
            <h4>
                Validation results
            </h4>
            <p>Here are the results of the validation performed on the Excel file.  Any rows with errors will not be created.  You can check the errors and re-upload the file.  The same <strong>order number</strong> will not be created twice.</p>
            <FormControlLabel
                control={
                    <Switch
                        checked={props.autoRec}
                        onChange={props.onAutoRec}
                        name="autoRecSwitch"
                        color="primary"
                    />
                }
                label="Attempt auto reconciliation after import"
            />
            {
                //for each sheet do a header showing the results of the report
                props.formVals.validationReport.map(s => {
                    return (
                        <div key={s.sheet}>
                            <h4>
                                Sheet '{s.sheet}'
                            </h4>
                            {
                                s.validationResult.length === 0 ?
                                    <Alert severity="success">
                                        There are no validation errors
                                    </Alert>
                                :
                                    (s.validationResult || []).sort((a,b) => sortRows(a,b)).map(e => {
                                        return (
                                            e.error === "Sheet skipped due to incomplete mapping" ?
                                                <Alert severity="warning" style={{marginTop: "4px"}} key={s.sheet + e.row + e.column + e.error}>
                                                    {e.error}
                                                </Alert>
                                            :
                                                <Alert severity="error" style={{marginTop: "4px"}} key={s.sheet + e.row + e.column + e.error}>
                                                    Row {e.row} col {e.column} value "{e.value + ""}" error: "{e.error}"
                                                </Alert>
                                        )
                                    })
                            }
                        </div>
                    )
                })
            }


            
        </div>
    )
}