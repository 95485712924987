import React, {useState, useEffect} from 'react'
import { CircularProgress } from '@material-ui/core'
import dateFormat from 'dateformat'

import authFetch from '../../../lib/authFetch'
import recSheetEntityForm from './recSheetEntityForm'
import SalesTable from '../sales/salesTable'

export default function SaleAdjustments(props) {
    const [recSheet, setRecSheet] = useState(recSheetEntityForm.blankForm)
    const [loading, setLoading] = useState(false)

    //get the date card for use in the header etc
    useEffect(() => {
        setLoading(true)
        authFetch("/recSheets?recSheetID=" + props.match.params.recSheetID)
        .then(r => {
            setLoading(false)
            setRecSheet(r.listData[0])
        })
    }, [props.match.params.recSheetID])

    const filters = {
        saleDateGEQ: dateFormat(recSheet.recDate, 'yyyy-mm-01'),
        saleDateLEQ: dateFormat(new Date(new Date(recSheet.recDate).getFullYear(), new Date(recSheet.recDate).getMonth() + 1, 0), 'yyyy-mm-dd'),
        currentHistoryStep: "reconciled",
        adjustmentNot: 0
    }

    return (
        <div>
            {
                loading ?
                    <CircularProgress size={150} />
                :
                    <>
                        <h1>
                            Sale Adjustments
                        </h1>
                        <p>
                            All these sales are reconciled, but an adjustment was necessary to match what was on the revenue sheet.
                        </p>
                        <ul>
                            <li>
                                <strong>Revenue</strong> - the revenue as originally recorded on the sale
                            </li>
                            <li>
                                <strong>Adjustment</strong> - the adjustment made to the revenue to make it match what is on the revenue sheet
                            </li>
                            <li>
                                <strong>Adjusted revenue</strong> - the revenue now that the adjustment is made (same as the revenue on the revenue sheet)
                            </li>
                        </ul>
                        <SalesTable
                            columnSet={["adjustment"]}
                            filters={filters} //these are the default filters, taken from the URL 
                            title="Adjusted"
                            enableCrud={true} //enabling editing of sales, if the accessProfile permits it
                            sort="adjustment"
                            sortDir="asc"
                            showTopButtons={true}
                            me={props.me}
                        />
                    </>
            }
        </div>
    )
}