import React, {useEffect, useState} from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import dateFormat from 'dateformat'

import rateCardLineEntityForm from '../../data/rateCards/rateCardLineEntityForm'
import rateCardEntityForm from '../../data/rateCards/rateCardEntityForm'
import ProgressForm from '../../../shared/form/steps/progressForm'
import authFetch from '../../../../lib/authFetch'
import RateCardDetails from './rateCardDetails'
import AnalysisFields from './analysisFields'
import NoTranslateModal from './noTranslateModal'
import RefundAndCancel from './refundAndCancel'
import SaleDetailFields from './saleDetailFields'
import RefundAlert from './RefundAlert'
import ReconciledAlert from './reconciledAlert'
import {blankDiscountStore} from '../../data/discounts/discountStoreSelector'
import RevenueCommissionErrorModal from './revenueCommissionErrorModal'
import DiscountAdjustmentAlert from './discountAdjustmentAlert'
import RateCardChangeAlert from './rateCardChangedAlert'

const blankRateCardLineStore = {
    rateCardLineStoreID: 0,
    storeID: 0,
    rateCardLineID: 0,
    rateCardLine: {
        ...rateCardLineEntityForm.blankForm,
        rateCard: {
            ...rateCardEntityForm.blankForm
        }
    }
}

const noDiscountOption = {
    "discountStoreID": 0,
    "createdDate": "2021-06-09T17:00:58.493Z",
    "createdByUserID": 1,
    "updatedDate": "2021-06-21T14:57:43.977Z",
    "updatedByUserID": 1,
    "deleted": false,
    "deletedByUserID": 0,
    "deletedDate": null,
    "storeID": 0,
    "discountID": 0,
    "captureVariablePercent": false,
    "revenueReductionFixed": 0,
    "revenueReductionIsFixed": true,
    "revenueReductionRatio": 0,
    "commissionReductionFixed": 0,
    "commissionReductionIsFixed": true,
    "commissionReductionRatio": 0,
    "enabled": true,
    "commissionSameAsRevenue": true,
    "discountName": "No discount",
    "discountSaleTypeID": 0,
    "createdByUserName": "Mark Timms",
    "deletedByUserName": null,
    "updatedByUserName": "Mark Timms"
}

export default {
    displayName: "Transaction",
    idField: "saleID",

    //getURL: id => "/sale/" + id,
    getURL: id => "/sales?fkJoins=" + encodeURIComponent(["saleAnalysisValues", "rateCardLineStore", "rateCardLineStore.rateCardLine", "rateCardLineStore.rateCardLine.rateCard", "recSheetLines", "discountStore"].join(",")) + "&saleID=" + id,
    putURL: id => "/sale/" + id,
    //deleteURL: id => "/sale/" + id,
    postURL: "/sale",
    getListURL: "/sales",
    maxWidth: "xl",
    recordName: formVals => formVals.saleID + (formVals.isRefund ? " (REFUND)" : " (SALE)"),
    overrideOnCopy: {
        //orderRef: "", no becasue main use for copy is where you have made multiple sales same order number
        currentHistoryID: 0,
        currentHistoryStep: "recording",
        isRefund: false,
        isRefunded: false,
    },

    blankForm: {
        saleID: 0,
        attributableToUserID: 0,
        sku: "",
        saleDate: dateFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), "yyyy-mm-dd") + "T00:00:00.000Z",
        orderRef: "",
        rateCardID: 0,
        rateCardLineID: 0,
        rateCardLineStoreID: 0,
        storeID: 0,
        currentHistoryID: 0,
        currentHistoryStep: "recording",
        revenue: 0,
        refSaleID: 0,
        isRefund: false,
        isRefunded: false,
        refundReasonID: 0,
        notes: "",
        saleAnalysisValues: [],
        rateCardLineStore: blankRateCardLineStore,
        saleTypeID: 0,
        discountPercent: 0,
        discountStoreID: "unselected",
        discountStore: {
            ...blankDiscountStore,
            discountName: ""
        },
    },
    form : function (props) {
        const [currentRateCardID, setCurrentCardID] = useState(0)
        const [noTranslateOpen, setNoTranslateOpen] = useState(false)
        const [revenueCommissionErrorOpen, setRevenueCommissionErrorOpen] = useState(false)
        //const [saleType, setSaleType] = useState(saleTypeEntityForm.blankForm)
        //const [loadingSaleType, setLoadingSaleType] = useState(false)
        //const [currentHistory, setCurrentHistory] = useState(historyEntityForm.blankForm)
        const [loadingNewRCLS, setLoadingNewRCLS] = useState(false)
        const [discountOptions, setDiscountOptions] = useState([])

        //console.log("Sale date is " + props.formVals.saleDate)

        //ALL EFFECTS AFFECTING FORMVALS ON FIRST LOAD GO HERE
        //when the saleID changes, if it's a new sale then populate the storeID with their default storeID
        useEffect(() => {
            //console.log("Checking saleID " + props.formVals.saleID + " and if it's 0 then setting storeID to " + props.me.storeID)
            var newVals = {...props.formVals}
            if(props.formVals.saleID === 0) {
            //if thiese are blank, ie this is not a copy, set some defaults
                if(props.formVals.storeID === 0) {newVals.storeID = props.me.storeID}
                if(props.formVals.attributableToUserID === 0) {newVals.attributableToUserID = props.me.userID}
            } else {
                //this is an existing sale, better get the available discount options
                authFetch("/discountStores?sort=name&sortDir=asc&enabled=1&storeID=" + props.formVals.storeID + "&discountSaleTypeID=" + props.formVals.saleTypeID)
                .then(r => {
                    //build the list of discountOptions
                    var newDiscountOptions = [
                        ...r.listData,
                        noDiscountOption
                    ]
                    //set it as the list of discount options in the UI
                    setDiscountOptions(newDiscountOptions)
                })
            }
            props.onChange(newVals)
        }, [props.formVals.saleID]) 


        //on first load get the current rate card.  It will be used if the rateCardID of formVals is 0
        useEffect(() => {
            authFetch("/rateCard/current")
            .then(r => {
                setCurrentCardID(r.data.rateCardID)
            })
        }, [])

        //get the sale type details
        // useEffect(() => {
        //     setLoadingSaleType(true)
            
        // }, [props.formVals.saleTypeID])

        //loads the new RCLS.  can be triggered by a store change, saleType change (and therefore an RCLS translate) or a SKU ddl change
        function loadNewRCLS(newVals, rcls, discountPercent, discountStoreID) {
            newVals.rateCardLineStoreID = rcls.rateCardLineStoreID
            newVals.rateCardLineStore = rcls
            //set the formvals values
            newVals.sku = rcls.sku
            newVals.rateCardID = rcls.rateCardLine.rateCardID
            newVals.rateCardLineID = rcls.rateCardLineID
            //if discount is excluded for this sku, untick it
            if(rcls.excludeDiscount) {
                newVals.discountStoreID = 0
                newVals.discountStore = blankDiscountStore
                newVals.discountPercent = 0
            }
            //finally recalculate the revenue and comission
            return calculateRevenueCommission(newVals, rcls.rateCardLineStoreID, discountPercent, discountStoreID)
            .then(na => {
                setLoadingNewRCLS(false)
            })
        }

        function handleChange(e) {
            var event = {
                target: {
                    id: e.target.id,
                    value: e.target.value
                }
            }
            var newVals = {...props.formVals}
            var proms = []
            //merge it with the existing form vals and call the parent handler function
            //if it's a change to the RCLS then load up all the objects in formVals that relate to this
            if(event.target.id === "rateCardLineStoreID") {
                setLoadingNewRCLS(true)
                proms.push(
                    authFetch("/rateCardLineStores?fkJoins=" + encodeURIComponent(["rateCardLine", "rateCardLine.rateCard"].join(",")) + "&rateCardLineStoreID=" + event.target.value)
                    .then(r => loadNewRCLS(newVals, (r.listData.length === 1 ? r.listData[0] : blankRateCardLineStore), props.formVals.discountPercent, props.formVals.discountStoreID))
                )
            }
            //if it's a change to the store, we must try to translate the sku to the new rate card line (thus getting a new RCLS)
            if(event.target.id === "storeID") {
                //they are changing the storeID, we must translate the RCLS
                //unless of course, the RCLS is blank, in which case it remains blank
                if(props.formVals.rateCardLineStoreID + "" !== "0") {
                    //ok we are going to translate the rcls to the new store
                    setLoadingNewRCLS(true)
                    //but first translate the discountStore, as loading a new rcls depends on this being set
                    proms.push(
                        checkDiscount(newVals, event.target.value)
                        .then(na => authFetch("/rateCardLineStores?fkJoins=" + encodeURIComponent(["rateCardLine", "rateCardLine.rateCard"].join(",")) + "&active=1&rateCardID=" + (props.formVals.rateCardID === 0 ? currentRateCardID : props.formVals.rateCardID) + "&sku=" + encodeURIComponent(props.formVals.sku) + "&storeID=" + event.target.value + "&saleTypeID=" + props.formVals.saleTypeID))
                        .then(newRCLS => {
                            //were we able to translate the RCLS to the new store?
                            //most likely yes
                            if(newRCLS.listData.length > 0) {
                                return loadNewRCLS(newVals, newRCLS.listData[0], newVals.discountPercent, newVals.discountStoreID) 
                            } else {
                                //they changed the store but this sku does not exist on the new store
                                setNoTranslateOpen(true)
                                return loadNewRCLS(newVals, blankRateCardLineStore, props.formVals.discountPercent, newVals.discountStoreID) 
                            }
                        })
                    )
                } else {
                    //they have changed the storeID but rcls is blank
                    //so not translating the rcls, only translating the discount
                    proms.push(
                        checkDiscount(newVals, event.target.value)
                        .then(na => calculateRevenueCommission(newVals, props.formVals.rateCardLineStoreID, newVals.discountPercent, newVals.discountStoreID))
                    )
                }
            }
            //if it's a change to the sale type, we must get the new details, and the new rcls, and recalculate the revenue
            if(event.target.id === "saleTypeID") {
                //they are changing the saleTypeID, we must translate the RCLS
                //unless of course, the RCLS is blank, in which case it remains blank
                if(props.formVals.rateCardLineStoreID + "" !== "0") {
                    setLoadingNewRCLS(true)
                    //reset the disoucnt, since the selected discount cannot apply to the new sale type
                    newVals.discountStoreID = 0
                    newVals.discountStore = blankDiscountStore            
                    //get the new rcls
                    proms.push(
                        authFetch("/rateCardLineStores?fkJoins=" + encodeURIComponent(["rateCardLine", "rateCardLine.rateCard"].join(",")) + "&active=1&rateCardID=" + (props.formVals.rateCardID === 0 ? currentRateCardID : props.formVals.rateCardID) + "&sku=" + encodeURIComponent(props.formVals.sku) + "&storeID=" + props.formVals.storeID + "&saleTypeID=" + event.target.value)
                        .then(newRCLS => {
                            //were we able to translate the RCLS to the new store?
                            //most likely yes
                            if(newRCLS.listData.length > 0) {
                                return loadNewRCLS(newVals, newRCLS.listData[0], props.formVals.discountPercent, 0)
                            } else {
                                //they changed the store but this sku does not exist on the new store
                                setNoTranslateOpen(true)
                                return loadNewRCLS(newVals, blankRateCardLineStore, props.formVals.discountPercent, 0)
                            }
                        })
                    )
                } 
            }
            //if changing storeID or saleTypeID then need to recalculate available discount options
            if(event.target.id === "storeID" || event.target.id === "saleTypeID") {
                //get the discount options that are available
                //are the dependency fields set to valid values?
                if(
                        (event.target.id === "storeID" ? event.target.value : props.formVals.storeID) + "" !== "0" && 
                        (event.target.id === "saleTypeID" ? event.target.value : props.formVals.saleTypeID) + "" !== "0"
                    ) {
                    //valid values, do a server call
                    proms.push(
                        authFetch("/discountStores?sort=name&sortDir=asc&enabled=1&storeID=" + (event.target.id === "storeID" ? event.target.value : props.formVals.storeID) + "&discountSaleTypeID=" + (event.target.id === "saleTypeID" ? event.target.value : props.formVals.saleTypeID))
                        .then(r => {
                            //build the list of discountOptions
                            var newDiscountOptions = [
                                ...r.listData,
                                noDiscountOption
                            ]
                            //set it as the list of discount options in the UI
                            //console.log("Setting new options " + JSON.stringify(newDiscountOptions))
                            setDiscountOptions(newDiscountOptions)
                            //auto-select the no-discount option if there are no valid discounts
                            if(newDiscountOptions.length === 1) {
                                //only option is no discount, save the user a click by selecting it
                                //console.log("ONly one option, setting it")
                                newVals.discountStoreID = 0
                                newVals.discountStore = blankDiscountStore 
                            }
                        })
                    )
                } else {
                    //values are not valid, no results would be returned
                    //build the list of discountOptions
                    var newDiscountOptions = [
                        noDiscountOption
                    ]
                    //choose this discount value
                    newVals.discountStoreID = 0
                    newVals.discountStore = blankDiscountStore 
                    //set it as the list of discount options in the UI
                    setDiscountOptions(newDiscountOptions)
                }
            }
            //if changing the applied discount, get the details of the new applied discount
            if(event.target.id === "discountStoreID") {
                //do we need to go to server to get the new disocuntStore?
                if(event.target.value + "" === "0") {
                    //nope, blank
                    newVals.discountStore = blankDiscountStore
                } else {
                    //yes, go to the server for the new value
                    proms.push(
                        authFetch("/discountStores?discountStoreID=" + event.target.value)
                        .then(r => {
                            if(r.listData.length === 1) {
                                newVals.discountStore = r.listData[0]
                                //need to reset applied percent?
                                if(!r.listData[0].captureVariablePercent) {
                                    //no longer capturing percent
                                    newVals.discountPercent = 0
                                }
                            } else {
                                newVals.discountStore = blankDiscountStore
                            }
                        })
                    )
                }
            }
            //if changing whether discount applies, or the type of discount, recalculate the revenue and commission
            if(event.target.id === "discountStoreID") {
                proms.push(
                    calculateRevenueCommission(newVals, props.formVals.rateCardLineStoreID, props.formVals.discountPercent, event.target.value)
                )
            }
            //if changing disciount percent, recalculate the revenue and commission
            if(event.target.id === "discountPercent") {
                proms.push(
                    calculateRevenueCommission(newVals, props.formVals.rateCardLineStoreID, event.target.value, props.formVals.discountStoreID)
                )
            }
            //console.log("Changed " + event.target.id + " to " + event.target.value)
            Promise.all(proms)
            .then(na => {
                props.onChange({...newVals, [event.target.id]: event.target.value})
            })
        }

        //this function checks for new discountstore.  aiming to keep it populated if discount is applicable at new store
        var checkDiscount = function(newVals, newStoreID) { //mutates newvals
            //only worth looking if there is a discount selected
            if(newVals.discountStoreID + "" !== "0" && newVals.discountStoreID + "" !== "unselected" && newVals.discountStore !== undefined) { 
                //look for same discountID but on the new storeID
                return authFetch("/discountStores?storeID=" + newStoreID + "&discountID=" + newVals.discountStore.discountID)
                .then(r => {
                    if(r.listData.length === 1) {
                        //great we can use this discount still
                        newVals.discountStoreID = r.listData[0].discountStoreID
                        newVals.discountStore = r.listData[0]
                    } else {
                        //can't use this discount, not applicable on the new store
                        newVals.discountStoreID = 0
                        newVals.discountStore = blankDiscountStore
                    }
                })        
            } else {
                //return nothing.  leaving discount store as 0
                return Promise.resolve("ok")
            }
        }

        function calculateRevenueCommission(formVals, rateCardLineStoreID, discountPercent, discountStoreID) {
            //make a call to the web service to calculate the new rev and commission values, applying them to the formVals object before resolving
            //only do this if the necessary stuff is filled in
            if(rateCardLineStoreID === 0) {
                //we dont have the necessary info
                formVals.revenue = 0
                formVals.commission = 0
                return Promise.resolve("ok")
            } else {
                return authFetch("/sale/revenueCommission?discountStoreID=" + discountStoreID + "&rateCardLineStoreID=" + rateCardLineStoreID + "&discountPercent=" + discountPercent + "&isCancelled=" + (props.formVals.currentHistoryStep === "cancelled") + "&isRefund=" + props.formVals.isRefund)
                .then(r => {
                    if(r.data.revenue !== undefined && r.data.commission !== undefined) {
                        formVals.revenue = r.data.revenue
                        formVals.commission = r.data.commission
                    } else {
                        //best to bring up an error message here
                        setRevenueCommissionErrorOpen(true)
                    }
                })
            }
        }

        //calculator only mode only returns the detailfields.  its used in the calculator that allows quick calcs
        if(props.calculatorOnly) {
            //calculator mode, only detail fields
            return (
                <div>
                    <SaleDetailFields 
                        me={props.me}
                        formVals={props.formVals}
                        validationResult={props.validationResult}
                        disabled={props.disabled}
                        handleChange={handleChange}
                        calculatorOnly={props.calculatorOnly}
                        onChange={props.onChange}
                        loadNewRCLS={loadNewRCLS}
                        onLoadingNewRCLS={() => setLoadingNewRCLS(true)}
                        blankRateCardLineStore={blankRateCardLineStore}
                        currentRateCardID={currentRateCardID}
                        isHighestRevenue={props.isHighestRevenue}
                        discountOptions={discountOptions}
                    />
                </div>
            )
        } else {
            //standard mode, showing the full sale form
            return (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={2}>
                            
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <ProgressForm 
                                //entityName="sales"
                                entityDisplayName="Transaction"
                                //if there is a sale id, include it in the URL and the server will take it into account to add in any discretional steps like cancelled
                                getStepsURL={props.formVals.saleID === 0 ? "/sale/steps" : "/sale/steps/" + props.formVals.saleID}
                                documentType="sales"
                                currentHistoryStep={props.formVals.currentHistoryStep} 
                                documentID={props.formVals.saleID} 
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            
                        </Grid>
                    </Grid>
                    <RefundAlert
                        formVals={props.formVals}
                        me={props.me}
                    />
                    <ReconciledAlert
                        formVals={props.formVals}
                        me={props.me}
                        onDataChanged={props.onDataChanged}
                    />
                    <SaleDetailFields 
                        me={props.me}
                        formVals={props.formVals}
                        validationResult={props.validationResult}
                        disabled={props.disabled}
                        handleChange={handleChange}
                        calculatorOnly={props.calculatorOnly}
                        onChange={props.onChange}
                        loadNewRCLS={loadNewRCLS}
                        onLoadingNewRCLS={() => setLoadingNewRCLS(true)}
                        blankRateCardLineStore={blankRateCardLineStore}
                        currentRateCardID={currentRateCardID}
                        discountOptions={discountOptions}
                    />
                    <RateCardDetails
                        me={props.me}
                        formVals={props.formVals}
                    />
                    <Grid container spacing={2}>
                        <DiscountAdjustmentAlert
                            formVals={props.formVals}
                            type="revenue"
                            showField="showRevenue"
                            me={props.me}
                            disabled={props.disabled}
                        />
                        <DiscountAdjustmentAlert
                            formVals={props.formVals}
                            type="commission"
                            showField="showCommission"
                            me={props.me}
                            disabled={props.disabled}
                        />
                        <RateCardChangeAlert
                            formVals={props.formVals}
                            type="revenue"
                            showField="showRevenue"
                            me={props.me}
                            disabled={props.disabled}
                        />
                        <RateCardChangeAlert
                            formVals={props.formVals}
                            type="commission"
                            showField="showCommission"
                            me={props.me}
                            disabled={props.disabled}
                        />
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                id="notes"
                                label="Notes"
                                type="text"
                                value={props.formVals.notes}
                                onChange={handleChange}
                                error={props.validationResult.failMessages.hasOwnProperty("notes")}
                                helperText={props.validationResult.failMessages["notes"]}
                                fullWidth
                                disabled={props.disabled}
                            />
                        </Grid>
                    </Grid>
                    <h4>
                        Analysis fields
                    </h4>
                    <AnalysisFields 
                        onChange={handleChange}
                        rateCardLineStore={props.formVals.rateCardLineStore} //when it changes autoFill uses this to fill in values
                        storeID={props.formVals.storeID}
                        saleID={props.formVals.saleID} //to decide if default values are to be input 
                        id="saleAnalysisValues"
                        value={props.formVals.saleAnalysisValues} 
                        validationResult={props.validationResult}
                        disabled={props.disabled}
                        me={props.me}
                        //formVals={props.formVals} //for checking metric part powered autofill analysis fields, which can be based on any field
                    />
                    <RefundAndCancel 
                        me={props.me}
                        formVals={props.formVals}
                        disabled={props.disabled}
                        validationResult={props.validationResult}
                        onChange={props.onChange}
                    />
                    <NoTranslateModal 
                        open={noTranslateOpen}
                        onClose={() => setNoTranslateOpen(false)} 
                    />
                    <RevenueCommissionErrorModal 
                        open={revenueCommissionErrorOpen}
                        onClose={() => setRevenueCommissionErrorOpen(false)} 
                    />
                </div>
            )
        }
    },
}

