import React, {useState, useEffect} from 'react'
import dateFormat from 'dateformat'


import StoreTabs from '../../../shared/storeTabs'
import storeEntityForm from '../../data/stores/storeEntityForm'
import RotaDateSelector from '../dateSelector/rotaDateSelector'
import DaysList from '../daysList'

export default function PlannerPage(props) {
    const [rotaLines, setRotaLines] = useState([])
    const [store, setStore] = useState(storeEntityForm.blankForm)
    //by default the dates are just today.  This prevents unnecessary data being loaded from the server.  Teh rota Date selector will furnish some appropriate dates with an effect
    const [rotaDateGEQ, setRotaDateGEQ] = useState(new Date(dateFormat(new Date(), "yyyy-mm-dd")))
    const [rotaDateLEQ, setRotaDateLEQ] = useState(new Date(dateFormat(new Date(), "yyyy-mm-dd")))

    //use an effect to load the default store when me changes
    useEffect(() => {
        if(props.me.accessProfile !== undefined) {
            var matches = props.me.accessProfile.accessProfileStores.filter(s => s.storeID === props.me.storeID)
            if(matches.length === 1) {
                setStore(matches[0].store)
            }
        }
    }, [JSON.stringify(props.me.accessProfile)])

    //build the params
    function getParams() {
        return ({
            rotaDateGEQ: dateFormat(rotaDateGEQ, "yyyy-mm-dd"),
            rotaDateLEQ: dateFormat(rotaDateLEQ, "yyyy-mm-dd"),
            storeID: store.storeID
        })
    }

    //get the data if any params change
    useEffect(getData, [JSON.stringify(getParams())])

    //if they dont have permission to plan, or there is no store selected, return nothing
    if(!(props.me.accessProfile.systemAreas.split(", ").includes("RecordRotaLines")) || store.storeID === 0) {
        return null
    }

    function handleDataChanged() {
        //get new data from the server
        getData()
    }

    function getData() {
        //check the params are valid for a request for data, and if they are then make the request and get the data
        var params = getParams()
        //only request data if the from date and to date are different, and store ID is not 0
        if((params.rotaDateGEQ === params.rotaDateLEQ) || params.storeID === 0) {
            //can't request this data
        } else {
            //request data from server

        }
    }

    return (
        <>
            <StoreTabs
                me={props.me}
                onChange={ns => setStore(ns)}
                store={store}
            />
            <RotaDateSelector
                me={props.me}
                rotaDateGEQ={rotaDateGEQ}
                rotaDateLEQ={rotaDateLEQ}
                onDatesChange={(newGEQ, newLEQ) => {
                    setRotaDateGEQ(newGEQ)
                    setRotaDateLEQ(newLEQ)

                }}
            />
            <DaysList
                me={props.me}
                rotaLines={rotaLines}
                params={getParams()}
                onDataChanged={handleDataChanged}
            />
        </>           
    )
}