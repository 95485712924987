import React from 'react'
import Grid from '@material-ui/core/Grid'
import dateFormat from 'dateformat'
import ResetIcon from '@material-ui/icons/Restore'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Button } from '@material-ui/core'
import HiddenCardsSelect from './hiddenCardSelect'
import {accessibleUsers} from '../../../../lib/general'
import { withRouter } from 'react-router-dom'
import MultiSelect from '../../../shared/form/multiSelect'
import authFetch from '../../../../lib/authFetch'

export default withRouter(function KpiParams(props) {
    const resetSameLine = useMediaQuery("(min-width:600px)")

    function handleChange(e) {
        //handles change userID field.  must be an array
        if (Array.isArray(e.target.value)) {
            //calculate the new value
            var newVal
            //can't have none, so if there are none then override with current user
            if(e.target.value.length > 0) {
                //valid options selected
                var newVal = e.target.value.join(",")
            } else {
                //none are selected, just select the current user
                var newVal = props.me.userID + ""
            }
            //save these as the users new favorite
            authFetch("/user/userPreference/defaults/cardUsers", {method: "PUT", body: JSON.stringify({value: newVal})})
            //update the title url
            props.onCardUsersChange(newVal)
            //and redirect to this set of users
            props.history.push("/landing/0/" + props.match.params.date + "/" + newVal)
        }
    }

    function handleReset() {
        props.history.push("/landing/0/" + dateFormat(new Date(), "yyyy-mm-dd") + "/" + props.me.userID)
        props.onChangeHidden([]) //reset the hidden cards
    }

    function handleChangeHidden(e) {
        props.onChangeHidden(e.target.value)
    }

    return (
        <>
            <div style={{display: "flex", flexDirection: "row"}}>
                <div style={{display: "flex", flexGrow: 1, flexFlow: "row wrap"}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <MultiSelect
                                id="userID"
                                multiple
                                value={
                                        (props.userID + "")
                                        .split(",")
                                        .filter(x => 
                                            accessibleUsers(props.me)
                                            .map(u => u.userID + "")
                                            .includes(x)
                                        )
                                    }
                                label="Users"
                                onChange={handleChange}
                                optionLabel={o => o.name}
                                optionValue={o => o.userID + ""}
                                options={accessibleUsers(props.me)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <HiddenCardsSelect
                                multiple
                                value={props.hidden}
                                onChange={handleChangeHidden}
                                me={props.me}
                            />
                        </Grid>
                    </Grid>
                </div>
                {
                    resetSameLine ?
                    <div>
                        <Button
                            style={{marginTop: "7px"}}
                            onClick={handleReset}
                            variant="contained"
                            startIcon={<ResetIcon />}
                        >
                            Reset
                        </Button>
                    </div>
                    : null
                }
            </div>
            {
                !resetSameLine ?
                <div>
                    <Button
                        style={{marginTop: "7px"}}
                        onClick={handleReset}
                        variant="contained"
                        startIcon={<ResetIcon />}
                    >
                        Reset
                    </Button>
                </div>
                : null
            }

        </>
    )
})