import React from 'react'

export default function RotaUser(props) {
    

    return(
        <div>
            Rota user
        </div>
    )
}