import React, {useState} from 'react'
import Chip from '@material-ui/core/Chip'
import Link from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

//project
import CrudModal from '../crudModal'

//icons
import AddCircleIcon from '@material-ui/icons/AddCircleOutline'


export default function ChipCell(props) {
    const [modalOpen, setModalOpen] = useState(false)
    const [action, setAction] = useState('create')
    const [openID, setOpenID] = useState(0)

    function handleCrudClose() {
        setModalOpen(false)
    }

    function handleCrudOpen() {
        setOpenID(0)
        setAction("create")
        setModalOpen(true)
    }

    function handleClick(id) {
        setOpenID(id)
        setAction("read")
        setModalOpen(true)
    }

    const entityDisplayName = props.entityForm.displayName
    const parentEntityDisplayName = props.parentEntityForm === undefined ? "" : props.parentEntityForm.displayName

    function sortFn(a, b) {
        var propertyName = (props.fieldName === undefined ? "name" : props.fieldName)
        if (a[propertyName] < b[propertyName]){
            return -1;
        }
        if (a[propertyName] > b[propertyName]){
            return 1;
        }
        return 0;
    }

    return (
        <div>
            {props.chips.sort(sortFn).map(f => {
                return (
                    <Chip key={f[props.entityForm.idField]} variant="outlined" size="small" clickable onClick={() => handleClick(f[props.entityForm.idField])} label={f[(props.fieldName === undefined ? "name" : props.fieldName)]}>
                    </Chip>
                )
            })}
            {props.enableCrud ? 
                <Tooltip title={"Add a " + entityDisplayName + " to this " + parentEntityDisplayName}>
                    <IconButton size="small" style={{padding: "0px"}} onClick={handleCrudOpen} aria-label={"Add a " + entityDisplayName + " to this " + parentEntityDisplayName}>
                        <AddCircleIcon style={{padding: "0px"}} />
                    </IconButton>
                </Tooltip>
            : undefined}
            <CrudModal 
                //overriding the blankform to inject the parent entity value
                entityForm={{...props.entityForm, blankForm: props.enableCrud ? {...props.entityForm.blankForm, [props.parentEntityForm.idField]: props.parentEntityValue} : {...props.entityForm.blankForm}}}
                action={action}
                open={modalOpen}
                onDataChanged={props.onDataChanged}
                onClose={handleCrudClose}
                id={openID}
                me={props.me}
            />
        </div>

    )
}