import React from 'react'
import { Badge, Tooltip } from '@material-ui/core'
import SalesLink from '../../shared/salesLink'

export default function OrderDupeClick(props) {
    //if dupeCount is <=1 then it's not a duplicate order
    if(props.dupeCount <= 1) {
        return props.children
    }

    //this is a duplicate order number.  Let's display a clickable thing to take them to the list of sales with same order number
    return (
        <Tooltip title={"Click to see " + props.dupeCount + " sales with same order ref"}>
            <span>
                <SalesLink 
                    params={{
                        orderRef: props.orderRef
                    }}
                >
                    <Badge 
                        badgeContent={props.dupeCount} 
                        color="primary"
                    >
                        {props.children}
                    </Badge>
                </SalesLink>
            </span>
        </Tooltip>
    )
}