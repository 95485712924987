import React from 'react'
import {Route, Switch} from 'react-router-dom'

import SalesList from './salesList'
import Calculator from './calculator/calculator'

export default function Sales(props) {
    return(
        <Switch>
            <Route path="/sales/calculator" render={renderProps => <Calculator {...renderProps} me={props.me} />} />
            <Route path="/sales/newSale/:defaultFormVals" render={renderProps => <SalesList {...renderProps} me={props.me} newSale={true} />} />
            <Route path="/sales/:storeID/params/:params" render={renderProps => <SalesList {...renderProps} me={props.me} />} />
            <Route path="/sales/params/:params" render={renderProps => <SalesList {...renderProps} me={props.me} />} />
            <Route path="/sales/:storeID" render={renderProps => <SalesList {...renderProps} me={props.me} />} />
            <Route path="/sales" render={renderProps => <SalesList {...renderProps} me={props.me} />} />
        </Switch>
    )
}