import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import HideIcon from '@material-ui/icons/VisibilityOffOutlined'
import { Tooltip } from '@material-ui/core'

export default function CardSetHeader(props) {
    return (
                <h2>
                    {props.title}
                    <Tooltip title="Click to hide">
                        <IconButton 
                            aria-label="hide" 
                            onClick={() => props.onAddHidden(props.setName)}
                            size="small"
                        >
                            <HideIcon />
                        </IconButton>
                    </Tooltip>
                </h2>
    )
}