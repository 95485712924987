import React from 'react'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const steps = [
    'Choose file',
    'Map fields',
    'Confirm import'
]

export default function UploadStepper(props) {
    return(
        <Stepper activeStep={props.step}>
            {steps.map((step) => (
                <Step 
                    key={step} 
                >
                    <StepLabel>
                        {step}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}