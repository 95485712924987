import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function NoTranslateModal(props) {
    return(
        <Dialog open={props.open} maxWidth="xs" fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Not all worksheets are mapped
            </DialogTitle>
            <DialogContent>
                <p>If you continue the unmapped sheets will NOT be imported.  Are you sure you want to continue?</p>
            </DialogContent>
            <DialogActions>
            <Button color="default" onClick={props.onClose} variant="contained">
                    Cancel
                </Button>
                <Button color="primary" onClick={props.onContinue} variant="contained">
                    Continue
                </Button>
            </DialogActions>
      </Dialog>
    )
}