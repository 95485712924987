import React, {useState, useEffect} from 'react'
import { TextField } from '@material-ui/core'

import OptionalTooltip from '../../shared/optionalTooltip'

import { ensureArray } from '../../../lib/general'
import { recalculateColumnHoursTargets } from './hoursCell'

export default function HoursTotalCell(props) {
    const [totalDisplayed, setTotalDisplayed] = useState("")

    //on first load the value displayed is the total
    useEffect(
        () => {
            setTotalDisplayed(ensureArray(props.totalTargets).reduce((acc, cur) => acc + parseFloat(cur.target), 0))
        }
        , [ensureArray(props.totalTargets).reduce((acc, cur) => acc + parseFloat(cur.target), 0)] //change it whenever it changes for any reason in higher level (eg month change, store change)
    )

    var tempTotal = ""

    function handleChange(e) {
        //cache it for half a second or so before recalculating everything
        //otherwise use isnt going to be able to type anything
        var rt = new RegExp("^[0-9]*$")
        if(rt.test(e.target.value)) {
            tempTotal = e.target.value //variable stored globally to capture chagnes from all renders.  State didnt work for this purpose
            setTotalDisplayed(tempTotal)
            setTimeout(function() {
                //is it still the same now?
                if(tempTotal === e.target.value) {
                    //no change in the time so recalculate all targets
                    recalculateTargets(parseFloat(tempTotal))
                }
            }, 750)
        }
    }

    function recalculateTargets(newTotal) {
        //recalculate all targets so that the total is distributed amongst all users based on the hours and weights
        recalculateColumnHoursTargets(props.totalTargets, newTotal)
        //this mutates the objects also present in the allTargets array
        var newTargets = [
            ...props.allTargets
        ]
        //so now these are the new targets
        props.onChange(newTargets)
        //console.log("Setting recalculated store targets: " + props.allTargets.filter(t => t.storeID !== 0 && t.userID !== 0 && t.analysisFieldID === 0).map(t=> t.target).join(","))
    }

    return (
        <OptionalTooltip
            tooltip={props.tooltip}
        >
            <TextField 
                inputProps={{
                    style: {textAlign: "right"}
                }}
                margin="dense"
                label={"Total"}
                type="text"
                value={totalDisplayed}
                onChange={handleChange}
                fullWidth
            />
        </OptionalTooltip>
    )
}
