import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Grid } from '@material-ui/core';
import { ensureArray, formatMoney } from '../../../../lib/general';

import AutoCompleteNative from '../../../shared/autoCompleteNative';
import authFetch from '../../../../lib/authFetch'

export default function SendModal(props) {
    const [metricIDs, setMetricIDs] = useState([])
    const [metrics, setMetrics] = useState([])

    const noFullscreen = useMediaQuery("(min-width:1000px)")

    const storeName = props.store === undefined ? "" : props.store.name

    function getBody(newLine) {
        return (
            storeName + 
            newLine + 
            ensureArray(props.reportRows).map(
                rr => rr.dailyReportAs + " - " + rr.quantity + (props.includeRevenue ? " - £" + formatMoney(rr.revenue, 2) : "")
            ).join(newLine) + 
            (ensureArray(metrics).length === 0 ? "" : newLine) +
            ensureArray(metrics).map(
                m => m.name + " - " + m.result
            ).join(newLine)
        )
    }

    function handleChange(e) {
        //console.log("New metrics " +JSON.stringify(e.target.value))
        //update the metric IDs
        setMetricIDs(e.target.value)
        getMetrics(e.target.value)
        //also save this as the new user preference
        authFetch("/user/userPreference/defaults/drhMetrics", {method: "PUT", body: JSON.stringify({value: e.target.value})})
    }

    function checkDrhMetricsUP() {
        //get any metrics this user sent last time with the daily report
        //console.log("Fetching UP")
        authFetch("/user/userPreference/defaults/drhMetrics")
        .then(r => {
            //console.log("Got UP: " + r)
            if(r.validationResult.fail) {
                //no preference
                return Promise.resolve([])
            } else {
                //there is something, parse it as JSON.  We expect an array of int
                var pot = JSON.parse(r.data)
                if(Array.isArray(pot)) {
                    return Promise.resolve(pot)
                } else {
                    return Promise.resolve([])
                }
            }
        })
        .then(arr => {
            //now we have our initial array of options
            setMetricIDs(arr)
            //and get the new set of metrics
            getMetrics(arr)
        })
        .catch(err => {
            //error parsing preference
            console.log("Error getting UP: " + err)
        })
    }

    function getMetrics(fromMetricIDs) {
        if(Array.isArray(fromMetricIDs)) {
            //call the api to provide us our metrics
            authFetch("/metricToday?storeID=" + props.store.storeID + "&metricID=" + fromMetricIDs.join(",") + "&saleDate=" + props.saleDate)
            .then(r => {
                setMetrics(r.listData)
            })
        }
    }

    //console.log("Metrics: " + metricIDs.join(","))

    return(
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title" onEnter={checkDrhMetricsUP}>
            <DialogTitle id="form-dialog-title">
                Send Report
            </DialogTitle>
            <DialogContent>
                <p>
                    Copy and paste the text below, or use the links below to send your daily report:
                </p>
                <AutoCompleteNative
                    id="metricIDSelector"
                    value={metricIDs}
                    label="Include metrics?"
                    onChange={handleChange}
                    multiple={true}
                    optionLabel={o => o.name}
                    optionValue={o => o.metricID}
                    optionsURL={"/metrics?sort=name"}
                    placeholder={"Choose metrics..."}
                    disabled={props.disabled}
                />
                <div style={{width: "95%", margin: "12px", padding: "12px", border: "1px solid #ccc", borderRadius: "16px"}}>
                    <code>
                        {
                            getBody("<br/>").split("<br/>").map((l, i) => <React.Fragment key={i}>
                                {l}
                                <br/>
                            </React.Fragment>)
                        }
                    </code>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <a href={"mailto:?subject=Daily Report&body=" + getBody("%0D%0A")}>Email...</a>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <a href={"https://wa.me/?text=" + getBody("%0a")} target="_blank">Whatsapp...</a>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
    )
}