import React from 'react'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'

import {formatMoney} from '../../../../lib/general'

export default function DiscountAdjustmentAlert(props) {
    return (
        props.disabled || props.formVals.rateCardLineStoreID + "" === "0" || props.me === undefined || props.me.accessProfile === undefined || !(props.me.accessProfile[props.showField]) || props.formVals.rateCardLineStore[props.type] === 0 || props.formVals.rateCardLineStore[props.type] === props.formVals[props.type] || props.formVals.discountStore === undefined || props.formVals.discountStoreID === 0 ? null :
            <Grid item xs={12}>
                <Alert severity="info" style={{margin: "8px"}}>
                    {props.formVals.discountStore.discountName} discount reduces the {props.type} {props.formVals.rateCardLineStore.rateCardLineStoreID === 0 ? null : formatMoney(props.formVals.rateCardLineStore[props.type])} by {formatMoney(((props.formVals[props.type] - props.formVals.rateCardLineStore[props.type]) / props.formVals.rateCardLineStore[props.type]) * 100, 0)}% so {props.type} is <strong>{formatMoney(props.formVals[props.type])}</strong>
                </Alert>
            </Grid>
    )
}

