import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

export default function DaysSelector(props) {
    const days = [
        {
            label: "Sunday",
            value: 0
        },
        {
            label: "Monday",
            value: 1
        },
        {
            label: "Tuesday",
            value: 2
        },
        {
            label: "Wednesday",
            value: 3
        },
        {
            label: "Thursday",
            value: 4
        },
        {
            label: "Friday",
            value: 5
        },
        {
            label: "Saturday",
            value: 6
        },
    ]

    function handleChange(e) {
        //compile the new value
        //console.log("HandleChange called while props.value is " + props.value)
        var newVal = 0
        var i;
        for(i = 6; i >= 0; i--) {
            //is this the value that's been changed?
            if(parseInt(e.target.id) === i) {
                //console.log("Day " + e.target.id + " was clicked and it's now " + e.target.checked)
                //yes this is the day that's been clicked
                if(e.target.checked) {
                    
                    newVal = newVal + (2**i)
                }
            } else {
                //this is not the value that's been changed
                if(valueContains(parseInt(i))) {
                    //console.log("Day " + i + " was NOT clicked but it was checked so adding it back")
                    //it was checked before
                    newVal = newVal + (2**i)
                } else {
                    //console.log("Day " + i + " was NOT clicked and NOT checked before")
                }
            }
        }
        //console.log("New val is " + newVal)
        var e = {
            target: {
                id: props.id,
                value: newVal
            }
        }
        props.onChange(e)
    }

    function valueContains(thisDay) {
        if(props.value === 0) {
            //console.log("Value is 0 so day is selected")
            return true
        } else {
            //console.log("valueContains kicked off with props.value=" + props.value)
            var culm = parseInt(props.value)
            var i;
            for(i = 6; i >= 0; i--) {
                if(culm >= 2**i) {
                    //console.log("Culm is " + culm + " so day " + thisDay + " is selected (" + (2**i) + ")")
                    //it's included in the value
                    culm = culm - 2**i
                    //if this si the day we were looking for return true
                    if (thisDay === i) {
                        return true
                    }
                } else {
                    //console.log("Culm is " + culm + " so day " + thisDay + " is NOT selected")
                }
            }
        }
        return false
    }

    return (
        <FormControl component="fieldset" style={{marginTop: "10px"}}>
            <FormLabel component="legend">{props.label}</FormLabel>
            <FormGroup row>
                {
                    days.map(d => {
                        return(
                            <FormControlLabel
                                control={<Checkbox id={d.value + ""} checked={valueContains(d.value)} onChange={handleChange} name={d.value + ""} disabled={props.disabled} />}
                                label={d.label}
                                key={d.value}
                                style={{width: "100%"}}
                            />
                        )
                    })
                }   
            </FormGroup>
        </FormControl>
    )
}