import React, {useState} from 'react'
import { Button } from '@material-ui/core'

import {formatMoney} from '../../../../lib/general'
import TableDependentModal from '../../data/metrics/tableDependentModal'

export default function divisionPart(props) {
    const [tableDependentModalOpen, setTableDependentModalOpen] = useState(false)
    const [metricPart, setMetricPart] = useState(null) //passing this through to the modal
    //used to list out the numerators or denominators of the division

    function sortByMultiplier(a, b) {
        if(a.multiplier > b.multiplier) {
            return -1
        }
        if(a.multiplier < b.multiplier) {
            return 1
        }
        return 0
    }

    //console.log("Metric part: " + JSON.stringify(metricPart))

    return (
        <div style={{display: "flex", flexDirection: "row", flexWrap: "nowrap"}}>
            {
                //cycleing through all the parts of the calculation, but starting with positive ones if possible
                props.value.sort(sortByMultiplier).map((dp, i) => {
                    //calculate the joiner string (like +, -, + 2 x, -6 x)
                    var multiplierStr = ""
                    var multiplier = parseFloat(dp.multiplier)
                    //we need to show something if it's not the first one, or it's the first one and it's not just 1
                    if(i !== 0 || (i === 0 && multiplier !== 1)) {
                        //if it's plus one or minus one simply plus or minus will do
                        if(multiplier === 1) {
                            multiplierStr = " + "
                        } else if(dp.multiplier === -1) {
                            multiplierStr = " - "
                        } else {
                            //need both a plus/minus and a multiplocation
                            var sign = multiplier > 0 ? " + " : " - "
                            multiplierStr = sign + Math.abs(multiplier) + " x "
                        }
                    }
                    return (
                        <React.Fragment key={i}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                {multiplierStr}
                            </div>
                            <div>
                                <Button
                                    onClick={() => {
                                        setMetricPart(dp)
                                        setTableDependentModalOpen(true)
                                    }}
                                >
                                    {dp.name + " (" + formatMoney(dp.current[dp.field], 0) + ")"}
                                </Button>
                            </div>
                        </React.Fragment>
                    )
                })
            }
            {
                metricPart === undefined || metricPart === null ? null :
                <TableDependentModal
                    title={metricPart.name}
                    me={props.me}
                    params={metricPart.current.params}
                    open={tableDependentModalOpen}
                    onClose={() => setTableDependentModalOpen(false)}
                    tableName={metricPart.tableName}
                />
            }
        </div>
    )
}