import React, {useState} from 'react'
import {Route} from 'react-router-dom'
import dateFormat from 'dateformat'

import { accessibleStores, byName } from '../../../lib/general'
import LandingMonthHeader from './landingMonthHeader'
import LandingTabs from './landingTabs'
import Cards from "./cards/cards"
import TableSet from './tables/tableSet'
import LeagueTable from './leagueTable'
//icons

export default function LandingContent(props) {
    const [loading, setLoading] = useState(false)
    //this component has been passed a valid me object

    //redirect to home if invalid url
    if(
        props.match.params.userID + "" === "0" || //userID cannot be zero
        !(/^[0-9]+$/.test(props.match.params.tab)) || //tab must be an integer
        !(/^[12][019][0-9][0-9]-[01][0-9]-[0-3][0-9]$/.test(props.match.params.date)) || //date must be in this format yyyy-mm-dd
        ((dateFormat(new Date(), "yyyy-mm") === props.match.params.date.substr(0, 7)) && !(props.match.params.date === dateFormat(new Date(), "yyyy-mm-dd"))) || //if current month, date must be today
        !(/^[0-9]+(,[0-9]+)*$/.test(props.match.params.userID)) //userID must be an CSV of integers
    ) {
        //problem with params
        props.history.push("/landing/0/" + dateFormat(new Date(), "yyyy-mm-dd") + "/" + props.me.userID)
        return (
            <div style={{minHeight: "800px"}}>
                <p>Redirecting...</p>
            </div>
        )
    }

    //calculate the stores and otehr data used throughout
    const stores = accessibleStores(props.me)
    const allStoresTabCount = stores.length <= 1 ? 0 : 3

    return (
        <>
            <Route 
                path={"/landing/:tab/:date/:userID"} 
                render={routeProps => 
                    <>
                        <LandingMonthHeader
                            {...routeProps}
                            me={props.me}
                            loading={loading}
                            //for working out the custom date link
                            allStoresTabCount={allStoresTabCount}
                            stores={stores}
                        />
                        <LandingTabs
                            {...routeProps}
                            me={props.me}
                            stores={stores}
                        />
                    </>
                } 
            />
            <Route 
                path={
                    "/landing/0/:date/:userID" //tab 0 is always the cards
                } 
                render={routeProps => 
                    <Cards
                        {...routeProps}
                        me={props.me}
                        kpiCard={props.kpiCard}
                        onNewKpiCard={props.onNewKpiCard} 
                        onAddHidden={props.onAddHidden}
                        onChangeHidden={props.onChangeHidden}
                        hidden={props.hidden}
                        loading={loading}
                        stores={stores}
                        onLoadingChange={l => setLoading(l)}
                        onCardUsersChange={props.onCardUsersChange}
                    />
                } 
            />
            <Route 
                path={
                    "/landing/1/:date/:userID" //tab 1 is always league table
                } 
                render={routeProps => 
                    <LeagueTable
                        {...routeProps}
                        me={props.me}
                        stores={stores}
                        loading={loading}
                        onLoadingChange={l => setLoading(l)}
                    />
                } 
            />
            {
                //we have more than 1 store?
                allStoresTabCount === 0 ? null :
                <>
                    <Route 
                        path={
                            "/landing/2/:date/:userID" //tab 2 is all, when we have multiple stores
                        } 
                        render={routeProps => {
                            var tsMatches = props.tableSets.filter(t => t.tab === 2)
                            return (
                                <TableSet
                                    date={routeProps.match.params.date}
                                    me={props.me}
                                    loading={loading}
                                    allStores={stores}
                                    storeIDs={stores.map(s => s.storeID)}
                                    splitBy={"attributableToUserID"}
                                    onLoadingChange={l => setLoading(l)}
                                    tableSet={tsMatches.length === 1 ? tsMatches[0].tableSet : null}
                                    onNewTableSet={ts => props.onNewTableSet({tab: 2, tableSet: ts})} 
                                />
                            )
                        }
                            
                        } 
                    />
                    <Route 
                        path={
                            "/landing/3/:date/:userID" //tab 3 is all (stores), when we have multiple stores
                        } 
                        render={routeProps => {
                            var tsMatches = props.tableSets.filter(t => t.tab === 3)
                            return (
                                <TableSet
                                    date={routeProps.match.params.date}
                                    me={props.me}
                                    loading={loading}
                                    allStores={stores}
                                    storeIDs={stores.map(s => s.storeID)}
                                    splitBy={"storeID"}
                                    onLoadingChange={l => setLoading(l)}
                                    tableSet={tsMatches.length === 1 ? tsMatches[0].tableSet : null}
                                    onNewTableSet={ts => props.onNewTableSet({tab: 3, tableSet: ts})} 
                                />
                            )
                        }
                        }
                    />
                    <Route 
                        path={
                            "/landing/4/:date/:userID" //tab 4 is all (types), when we have multiple stores
                        } 
                        render={routeProps => {
                            var tsMatches = props.tableSets.filter(t => t.tab === 4)
                            return (
                                <TableSet
                                    date={routeProps.match.params.date}
                                    me={props.me}
                                    loading={loading}
                                    allStores={stores}
                                    storeIDs={stores.map(s => s.storeID)}
                                    splitBy={"storeStoreTypeID"}
                                    onLoadingChange={l => setLoading(l)}
                                    tableSet={tsMatches.length === 1 ? tsMatches[0].tableSet : null}
                                    onNewTableSet={ts => props.onNewTableSet({tab: 4, tableSet: ts})} 
                                />
                            )
                        }
                        }
                    />
                </>
            }
            {
                //now mapping through the stores, creating the route for each
                stores.map((s, i) => <Route 
                    key={i}
                    path={
                        "/landing/" + (allStoresTabCount + i + 2) + "/:date/:userID"
                    } 
                    render={routeProps => {
                        var tsMatches = props.tableSets.filter(t => t.tab === (allStoresTabCount + i + 2))
                        return (
                            <TableSet
                                date={routeProps.match.params.date}
                                me={props.me}
                                loading={loading}
                                allStores={stores}
                                storeIDs={[s.storeID]}
                                splitBy={"attributableToUserID"}
                                onLoadingChange={l => setLoading(l)}
                                tableSet={tsMatches.length === 1 ? tsMatches[0].tableSet : null}
                                onNewTableSet={ts => props.onNewTableSet({tab: (allStoresTabCount + i + 2), tableSet: ts})} 
                            />
                        )
                    }
                        
                    } 
                />)
            }
        </>
    )
}