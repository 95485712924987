import React, {useState} from 'react'
import DateRangeIcon from '@material-ui/icons/DateRange'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import {Link} from 'react-router-dom'

import DataTable, {addAuditFields} from '../../../shared/dataTable/dataTable'
import BandEntityForm from './bandEntityForm';

const filters = [
]

const columnData = [
    { fieldName: 'bandID', default: true, name: 'ID', description: "The unchanging identifier of the band"},
    { fieldName: 'name', default: true, name: 'Name', description: "Name of the band"},
    { fieldName: 'description', default: true, name: 'Description', description: "Description of the band"},
];


export default function BandsList(props) {

    return(
        <div>
            <h1>
                Bands
            </h1>
            <p>
                Bands are for classifying the SKU's into different bands.
            </p>
            <DataTable
                columnData={addAuditFields(columnData)}
                entityForm={BandEntityForm}
                
                filters={filters} 
                title="Bands List"
                enableCrud={true}
            />
        </div>
    )
}