import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import dateFormat from 'dateformat'
import LookupCell from '../../../shared/dataTable/lookupCell';
import useMediaQuery from '@material-ui/core/useMediaQuery'

//components
import DataTable, {addAuditFields} from '../../../shared/dataTable/dataTable'
import saleTypeEntityForm from '../saleTypes/saleTypeEntityForm'
import RateCardEntityForm from './rateCardEntityForm'
import RateCardLineEntityForm from './rateCardLineEntityForm'
import SKUTypeEntityForm from '../skuTypes/skuTypeEntityForm'
import BandEntityForm from '../bands/bandEntityForm'

//lib
import authFetch from '../../../../lib/authFetch';
import {getToken} from '../../../../lib/auth';
import {formatMoney} from '../../../../lib/general'

const blankRateCard = {
    rateCardID: 0,
    fromDate: new Date(),
    name: ""
}



export default function SKUHistoryModal(props) {
    const [dataRefreshTime, setDataRefreshTime] = useState(new Date())
    const [cols, setCols] = useState([])
    const [description, setDescription] = useState("")

    
    const columnData = [
        { fieldName: 'rateCardFromDate', default: true, name: 'Rate Card', description: "The rate card", renderCell: (dr) => 
            <LookupCell 
                id={dr.rateCardID} 
                entityForm={RateCardEntityForm}
                label={dateFormat(dr.rateCardFromDate, "dd/mm/yyyy")}
                me={props.me}
            />
        },
        { fieldName: 'rateCardLineID', default: false, name: 'ID', description: "The unchanging identifier of the rate card line"},
        { fieldName: 'saleTypeName', default: true, name: 'Sale Type', description: "Sale type", renderCell: (dr) => 
            <LookupCell 
                id={dr.saleTypeID} 
                entityForm={saleTypeEntityForm}
                label={dr.saleTypeName}
                me={props.me}
            />
        },
        { fieldName: 'skuType', default: true, name: 'SKU Type', description: "The SKU type", renderCell: (dr) => 
            <LookupCell 
                id={dr.skuTypeID} 
                entityForm={SKUTypeEntityForm}
                label={dr.skuTypeName}
                me={props.me}
            />
        },
        { fieldName: 'customerRRP', default: true, name: 'RRP', description: "RRP payable by the customer", renderCell: dr => dr.customerRRP + "" === "0" ? "" : formatMoney(dr.customerRRP), alignRight: true},
        { fieldName: 'customerMonthly', default: true, name: 'Monthly', description: "Monthly amount payable by the customer", renderCell: dr => dr.customerMonthly + "" === "0" ? "-" : formatMoney(dr.customerMonthly), alignRight: true},
        { fieldName: 'contractMonths', default: true, name: 'Contract length', description: "Number of months long the contract is", renderCell: dr => dr.contractMonths + "" === "0" ? "-" : dr.contractMonths + "m", alignRight: true},
        { fieldName: 'bandName', default: true, name: 'Band', description: "The band of this item", renderCell: (dr) => 
            <LookupCell 
                id={dr.bandID} 
                entityForm={BandEntityForm}
                label={dr.bandName}
                me={props.me}
            />
        },
        //if you're going to enable this only allow it for staff with systemSetup
        //{ fieldName: 'file', default: false, name: 'Upload file', description: "The upload file from which this line came", renderCell: dr => <a href={(window.location.href + "salt").replace(window.location.pathname + "salt", "/api") + "/file/" + dr.fileID + "/download?token=" + getToken()}>{dr.fileOriginalName}</a>},
    ];


    function handleEnter() {
        //on first load get the stores to build the dynamic columns
        authFetch("/stores")
        .then(s => {
            //start with the fixed cols
            var newCols = [...columnData]
            //add in the ones from the stores
            newCols.push(...s.listData.map(ns => {
                //build a column from this data
                return {
                    fieldName: "storeID" + ns.storeID,
                    default: true,
                    name: ns.name,
                    description: "Revenue at store " + ns.name,
                    renderCell: dr => {
                        var rows = (dr.rateCardLineStores || []).filter(rcls => rcls.storeID === ns.storeID)
                        return <span style={rows.length === 0 ? undefined : rows[0].revenue + "" === "0" ? undefined : rows[0].active === false ? {color: "pink"} : undefined}>
                            {rows.length === 0 ? "-" : rows[0].revenue + "" === "0" ? "-" : formatMoney(rows[0].revenue)}
                        </span>
                    }, 
                    alignRight: true
                }
            }))
            //console.log(JSON.stringify(newCols))
            setCols(addAuditFields(newCols))
            return authFetch("/rateCardLines?pageLength=1&page=0&sort=rateCard&sortDir=desc&sku=" + encodeURIComponent(props.sku))
        })
        .then(r => {
            if(r.listData.length > 0) {
                setDescription(r.listData[0].description)
            }
        })
    }

    const noFullscreen = useMediaQuery("(min-width:1000px)")

    return(
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth="xl" fullWidth={true} aria-labelledby="form-dialog-title" onEnter={handleEnter}>
            <DialogTitle id="form-dialog-title">
                SKU {props.sku} History
            </DialogTitle>
            <DialogContent style={{minHeight: "250px"}}>
                <p>{description}</p>
                <DataTable
                    columnData={cols}
                    dataRefreshTime={dataRefreshTime}
                    filters={{sku: props.sku, fkJoins: "rateCardLineStores"}} 
                    title="SKU History"
                    enableCrud={false}
                    entityForm={RateCardLineEntityForm}
                    sort="rateCardFromDate"
                    sortDir="desc"
                    me={props.me}
                />
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
    )
}