import React, {useState} from 'react'
import dateFormat from 'dateformat'
import {Link} from 'react-router-dom'

import DataTable, {addAuditFields} from '../../shared/dataTable/dataTable'
import recSheetEntityForm from './recSheetEntityForm';
import {formatMoney} from '../../../lib/general'

function calculatePercent(a, b) {
    if(b === 0) {
        return 0
    } else {
        return Math.floor(a * 1000 / b) / 10
    }
}

const columnData = [
    { fieldName: 'rateCardID', default: false, name: 'ID', description: "The unchanging identifier of the rate card"},
    { fieldName: 'recDate', default: true, name: 'Date', description: "Date of the reconciliation sheet", renderCell: dr => dateFormat(new Date(dr.recDate), "dd/mm/yyyy") },
    { fieldName: 'name', default: true, name: 'Name', description: "Name of the reconciliation sheet"},
    { fieldName: 'recSheetLineCount', default: true, name: 'Lines', description: 'A link to the lines of the reconciliation sheet', renderCell: dr => <Link to={'/recSheets/' + dr.recSheetID + '/lines'}>{dr.recSheetLineCount} lines</Link>},
    { fieldName: 'reconciledPercent', default: true, name: 'Reconciled', description: 'What percent of the lines on the rec sheet are reconciled', renderCell: dr => formatMoney(dr.reconciledPercent * 100, 1) + "%"},
    { fieldName: 'autoReconciledPercent', default: true, name: 'Auto Reconciled', description: 'What percent of the lines on the rec sheet were reconciled automatically.  An indication of how accurate sales are being recorded', renderCell: dr => formatMoney(dr.autoReconciledPercent * 100, 1) + "%"}
];



export default function RecSheetsList(props) {

    return(
        <div>
            <h1>
                Revenue Sheets
            </h1>
            <p>
                Here is a list of the revenue reconciliation sheets in the system
            </p>
            <DataTable
                columnData={addAuditFields(columnData)}
                entityForm={recSheetEntityForm}
                filters={{}} 
                title="Revenue Reconciliation Sheets"
                enableCrud={true}
                sort="recDate"
                sortDir="desc"
            />
        </div>
    )
}