//react and material
import React, {useState, useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {Link} from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import purple from '@material-ui/core/colors/purple';
import {withRouter} from 'react-router-dom'
import dateFormat from 'dateformat'
import useMediaQuery from '@material-ui/core/useMediaQuery'

//components
import DataTableToolbar from './dataTableToolbar'
import DataTableHead from './dataTableHead'
import ExcelDownloadButton from './excelDownloadButton';
import CrudModal from '../crudModal'
import CrudCell from './crudCell'
import ColumnDataModal from './columnDataModal'

//lib
import authFetch from '../../../lib/authFetch'

//icons
import ViewColumnIcon from '@material-ui/icons/ViewColumn';

//helper function to add the common audit fields to a set of column data being passed into datatable component
export function addAuditFields(toColumnData) {
    //modifies the passed array, does not return anything
    const af = [
        { fieldName: 'createdByUserName', default: false, name: 'Creator name', description: "The name of the user who created it"},
        { fieldName: 'createdDate', default: false, name: 'Created date', description: "Date it was created", renderCell: dr => dr.createdDate === null ? "-" : dateFormat(dr.createdDate, "dd/mm/yyyy HH:MM:ss", true) },
        { fieldName: 'updatedByUserName', default: false, name: 'Updater name', description: "The name of the user who last updated it"},
        { fieldName: 'updatedDate', default: false, name: 'Updated date', description: "Date it was last updated", renderCell: dr => dr.updatedDate === null ? "-" : dateFormat(dr.updatedDate, "dd/mm/yyyy HH:MM:ss", true) },

    ]
    //now add them
    const both = toColumnData.concat(af)
    return both
}

export function renderCB(val) {
    return(
    <Checkbox checked={val} disabled style={{margin: "0px", padding: "0px"}} />
    )
}

var tempQF = ""

//the main component
export default withRouter(function DataTable(props) {
    const [dataRefreshInProgress, setDataRefreshInProgress] = useState(false)
    const [selected, setSelected] = useState([])
    const [listData, setListData] = useState([])
    const [dataRefreshRequired, setDataRefreshRequired] = useState(new Date())
    const [sort, setSort] = useState(props.sort || "")
    const [sortDir, setSortDir] = useState(props.sortDir || "asc")
    const [page, setPage] = useState(0)
    const [pageLength, setPageLength] = useState(10)
    const [listIDs, setListIDs] = useState([])
    const [quickFilterDisplayed, setQuickFilterDisplayed] = useState('')
    const [quickFilterFilter, setQuickFilterFilter] = useState('')
    const [crudModalOpen, setCrudModalOpen] = useState(props.crudModalOpen ? true : false) //can pass in crudModalOpen if you want it open by default.  Also pass in defaultFormVals to prepopulate the form
    const [crudModalAction, setCrudModalAction] = useState("create")
    const [crudModalID, setCrudModalID] = useState(0)
    const [columnData, setColumnData] = useState(props.columnData.filter(c => c.default))
    const [columnDataOpen, setColumnDataOpen] = useState(false)
    const [excelURL, setExcelURL] = useState("")
    const [haveUsedDefaultFormVals, setHaveUsedDefaultFormVals] = useState(false)

    const handleColumnDataChange = function(newColumnData) {
        console.log("Modal setting column data len " + newColumnData.length)
        setColumnData(newColumnData)
    }

    const handleColumnDataClose = function() {
        setColumnDataOpen(false)
    }

    const handleColumnDataOpen = function() {
        setColumnDataOpen(true)
    }

    function handleDataChanged() {
        //the data has been changed, make a new date to trigger the data refresh
        setDataRefreshRequired(new Date())
    }

    function handleCrudClose() {
        //crud modal calls to request close
        setCrudModalOpen(false)
        setHaveUsedDefaultFormVals(true) //we only prepopulate the form the first time you see
    }

    function handleCrud(id, action) {
        setCrudModalAction(action)
        setCrudModalID(id)
        setCrudModalOpen(true)
    }

    //appends the fileters and quickfilter to the URL
    function buildAPIURL(paging, applyQuickFilter, excel) {
        //console.log("Building " + excel + " query from filters " + JSON.stringify(props.filters))
        var apiURL = props.getListURL || props.entityForm.getListURL
        //if it's excel add it to the end
        if(excel) {
            apiURL = apiURL + "/excel"
        }
        //start an array for the query params
        var queryparams = []
        //data filters
        var urlfilters = Object.keys(props.filters).map(k => encodeURIComponent(k) + "=" + encodeURIComponent(props.filters[k]))
        queryparams.push(...urlfilters)
        //paging
        if(paging) {
            queryparams.push("page=" + page)
            queryparams.push("pageLength=" + pageLength)
        }
        //sorting
        queryparams.push("sort=" + sort)
        queryparams.push("sortDir=" + sortDir)
        //quickfilter
        if(applyQuickFilter) {
            if (quickFilterFilter.length > 0) {
                queryparams.push("quickFilter=" + quickFilterFilter)
            }
        }
        //join all the query params together if there are any
        apiURL = apiURL + "?" + queryparams.join("&")
        return apiURL
    }

    const handleRequestSort = (event, newSort) => {
        let newSortDir = 'desc';

        if (sort === newSort && sortDir === 'desc') {
            newSortDir = 'asc';
        }

        setSort(newSort)
        setSortDir(newSortDir)
        //setDataRefreshRequired(true)
    };

    const handleSelectAllClick = (event, checked) => {
        var quickFilteredIDs = listIDs.filter(n => (n[1] === true)).map(n => n[0])
        if (checked) {
            //add only the ones that are not already selected
            var currentids = selected
            quickFilteredIDs = quickFilteredIDs.filter(addingid => (currentids.indexOf(addingid) === -1))
            quickFilteredIDs.push(...currentids)
            setSelected(quickFilteredIDs)
        } else {
            //remove all the ones in the current ids
            var newids = selected.filter(selectedID => (quickFilteredIDs.indexOf(selectedID) === -1))
            setSelected(newids)
        }
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        //setDataRefreshRequired(true)
    };

    const handleChangeRowsPerPage = event => {
        setPageLength(event.target.value)
        //setDataRefreshRequired(true)
    };

    const isSelected = id => selected.indexOf(id) !== -1;

    //handle a change in the quickfilter applied to the data
    function handleQuickFilterChange(newFilterValue) {
        tempQF = newFilterValue //variable stored globally to capture chagnes from all renders.  State didnt work for this purpose
        setQuickFilterDisplayed(newFilterValue)
        setTimeout(function() {
            //is it still the same now?
            if(tempQF === newFilterValue) {
                //no change in the time so get new data
                setQuickFilterFilter(newFilterValue)
            }
        }, 750)
    }

    //calculate the rowCount which is the number of rows in the quickFiltered data, used for the pagination control
    var rowCount = listIDs.filter(idRow => (idRow[1] === true)).length
    const emptyRows = pageLength - Math.min(pageLength, listData.length);

    //change this to reset the selected id's from the parent
    useEffect(() => {
        setSelected([])
    }, [props.selectRefreshTime])

    //calculate the total amount
    //let totalAmount = listIDs.filter(row => row[1]).reduce((acc, row) => acc + row[1], 0)

    //if data is not present then fetch it
    useEffect(() => {
        //console.log("Data refresh time " + dateFormat(props.dataRefreshTime, "yyyy-mm-dd HH:MM:ss.l"))
        const myAbortController = new AbortController();
        //set the excel url
        setExcelURL(buildAPIURL(false, false, true))
        //now get the data for the table
        setDataRefreshInProgress(true)
        authFetch(buildAPIURL(true, true, false), {signal: myAbortController.signal})
        .then(apiReturn => {
            //console.log("API returned " + apiReturn.listData.length + " rows")
            //set the data
            setListData(apiReturn.listData)
            setListIDs(apiReturn.listIDs)
            setPage(apiReturn.page)
            //setDataRefreshRequired(false)
            setDataRefreshInProgress(false)
        })
        .catch(err => {
            console.log("There was an error fetching data: " + err)
        })
        return () => {
            myAbortController.abort()
        }  
    }, [
        JSON.stringify(props.filters), 
        quickFilterFilter, 
        dataRefreshRequired, 
        sort, 
        sortDir, 
        page, 
        pageLength, 
        dateFormat(props.dataRefreshTime || new Date(2020, 1, 1), "yyyy-mm-dd HH:MM:ss.l")
    ])

    //renderdata is the data array with the 'isSelected' property calculated and added
    var renderData = listData.map(dataRow => {
        var newRow = dataRow
        newRow.isSelected = isSelected(dataRow[(props.idField || props.entityForm.idField)])
        return newRow
    })

    // //do we need to reset the current page?
    // var maxPage = Math.floor(rowCount / pageLength)
    // console.log("Max page is " + maxPage)
    // if(page > maxPage) {
    //     handleChangePage(undefined, maxPage)
    // }
    //console.log("Rendering datatable")

    //whenever column data changes, rebuild the list of displayed columns, taking into account the users preferences
    useEffect(() => {
        //console.log("Rebuilding with " + props.columnData.length + " cols")
        //get the users preference for columns in this table
        authFetch("/user/userPreference/TableColumns/" + encodeURIComponent(props.title))
        .then(d => {
            //if it's blank dont do anything just use the defaults
            //console.log("Got default columns: " + JSON.stringify(d.data))
            if(d.data === undefined || d.data === "" || d.data === null) {
                //set the columns data to defaults
                setColumnData(props.columnData.filter(c => c.default))
            } else {
                //it's a csv of names
                var cols = d.data.split(",")
                //console.log("Setting up " + cols.length + " cols")
                //preserver the order
                setColumnData(
                    cols.filter(c => props.columnData
                    .map(cd => cd.name)
                    .includes(c))
                    .map(vc => props.columnData
                    .filter(vcd => vcd.name === vc)[0])
                )
                //setColumnData(props.columnData.filter(c => cols.includes(c.name)))
            }
        })
    }, [props.columnData])

    const noStackCrud = useMediaQuery("(min-width:700px)")
    const showRead = props.enableRead && columnData.length > 0 && props.entityForm.getURL !== undefined
    const showCopy = props.enableCrud && columnData.length > 0 && props.entityForm.postURL !== undefined 
    const showEdit = props.enableCrud && columnData.length > 0 && props.entityForm.putURL !== undefined 
    const showDelete = props.enableCrud && columnData.length > 0 && props.entityForm.deleteURL !== undefined

    return (
        <Paper >
                    <DataTableToolbar 
                        selected={selected} 
                        dataRefreshRequired={dataRefreshRequired}
                        onQuickFilterChange={handleQuickFilterChange} 
                        quickFilter={quickFilterDisplayed}
                        listIDs={listIDs}
                        selectActions={props.selectActions}
                        title={props.title}
                        enableCrud={props.enableCrud && (props.entityForm.postURL !== undefined)}
                        handleCrud={handleCrud}
                        onDataChanged={handleDataChanged}
                        noSmallFilter={noStackCrud}
                        aggregateFieldIndex={props.aggregateFieldIndex}
                    />


                    <Table aria-labelledby="tableTitle" size="small">
                        <DataTableHead
                            selected={selected}
                            sortDir={sortDir}
                            sort={sort}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            listIDs={listIDs}
                            columnData={columnData}
                            selectActions={props.selectActions}
                            showRead={showRead}
                            showCopy={showCopy}
                            showEdit={showEdit}
                            showDelete={showDelete}
                            noStackCrud={noStackCrud}
                        />
                        <TableBody>
                            {renderData.map(dataRow => {
                                return (
                                    <TableRow
                                        hover
                                        style={props.conditionalRowStyle === undefined ? {} : props.conditionalRowStyle(dataRow)}
                                        tabIndex={-1}
                                        key={props.makeKeyFromDataRow === undefined ? dataRow[(props.idField || props.entityForm.idField)] : props.makeKeyFromDataRow(dataRow)}
                                    >
                                        {props.selectActions !== undefined ?
                                            <TableCell padding="checkbox" style={{padding: "6px 0px 6px 0px"}}>
                                                <Checkbox checked={dataRow.isSelected} onClick={event => handleClick(event, dataRow[(props.idField || props.entityForm.idField)])} />
                                            </TableCell>
                                            : undefined
                                        }
                                        {columnData.map(col => {
                                            return(
                                                <TableCell key={col.fieldName} style={col.alignRight ? {textAlign: "right", padding: "6px 2px 6px 3px"} : {padding: "4px 4px 4px 6px"}}>
                                                    {(col.renderCell === undefined) ? (
                                                        //drill through the object to get to the value specified by fieldName.  This is an atrocity.
                                                        col.fieldName.split(".").reduce((acc, curr, i) => acc = i > 0 ? (acc ? acc : {})[curr] : dataRow[curr],"")
                                                    ) : (
                                                        //pass the data row to the custom cell render method
                                                        col.renderCell(dataRow, handleDataChanged, props.enableCrud)
                                                    )}
                                                </TableCell>
                                            )
                                        })} 
                                        {
                                            noStackCrud ? 
                                                //show crud in individual cells
                                                <>
                                                    {showRead ?
                                                        <TableCell width="20px" style={{padding: "0px"}}>
                                                            <CrudCell rowID={dataRow[(props.idField || props.entityForm.idField)]} action='read' onClick={handleCrud} entityName={props.entityForm.displayName} />
                                                        </TableCell>
                                                        : undefined
                                                    }
                                                    {showCopy && (props.crudRecordCheck === undefined ? true : props.crudRecordCheck(dataRow)) ?
                                                        <TableCell width="20px" style={{padding: "0px"}}>
                                                            <CrudCell rowID={dataRow[(props.idField || props.entityForm.idField)]} action='copy' onClick={handleCrud} entityName={props.entityForm.displayName} />
                                                        </TableCell>
                                                        : undefined
                                                    }
                                                    {showEdit && (props.crudRecordCheck === undefined ? true : props.crudRecordCheck(dataRow)) ?
                                                        <TableCell width="20px" style={{padding: "0px"}}>
                                                            <CrudCell rowID={dataRow[(props.idField || props.entityForm.idField)]} action='update' onClick={handleCrud} entityName={props.entityForm.displayName} />
                                                        </TableCell>
                                                        : undefined
                                                    }
                                                    {showDelete && (props.crudRecordCheck === undefined ? true : props.crudRecordCheck(dataRow)) ?
                                                        <TableCell width="20px" style={{padding: "0px"}}>
                                                            <CrudCell rowID={dataRow[(props.idField || props.entityForm.idField)]} action='delete' onClick={handleCrud} entityName={props.entityForm.displayName} />
                                                        </TableCell>
                                                        : undefined
                                                    }

                                                </>
                                            :
                                                //show crud all in one cell
                                                <TableCell width="20px" style={{padding: "0px"}}>
                                                    {showRead ?
                                                            <CrudCell rowID={dataRow[(props.idField || props.entityForm.idField)]} action='read' onClick={handleCrud} entityName={props.entityForm.displayName} />
                                                        : undefined
                                                    }
                                                    {showCopy && (props.crudRecordCheck === undefined ? true : props.crudRecordCheck(dataRow)) ?
                                                            <CrudCell rowID={dataRow[(props.idField || props.entityForm.idField)]} action='copy' onClick={handleCrud} entityName={props.entityForm.displayName} />
                                                        : undefined
                                                    }
                                                    {showEdit && (props.crudRecordCheck === undefined ? true : props.crudRecordCheck(dataRow)) ?
                                                            <CrudCell rowID={dataRow[(props.idField || props.entityForm.idField)]} action='update' onClick={handleCrud} entityName={props.entityForm.displayName} />
                                                        : undefined
                                                    }
                                                    {showDelete && (props.crudRecordCheck === undefined ? true : props.crudRecordCheck(dataRow)) ?
                                                            <CrudCell rowID={dataRow[(props.idField || props.entityForm.idField)]} action='delete' onClick={handleCrud} entityName={props.entityForm.displayName} />
                                                        : undefined
                                                    }
                                                </TableCell>
                                        }
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                    <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
                        <div style={{display: "flex"}}>
                                <div style={{alignItems: "center", display: "flex", width: "100%"}}>
                                    <Tooltip title="Select columns">
                                        <IconButton aria-label="Select columns" onClick={handleColumnDataOpen}>
                                            <ViewColumnIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <ExcelDownloadButton 
                                        url={excelURL}
                                        fileName={props.title}
                                        me={props.me}
                                    />
                                </div>
                            {(dataRefreshInProgress) ? 
                                <LinearProgress style={{ marginTop:"18px", marginLeft: "10px", color: purple[500], width: (noStackCrud ? "200px" : "25px") }} />
                                : undefined }
                        </div>
                        <div style={{display: "flex"}}>
                            <TablePagination
                                component="div"
                                count={rowCount}
                                rowsPerPage={pageLength}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[10,25,50,100,500]}
                                labelRowsPerPage={noStackCrud ? "Rows per page" : "Rows"}
                                labelDisplayedRows={({from, to, count}) => {
                                    return from + "-" + to + " of " + count
                                }}
                            />
                        </div>
                    </div>

            {
                props.enableCrud || props.enableRead ?
                <CrudModal 
                    entityForm={props.entityForm}
                    action={crudModalAction}
                    open={crudModalOpen}
                    onClose={handleCrudClose}
                    id={crudModalID}
                    overrideOnCopy={props.overrideOnCopy}
                    me={props.me}
                    onDataChanged={handleDataChanged}
                    defaultFormVals={haveUsedDefaultFormVals ? undefined : props.defaultFormVals}
                />
                : null
            }
            <ColumnDataModal tableName={props.title} open={columnDataOpen} onClose={handleColumnDataClose} onChange={handleColumnDataChange} value={columnData} columnData={props.columnData} />
        </Paper>
    );
})

export function renderID(idField, fnPathFromID) {
    return function(dataRow) {
        return(
            <Link to={fnPathFromID(dataRow[idField])} >
                {dataRow[idField]}
            </Link>
        )
    };
}
