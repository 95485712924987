import React from 'react'
import Grid from '@material-ui/core/Grid'

import AutoCompleteNative from '../../../../shared/autoCompleteNative'

export default function Step1(props) {
    function handleChange(e, uploadField) {
        //merge the new value into the new form vals
        var newArr = []
        props.formVals.mappings.forEach(a => {
            newArr.push({
                uploadField: a.uploadField,
                excelField: a.uploadField === uploadField ? e.target.value : a.excelField
            })
        })
        //build the new formVals
        var newVals = {
            ...props.formVals,
            mappings: newArr
        }
        //handle the change
        props.onChange(newVals)
    }

    //for inserting revenue and commission headers
    var revenueShown = false
    var commissionShown = false

    return(
        <div>
            <h4>
                Map Fields
            </h4>
            <p>Match the fields in the system (left) to the fields in the Excel file (right)</p>
            <Grid container spacing={1}>
                {
                    props.formVals.mappings.map(uf => {
                        var showRevenue = false
                        var showCommission = false

                        if((uf.uploadField + "").substring(0, 7) === "Revenue" && !revenueShown) {
                            showRevenue = true
                            revenueShown = true
                        }
                        if((uf.uploadField + "").substring(0, 10) === "Commission" && !commissionShown) {
                            showCommission = true
                            commissionShown = true
                        }

                        return (
                            <React.Fragment key={uf.uploadField}>
                                {
                                    showRevenue ?
                                        <Grid item xs={12}>
                                            <h3>
                                                Revenue
                                            </h3>
                                        </Grid>
                                    : null
                                }
                                {
                                    showCommission ?
                                        <Grid item xs={12}>
                                            <h3>
                                                Commission (optional)
                                            </h3>
                                        </Grid>
                                    : null
                                }
                                <Grid item xs={4} style={{textAlign: "right", paddingTop: "26px"}}>
                                    {uf.uploadField}
                                </Grid>
                                <Grid item xs={8}>
                                    <AutoCompleteNative
                                        id={"excel" + uf.uploadField}
                                        value={uf.excelField}
                                        label={"Excel field for " + uf.uploadField}
                                        onChange={e => handleChange(e, uf.uploadField)}
                                        optionLabel={x => x.name + " (" + (x.sample === undefined ? "Sample not available" : x.sample) + ")"}
                                        optionValue={o => o.name}
                                        clearValue={""}
                                        placeholder={"Choose..."}
                                        options={props.excelFields}
                                    />
                                </Grid>
                            </React.Fragment>
                        )
                    })
                }
            </Grid>
        </div>
    )
}