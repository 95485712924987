import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Divider from '@material-ui/core/Divider'

import DivisionPart from './divisionPart'

export default function MetricCalcModal(props) {
    const noFullscreen = useMediaQuery("(min-width:1000px)")

    function ensureArray(possibleArray) {
        return (Array.isArray(possibleArray) ? possibleArray : [])
    }

    return(
        <Dialog open={props.open} maxWidth="md" fullScreen={!noFullscreen} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", flexWrap: "nowrap"}}>
                        <div style={{margin: "auto"}}>
                        {
                            //if no numerators, it's 0
                            ensureArray(props.value).filter(x => x.divisionPart === "numerator").length > 0 ?
                                <DivisionPart
                                    value={ensureArray(props.value).filter(x => x.divisionPart === "numerator")}
                                    me={props.me}
                                />
                            : "0"
                        }    
                        </div>
                        {
                            //only showing this if there are any denominators
                            ensureArray(props.value).filter(x => x.divisionPart === "denominator").length > 0 ?
                                <>
                                    <div style={{width: "100%"}}>
                                        <Divider variant="middle" style={{marginTop: "10px", marginBottom: "15px"}} />
                                    </div>
                                    <div style={{margin: "auto"}}>
                                        <DivisionPart
                                            value={ensureArray(props.value).filter(x => x.divisionPart === "denominator")}
                                            me={props.me}
                                        />
                                    </div>
                                </>
                            : null
                        }
                    </div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        = {props.currentPercentString}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
    )
}