import React from 'react'

import MultiSelect from '../../../shared/form/multiSelect'
import { accessibleStores } from '../../../../lib/general'

export default function KpiParams(props) {
    function byLabel(a, b) {
        if(a.label > b.label) {return 1}
        if(a.label < b.label) {return -1}
        return 0
    }


    var cardHeaders = [
        {
            value: "storeTotalTargets",
            label: "Targets - store total"
        },
        {
            value: "storeIndividualTargets",
            label: "Targets - store individual"
        },
    ]
    //add in the general revenue and commission cards
    if(props.me !== undefined && props.me.accessProfile.showRevenue) {
        //they have access to revenue
        cardHeaders.push({
            value: "allStorerevenue",
            label: "Revenue - all stores"
        })
        cardHeaders.push(
            ...accessibleStores(props.me).map(s => ({
                value: s.storeID + "storerevenue",
                label: "Revenue - " + s.name
            })
        ))
        cardHeaders.push({
            value: "userrevenue",
            label: "Revenue - user"
        })
    }
    if(props.me !== undefined && props.me.accessProfile.showCommission) {
        //they have access to commission
        cardHeaders.push({
            value: "allStorecommission",
            label: "Commission - all stores"
        })
        cardHeaders.push(
            ...accessibleStores(props.me).map(s => ({
                value: s.storeID + "storecommission",
                label: "Commission - " + s.name
            })
        ))
        cardHeaders.push({
            value: "usercommission",
            label: "Commission - user"
        })
    }
    //add in the analysisField card headers
    if(props.me !== undefined && Array.isArray(props.me.analysisFields)) {
        cardHeaders.push(
            ...props.me.analysisFields.filter(af => af.targetRevenue || af.targetQuantity).map(a => ({
                value: "af" + a.analysisFieldID + "StoreTotalTargets",
                label: "Targets - " + a.label + " (Store Total)"
            }))
        )
        cardHeaders.push(
            ...props.me.analysisFields.filter(af => af.targetRevenue || af.targetQuantity).map(a => ({
                value: "af" + a.analysisFieldID + "IndividualTargets",
                label: "Targets - " + a.label + " (Individual)"
            }))
        )
    }
    //add in the metricPart headers
    if(props.me !== undefined && Array.isArray(props.me.metricParts)) {
        cardHeaders.push(
            ...props.me.metricParts.reduce((acc, cur) => acc.includes(cur.metricName) ? acc : acc.concat(cur.metricName), []).map(a => ({
                value: "metricName" + a.replace(",", ""),
                label: "Metric - " + a
            }))
        )
    }

    function handleChange(e) {
        //invert the selection
        var newVal = {
            target: {
                id: props.id || "hiddenCardSelect",
                value: cardHeaders.filter(x => !(e.target.value.includes(x.value))).map(x => x.value).join(",")
            }
        }
        props.onChange(newVal)
    }

    //console.log("Val is " + JSON.stringify(cardHeaders.filter(x => !((props.value + "").split(",").includes(x.value))).map(x => x.value)))

    return (
        <MultiSelect
            id="hiddenCards"
            multiple
            value={cardHeaders.filter(x => !((props.value + "").split(",").includes(x.value))).map(x => x.value)} //inverting the value
            label="Card collections"
            onChange={handleChange}
            optionLabel={o => o.label}
            optionValue={o => o.value + ""}
            clearValue={props.me === undefined ? 0 : props.me.userID}
            placeholder={"Card collections..."}
            options={cardHeaders.sort(byLabel)}
        />
    )
}