import React, {useState} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SendIcon from '@material-ui/icons/Send'
import Button from '@material-ui/core/Button'
import SendModal from './sendModal';

import {byName, formatMoney} from '../../../../lib/general'
import HeaderCol from './headerCol';
import BodyCol from './bodyCol';
import TotalCol from './totalCol';

export default function DailyReportGrid(props) {
    const [sendOpen, setSendOpen] = useState(false)
    const [includeRevenue, setIncludeRevenue] = useState(false)

    function handleSendOpen(ir) {
        setSendOpen(true)
        setIncludeRevenue(ir)
    }

    function handleSendClose() {
        setSendOpen(false)
    }

    function rrSort(a, b) {
        if (a.dailyReportAs < b.dailyReportAs){
            return -1;
        }
        if (a.dailyReportAs > b.dailyReportAs){
            return 1;
        }
        return 0;
    }

    const sortedStores = props.me.accessProfile.accessProfileStores.map(aps => aps.store).sort(byName)

    //an array of dailReportAs, quantity
    const reportRows = [
            ...new Set(props.analysisListItems.map(ali => ali.dailyReportAs))
        ]
        .map(dailyReportAs => ({
            dailyReportAs,
            quantity: getQuantity(dailyReportAs),
            revenue: getRevenue(dailyReportAs),
            params: getParams(props.params, dailyReportAs),
            storeQuantities: sortedStores.map(ss => getQuantity(dailyReportAs, ss)),
            storeRevenues: sortedStores.map(ss => getRevenue(dailyReportAs, ss)),
            storeParams: sortedStores.map(ss => getParams(props.params, dailyReportAs, ss)),
        }))
        .sort(rrSort)

    function getQuantity(dailyReportAs, storeFilter) {
        return [
            ...new Set(
                props.analysisListItems
                .filter(ali => ali.dailyReportAs === dailyReportAs)
                .map(ali => ali.code)
            )
        ]
        .map(code => props.sales.filter(sf => storeFilter === undefined ? true : (sf.storeID + "") === (storeFilter.storeID + "")).filter(s => s.saleAnalysisValues.split(", ").includes(code)).length)
        .reduce((acc, cur) => acc + cur, 0)
    }

    function getRevenue(dailyReportAs, storeFilter) {
        return [
            ...new Set(
                props.analysisListItems
                .filter(ali => ali.dailyReportAs === dailyReportAs)
                .map(ali => ali.code)
            )
        ]
        .map(code => props.sales.filter(sf => storeFilter === undefined ? true : (sf.storeID + "") === (storeFilter.storeID + "")).filter(s => s.saleAnalysisValues.split(", ").includes(code)).map(s => s.adjustedRevenue).reduce((acc, cur) => acc+cur, 0))
        .reduce((acc, cur) => acc + cur, 0)
    }

    function getParams(params, dailyReportAs, storeFilter) {
        //add in the analysis values filter based on the dailyreportas
        var retVal = {
            ...params,
            analysisValue: [
                ...new Set(
                    props.analysisListItems
                    .filter(ali => ali.dailyReportAs === dailyReportAs)
                    .map(ali => ali.code)
                )
            ].join(","),
        }
        //add in the storeFilter param if passed
        if(storeFilter !== undefined) {
            retVal.storeID = storeFilter.storeID
        }
        return retVal
    }

    const colsPerStore = (props.me === undefined || !props.me.accessProfile.showRevenue) ? 1 : 2
    const storeCount = props.store === "all" ? sortedStores.length + 1 : 1
    const colCount = 1 + (storeCount * colsPerStore)
    const colWidth = formatMoney(100 / colCount, 3) + "%"

    //console.log("storeCount " + storeCount + " colCount " + colCount)

    return (
        <div>
            <TableContainer component={Paper} style={{marginTop: "10px", maxWidth: (props.store === "all" ? "100%" : "250px")}}>
                <Table aria-label="Daily report table">
                    <TableHead>
                        { //adding in the extra header row for the store name
                            props.store === "all" ? 
                                <TableRow>
                                    <TableCell style={{padding: "4px"}}>
                                        
                                    </TableCell>
                                    <HeaderCol
                                        me={props.me}
                                    >
                                        All
                                    </HeaderCol>
                                    {
                                        sortedStores.map((ss, i) => 
                                            <HeaderCol
                                                me={props.me}
                                                key={ss.storeID}
                                                shaded={i % 2 === 0}
                                            >
                                                {ss.name}
                                            </HeaderCol>
                                        )
                                    }
                                </TableRow>
                            : null
                        }
                        <TableRow>
                            <TableCell style={{padding: "4px", width: colWidth}}>
                                <strong>Item</strong>
                            </TableCell>
                            <TableCell style={{padding: "4px", width: colWidth, textAlign: "right"}}>
                                <strong>Qty</strong>
                            </TableCell>
                            {
                                props.me === undefined || !props.me.accessProfile.showRevenue ? null :
                                    <TableCell style={{padding: "4px", width: colWidth, textAlign: "right"}}>
                                        <strong>Revenue</strong>
                                    </TableCell>
                            }
                            {
                                props.store === "all" ? 
                                    sortedStores.map((ss, i) => 
                                        <React.Fragment key={ss.storeID}>
                                            <TableCell style={{padding: "4px", width: colWidth, backgroundColor: i % 2 === 0 ? "#e8e8e8" : undefined, textAlign: "right"}}>
                                                <strong>Qty</strong>
                                            </TableCell>
                                            {
                                                props.me === undefined || !props.me.accessProfile.showRevenue ? null :
                                                    <TableCell style={{padding: "4px", width: colWidth, backgroundColor: i % 2 === 0 ? "#e8e8e8" : undefined, textAlign: "right"}}>
                                                        <strong>Revenue</strong>
                                                    </TableCell>
                                            }
                                        </React.Fragment>
                                    )
                                : null
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            reportRows.length === 0 ?
                                <TableRow >
                                    <TableCell scope="row" colSpan={2} style={{padding: "4px"}}>
                                        <span style={{fontStyle: "italic"}}>
                                            {
                                                props.loading ?
                                                    "Loading..."
                                                :
                                                    "No analysis fields at this store are configured for daily reporting..."
                                            }
                                        </span>
                                    </TableCell>
                                </TableRow>
                            :
                                reportRows.map(rr => 
                                    <TableRow key={rr.dailyReportAs}>
                                        <TableCell scope="row" style={{padding: "4px"}}>
                                            {rr.dailyReportAs}
                                        </TableCell>
                                        <BodyCol
                                            me={props.me}
                                            quantity={rr.quantity}
                                            revenue={rr.revenue}
                                            params={rr.params}
                                        />
                                        { //if all, now do each of the individual stores
                                            props.store === "all" ?
                                                sortedStores.map((ss, i) => 
                                                    <BodyCol
                                                        me={props.me}
                                                        quantity={rr.storeQuantities[i]}
                                                        revenue={rr.storeRevenues[i]}
                                                        key={ss.storeID}
                                                        shaded={i % 2 === 0}
                                                        params={rr.storeParams[i]}
                                                    />
                                                )
                                            : null
                                        }
                                    </TableRow>
                                )
                        }
                        {
                            reportRows.length > 1 ?
                                <TableRow>
                                    <TableCell scope="row" style={{padding: "4px"}}>
                                        <strong>Total</strong>
                                    </TableCell>
                                    <TotalCol
                                        me={props.me}
                                        quantity={reportRows.reduce((acc, cur) => acc + cur.quantity, 0)}
                                        revenue={reportRows.reduce((acc, cur) => acc + cur.revenue, 0)}
                                    />
                                    { //if all, now do each of the individual stores
                                        props.store === "all" ?
                                            sortedStores.map((ss, i) => 
                                                <TotalCol
                                                    me={props.me}
                                                    quantity={reportRows.reduce((acc, cur) => acc + cur.storeQuantities[i], 0)}
                                                    revenue={reportRows.reduce((acc, cur) => acc + cur.storeRevenues[i], 0)}
                                                    key={ss.storeID}
                                                    shaded={i % 2 === 0}
                                                />
                                            )
                                        : null
                                    }
                                </TableRow>
                            : null
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                props.store === "all" ? 
                    null
                :   
                    <>
                        <Button 
                            style={{marginTop: "10px", marginRight: "12px"}} 
                            variant="contained"
                            color="primary"
                            onClick={() => handleSendOpen(false)}
                            startIcon={<SendIcon />}
                        >
                            Send Quantity
                        </Button>
                        {
                            props.me === undefined || !props.me.accessProfile.showRevenue ? null :
                            <Button 
                                style={{marginTop: "10px"}} 
                                variant="contained"
                                color="default"
                                onClick={() => handleSendOpen(true)}
                                startIcon={<SendIcon />}
                            >
                                Send Quantity and Revenue
                            </Button>
                        }
                        <SendModal
                            open={sendOpen}
                            onClose={handleSendClose}
                            reportRows={reportRows}
                            store={props.store}
                            includeRevenue={includeRevenue}
                            saleDate={props.params.saleDate}
                        />
                    </>
            }
        </div>
    )
}