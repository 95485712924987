import React from 'react'
import Grid from '@material-ui/core/Grid'

//project
import CheckBox from '../../../shared/checkBox'
import { accessibleUsers } from '../../../../lib/general'

//returns yes, no, maybe indicating if all the usersList is in the value
function allSelected(value, usersList) {
    //filter the value by the userList.  Then compare lengths
    const usersListIDs = usersList.map(u => u.userID)
    var filteredValue = value.filter(ua => usersListIDs.includes(ua.userID))
    //now compare the lengths
    if(filteredValue.length === usersList.length) {
        //same number are selected, so all are selected
        return "yes"
    } else {
        //are there none selected?
        if(filteredValue.length === 0) {
            return "no"
        } else {
            //some are selected, but not all
            return "maybe"
        }
    }
}


export default function TickableUsers(props) {
    function buildEvent(withNewVal) {
        var e = {
            target: {
                id: props.id,
                value: withNewVal
            }
        }
        //console.log("Calling change with " + JSON.stringify(withNewVal))
        props.onChange(e)
    }

    const skeletonAnnouncementUser = {
        announcementID: props.announcementID,
        acknowledgedDate: new Date("2000-01-01"),
        clickedDate: new Date("2000-01-01"),
        lastSeenDate: new Date("2000-01-01"),
        firstSeenDate: new Date("2000-01-01"),
        clicked: false,
        acknowledged: false,
    }

    function handleCheckboxChange(event, userID) {
        //decide if we're adding or removing it
        var newVals = []
        if(event.target.value) {
            //they have ticked
            //add this one
            newVals.push(...props.value, { 
                ...skeletonAnnouncementUser,
                userID,
            })
        } else {
            //they have unticked
            //delete it
            props.value.forEach(u => {
                var thisOne = {...u}
                if(u.userID === userID) {
                    //skip it
                } else {
                    newVals.push(thisOne)
                }
            })
        }
        //construct a pseudo event to pass up to the parent form
        buildEvent(newVals)
    }

    const usersList = props.store === "all" ? accessibleUsers(props.me) : accessibleUsers(props.me).filter(u => u.storeID === props.store.storeID)
    //console.log("All selected: " + allSelected(props.value, usersList))

    function handleSelectAll(e, list) {
        var newVals = []
        //are we selecting all or unselecting all?
        if(allSelected(props.value, list) === "yes") {
            //unselecting all
            const listIDs = list.map(u => u.userID)
            props.value.forEach(ua => {
                //add it if it's not on the current list
                if(!(listIDs.includes(ua.userID))) {
                    //not one of the ones currently displayed, keep it
                    newVals.push(ua)
                }
            })
        } else {
            //selecting all
            //start with the existing list
            newVals.push(...props.value)
            //work out which ones need adding
            const haveIDs = props.value.map(ua => ua.userID)
            //add the ones we dont have
            newVals.push(
                ...list.filter(u => !(haveIDs.includes(u.userID))).map(u => ({
                    ...skeletonAnnouncementUser,
                    userID: u.userID
                }))
            )
        }
        //construct a pseudo event to pass up to the parent form
        buildEvent(newVals)
    }

    return (
        <div style={{minHeight: "1500px"}}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <CheckBox 
                        indeterminate={allSelected(props.value, usersList) === "maybe" ? true : undefined}
                        value={allSelected(props.value, usersList) === "yes"}
                        onChange={e => handleSelectAll(e, usersList)}
                        label={(allSelected(props.value, usersList) === "yes" ? "Deselect" : "Select") + " all (" + usersList.length + ")"} 
                        disabled={props.disabled} 
                    />
                </Grid>
                {
                    usersList.map((u, i) => {
                        return (
                            <Grid item xs={12} key={i}>
                                <CheckBox 
                                    disabled={props.disabled} 
                                    label={u.name} 
                                    onChange={e => handleCheckboxChange(e, u.userID)} 
                                    value={props.value.filter(ts => u.userID === ts.userID).length === 1} 
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </div>
    )
}