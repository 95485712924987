import React, {useState} from 'react'
import {Route, Switch} from 'react-router-dom'

import TargetConsole from './targetConsole'

export default function Targets(props) {
    return(
        <Switch>
            <Route path="/targets/:view/:targetDate/:storeID/:highlightTargetID" render={renderProps => <TargetConsole {...renderProps} me={props.me} />} />
            <Route path="/targets/:view/:targetDate/:storeID" render={renderProps => <TargetConsole {...renderProps} me={props.me} />} />
            <Route path="/targets" render={renderProps => <TargetConsole {...renderProps} me={props.me} />} />
        </Switch>
    )
}