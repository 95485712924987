import React, { useState } from 'react'
import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import dateFormat from 'dateformat'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'

import AutoCompleteAsync from '../../shared/autoCompleteAsync'
import DatePicker from '../../shared/form/datePicker'

var tempVal = ""

export default function SaleFilters(props) {
    const [skuDisplayed, setSKUDisplayed] = useState(props.filters.sku)
    const [analysisValueDisplayed, setAnalysisValueDisplayed] = useState(props.filters.analysisValue)
    const [rateCardLineDescriptionContainsValueDisplayed, setRateCardLineDescriptionContainsValueDisplayed] = useState(props.filters.rateCardLineDescriptionContains)
    const [discountNameLowerValueDisplayed, setDiscountNameLowerValueDisplayed] = useState(props.filters.discountNameLower)

    function handleTextChange(event, setStateFn) {
        var newVal = event.target.value
        var id = event.target.id
        tempVal = newVal
        setStateFn(newVal)
        setTimeout(function() {
            //is it still the same now?
            if(tempVal === newVal) {
                //no change in the time so get new data
                var newFilters = {...props.filters}
                //if it's all, completely remove the filter
                if(newVal === "all" || newVal === "") {
                    delete newFilters[id]
                } else {
                    newFilters[id] = newVal
                }
                props.onFiltersChange(newFilters)
            }
        }, 750)
    }

    function handleRBChange(event, id) {
        var newFilters = {...props.filters}
        //if it's all, completely remove the filter
        if(event.target.value === "all") {
            delete newFilters[id]
        } else {
            newFilters[id] = event.target.value
        }
        props.onFiltersChange(newFilters)
    }

    function handleDateChange(e) {
        var newFilters = {...props.filters}
        //see if it's a proper date yet
        try{
            newFilters[e.target.id] = dateFormat(e.target.value, "yyyy-mm-dd")
            props.onFiltersChange(newFilters)
        } catch (err) {

        }        
        
    }

    function handleUserChange(e) {
        var newFilters = {...props.filters}
        if(e.target.value + "" === "0") {
            delete newFilters[e.target.id]
        } else {
            newFilters[e.target.id] = e.target.value
        }
        props.onFiltersChange(newFilters)
    }

    function handleSaleTypeIDChange(e) {
        var newFilters = {...props.filters}
        if(e.target.value + "" === "0") {
            delete newFilters[e.target.id]
        } else {
            newFilters[e.target.id] = e.target.value
        }
        props.onFiltersChange(newFilters)
    }

    function handleCBChange(e) {
        var newFilters = {...props.filters}
        var currentList = (props.filters.currentHistoryStep === undefined ? "" : props.filters.currentHistoryStep + "").split(",").filter(a => a !== "")
        //console.log("Currently " + (props.filters.currentHistoryStep === undefined) + " is " + currentList.length + ": " + JSON.stringify(currentList))
        //depending on if it's been checked or not
        if(e.target.checked) {
            //console.log("Addeing " + e.target.id)
            //it's been checked.  add it to the list
            newFilters.currentHistoryStep = [...new Set(currentList.concat(e.target.id))].join(",")
        } else {
            //console.log("Removing " + e.target.id)
            //it's been removed
            newFilters.currentHistoryStep = currentList.filter(a => a !== e.target.id).join(",")
        }
        props.onFiltersChange(newFilters)
    }

    //console.log("Filters: " + JSON.stringify(props.filters))

    return (
        <ExpansionPanel style={{marginBottom: "8px"}}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography>Filters</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Is refund?</FormLabel>
                            <RadioGroup row aria-label="position" name="position" defaultValue="all" value={props.filters.isRefund === undefined ? "all" : props.filters.isRefund + ""} onChange={e => handleRBChange(e, "isRefund")}>
                                <FormControlLabel value="all" control={<Radio />} label="All" />
                                <FormControlLabel value="0" control={<Radio />} label="No refunds" />
                                <FormControlLabel value="1" control={<Radio />} label="Refunds only" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Is refunded?</FormLabel>
                            <RadioGroup row aria-label="position" name="position" defaultValue="all" value={props.filters.isRefunded === undefined ? "all" : props.filters.isRefunded + ""} onChange={e => handleRBChange(e, "isRefunded")}>
                                <FormControlLabel value="all" control={<Radio />} label="All" />
                                <FormControlLabel value="0" control={<Radio />} label="No refunded" />
                                <FormControlLabel value="1" control={<Radio />} label="Refunded only" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Order ref duplicate?</FormLabel>
                            <RadioGroup row aria-label="position" name="position" defaultValue="all" value={props.filters.dupeCountGEQ === undefined ? "all" : props.filters.dupeCountGEQ + ""} onChange={e => handleRBChange(e, "dupeCountGEQ")}>
                                <FormControlLabel value="all" control={<Radio />} label="All" />
                                <FormControlLabel value="2" control={<Radio />} label="Duplicates only" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">SKU type is excluded from discounts?</FormLabel>
                            <RadioGroup row aria-label="position" name="position" defaultValue="all" value={props.filters.excludeDiscount === undefined ? "all" : props.filters.excludeDiscount + ""} onChange={e => handleRBChange(e, "excludeDiscount")}>
                                <FormControlLabel value="all" control={<Radio />} label="All" />
                                <FormControlLabel value="0" control={<Radio />} label="Discount allowed" />
                                <FormControlLabel value="1" control={<Radio />} label="Discount not allowed" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                   <Grid item xs={12} md={6}>
                        <FormControl component="fieldset" >
                            <FormLabel component="legend">Status</FormLabel>
                            <FormGroup row>
                                {
                                    ["recorded", "modified", "cancelled", "reconciled"].map(s => {
                                        return(
                                            <FormControlLabel
                                                control={<Checkbox id={s} checked={(props.filters.currentHistoryStep + "").includes(s)} onChange={handleCBChange} name={s} />}
                                                label={s.substring(0, 1).toUpperCase() + s.substring(1)}
                                                key={s}
                                            />
                                        )
                                    })
                                }   
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker 
                            id="saleDateGEQ"
                            label="From date"
                            onChange={handleDateChange}
                            value={props.filters.saleDateGEQ || ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker 
                            id="saleDateLEQ"
                            label="To date"
                            onChange={handleDateChange}
                            value={props.filters.saleDateLEQ || ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AutoCompleteAsync 
                            multi={false}
                            id="attributableToUserID"
                            label="Sale made by"
                            onChange={handleUserChange}
                            placeholder="All users..."
                            value={props.filters.attributableToUserID === undefined ? 0 : props.filters.attributableToUserID}
                            getURL={input => "/users?pageLength=50&page=0&sort=name" + ((input === "" || input === undefined) ? "" : "&quickFilter=" + encodeURIComponent(input))}
                            valueField="userID"
                            labelFn={v => v.name}
                            getURLValues={"/users?pageLength=1&page=0&userID=" + (props.filters.attributableToUserID || 0)}
                            labelValues={v => v.name}
                            idField="userID"
                            clearable={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AutoCompleteAsync 
                            multi={false}
                            id="saleTypeID"
                            label="Sale type"
                            onChange={handleSaleTypeIDChange}
                            placeholder="All sale types..."
                            value={props.filters.saleTypeID === undefined ? 0 : props.filters.saleTypeID}
                            getURL={input => "/saleTypes?pageLength=50&page=0&sort=name" + ((input === "" || input === undefined) ? "" : "&quickFilter=" + encodeURIComponent(input))}
                            valueField="saleTypeID"
                            labelFn={v => v.name}
                            getURLValues={"/saleTypes?pageLength=1&page=0&saleTypeID=" + (props.filters.saleTypeID || 0)}
                            labelValues={v => v.name}
                            idField="saleTypeID"
                            clearable={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            margin="dense"
                            id="sku"
                            label="SKUs (comma separated)"
                            type="text"
                            value={skuDisplayed === undefined ? "" : skuDisplayed}
                            onChange={e => handleTextChange(e, setSKUDisplayed)}
                            fullWidth
                            disabled={props.disabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            margin="dense"
                            id="analysisValue"
                            label="Analysis values (comma separated)"
                            type="text"
                            value={analysisValueDisplayed === undefined ? "" : analysisValueDisplayed}
                            onChange={e => handleTextChange(e, setAnalysisValueDisplayed)}
                            fullWidth
                            disabled={props.disabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            margin="dense"
                            id="rateCardLineDescriptionContains"
                            label="SKU description contains"
                            type="text"
                            value={rateCardLineDescriptionContainsValueDisplayed === undefined ? "" : rateCardLineDescriptionContainsValueDisplayed}
                            onChange={e => handleTextChange(e, setRateCardLineDescriptionContainsValueDisplayed)}
                            fullWidth
                            disabled={props.disabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            margin="dense"
                            id="discountNameLower"
                            label="Discount name"
                            type="text"
                            value={discountNameLowerValueDisplayed === undefined ? "" : discountNameLowerValueDisplayed}
                            onChange={e => handleTextChange(e, setDiscountNameLowerValueDisplayed)}
                            fullWidth
                            disabled={props.disabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker 
                            id="targetDate"
                            label="Target date"
                            onChange={handleDateChange}
                            value={props.filters.targetDate || "01/01/2000"}
                        />
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}