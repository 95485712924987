import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default function JustifyStepsModal(props) {
    const [showNA, setShowNA] = useState(false)

    function handleShowNA() {
        setShowNA(!showNA)
    }
    return (
        <Dialog open={props.open} maxWidth={"md"} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Steps Justification
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Justification for the steps that {props.entityDisplayName} must take
                </DialogContentText>
                {
                    props.steps.filter(s => (showNA ? true : s.applicable)).map(step => {
                        return(
                            <p key={step.name}>
                                <span style={{backgroundColor: (step.applicable ? undefined : "pink")}} >
                                    {step.displayName + " - " + step.description}
                                    <br/>
                                    <span style={{fontStyle: "italic"}} >
                                        Justification: {step.justification}
                                    </span>
                                </span>
                            </p>
                        )
                    })
                }
                <a style={{color: "blue", textDecoration: "underline"}} onClick={handleShowNA}>
                    {showNA ? "Hide steps not applicable" : "Show steps not applicable"}
                </a>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}