import React from 'react'
import {Route} from 'react-router-dom'

import SaleTypesList from './saleTypesList'

export default function SaleTypes(props) {
    return(
        <div>
            <Route path="/data/saleTypes" render={renderProps => <SaleTypesList {...renderProps} me={props.me} />} />
        </div>
    )
}