import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import APIHowToLinks from './apiHowToLinks';
import ApiParams from './apiParams';

const blankValidationResult = {fail: false, failSummary: '', failMessages: {}}

export default function ApiModal(props) {
    const [paramVals, setParamVals] = useState([])

    const noFullscreen = useMediaQuery("(min-width:1000px)")

    return(
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth="lg" fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {props.details.name} API Details
            </DialogTitle>
            <DialogContent>
                <div>
                    You can access the data on this page from an external application (for example Excel or Power BI) using the following URL:
                </div>
                <div style={{width: "95%", margin: "12px", padding: "12px", border: "1px solid #ccc", borderRadius: "16px"}}>
                    <code>
                        {props.details.url + (paramVals.filter(p => p.valid).length === 0 ? "" : paramVals.filter(p => p.valid).reduce((acc, cur, i) => acc + (i === 0 ? "" : "&") + cur.param + "=" + encodeURIComponent(cur.value), "?"))}
                    </code>
                </div>
                <APIHowToLinks />
                {
                    props.details.params === undefined || props.details.params.length === 0 ? null :
                    <ApiParams
                        params={props.details.params}
                        paramVals={paramVals}
                        onChange={n => setParamVals(n)}
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
      </Dialog>
    )
}