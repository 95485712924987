import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField'


export default function OrderRefField(props) {

    function handleChange(event) {
        var e = {
            target: {
                id: props.id,
                value: event.target.value.toUpperCase().substring(0, 20)
            }
        }
        props.onChange(e)
    }

    return (
        <>
            <TextField
                margin="dense"
                id={props.id}
                label={props.label}
                type="text"
                value={props.value}
                onChange={handleChange}
                error={props.error}
                helperText={props.helperText}
                fullWidth
                disabled={props.disabled}
            />
        </>
    )
}