import React from 'react'
import Grid from '@material-ui/core/Grid'
import dateFormat from 'dateformat'
import { getDaysInMonth } from '../../../../lib/general'

import TargetKPICard from './targetKPICard'
import CardSetHeader from './cardSetHeader'

export default function TargetKPICardSet(props) {
    var analysisFields = [] //any with an analysisFieldID go here
    var analysisFieldsTotal = [] //any with an analysisFieldID, with userID = 0 go here
    var stores = [] //any with analysisFieldID = 0 go here
    var storesTotal = [] //any with analysisFieldID = 0 and userID = 0 go here

    function sortTargets(a, b) {
        //store ID first, to keep them together
        if(a.storeName > b.storeName) {return 1}
        if(a.storeName < b.storeName) {return -1}
        //same store, show store totals first ie analysisFieldID 0
        if(a.analysisFieldID < b.analysisFieldID) {return 1}
        if(a.analysisFieldID > b.analysisFieldID) {return -1}
        //now it's same analyssi field, sort by analysis list item
        if(a.analysisListItemID < b.analysisListItemID) {return 1}
        if(a.analysisListItemID > b.analysisListItemID) {return -1}
        //shouldn't get here
        return 0
    }

    //dates - using the reference target as the date as they shoudl all be the same 
    const targetDate = new Date(props.value[0].targetDate)   
    //how these are set depends on whether we're looking at current month (today) or not (end of month)
    var today
    //if this is not the current month then its using the end of the month
    if(targetDate.getUTCFullYear() !== new Date().getUTCFullYear() || targetDate.getUTCMonth() !== new Date().getUTCMonth()) {
        //target date is not current month - overriding with end of month
        today = new Date(targetDate.getUTCFullYear(), targetDate.getUTCMonth() + 1, 0)
    } else {
        //it's current month
        today = new Date()
    }
    //console.log("Target today date " + today + " in  dateformat " + dateFormat(today, "yyyy-mm-dd"))
    var dayOfMonth = today.getUTCDate()
    const daysInMonth = getDaysInMonth(today.getUTCMonth() + 1, today.getUTCFullYear())
    

    //filtering out the ones with 0
    props.value.sort(sortTargets).forEach(t => {
        //which one does it belong in?
        if(t.analysisFieldID === 0) {
            //add it to the stores.  is it store total or individual target?
            if(t.userID === 0) {
                storesTotal.push(t)
            } else {
                stores.push(t)
            }
        } else {
            //add it to the analysisfields.  is it a store total or individual target?
            if(t.userID === 0) {
                var rels = analysisFieldsTotal.filter(a => a.analysisFieldID === t.analysisFieldID)
                if(rels.length === 1) {
                    //add it to the existing array
                    rels[0].targets.push(t)
                } else {
                    //add the obj
                    analysisFieldsTotal.push({
                        analysisFieldID: t.analysisFieldID,
                        analysisFieldLabel: t.analysisFieldLabel,
                        targets: [t]
                    })
                }
            } else {
                var rels = analysisFields.filter(a => a.analysisFieldID === t.analysisFieldID)
                if(rels.length === 1) {
                    //add it to the existing array
                    rels[0].targets.push(t)
                } else {
                    //add the obj
                    analysisFields.push({
                        analysisFieldID: t.analysisFieldID,
                        analysisFieldLabel: t.analysisFieldLabel,
                        targets: [t]
                    })
                }
            }
            
        }
    })

    if(stores.length === 0 && analysisFields.length === 0 && storesTotal.length === 0 && analysisFieldsTotal.length === 0) {
        return (
            null
        )
    } else {
        return (
            <>
                {
                    storesTotal.length === 0 || props.hidden.split(",").includes("storeTotalTargets") ? null :
                    <>
                        <CardSetHeader
                            title={(dateFormat(today, "mmm yyyy") + " Store Total Targets (day " + dayOfMonth + " of " + daysInMonth + ")")}
                            onAddHidden={props.onAddHidden}
                            setName="storeTotalTargets"
                        />
                        <Grid container spacing={2}>
                            {
                                storesTotal.map(s => <TargetKPICard 
                                    value={s}
                                    minHeight={props.minHeight}
                                    me={props.me}
                                    key={s.targetID}
                                />)
                            }
                        </Grid>
                    </>
                }
                {
                    stores.length === 0 || props.hidden.split(",").includes("storeIndividualTargets") ? null :
                    <>
                        <CardSetHeader
                            title={(dateFormat(today, "mmm yyyy") + " Store Individual Targets (day " + dayOfMonth + " of " + daysInMonth + ")")}
                            onAddHidden={props.onAddHidden}
                            setName="storeIndividualTargets"
                        />
                        <Grid container spacing={2}>
                            {
                                stores.map(s => <TargetKPICard 
                                    value={s}
                                    minHeight={props.minHeight}
                                    me={props.me}
                                    key={s.targetID}
                                />)
                            }
                        </Grid>
                    </>
                }
                {
                    analysisFieldsTotal.length === 0 ? null :
                    analysisFieldsTotal.map(a => 
                        props.hidden.split(",").includes("af" + a.analysisFieldID + "StoreTotalTargets") ? null :
                        <React.Fragment key={a.analysisFieldID}>
                            <CardSetHeader
                                title={(dateFormat(today, "mmm yyyy") + " " + a.analysisFieldLabel + " Targets (Store Total) (day " + dayOfMonth + " of " + daysInMonth + ")")}
                                onAddHidden={props.onAddHidden}
                                setName={"af" + a.analysisFieldID + "StoreTotalTargets"}
                            />
                            <Grid container spacing={2}>
                                {
                                    a.targets.map(s => <TargetKPICard 
                                        value={s}
                                        minHeight={props.minHeight}
                                        me={props.me}
                                        key={s.targetID}
                                    />)
                                }
                            </Grid>
                        </React.Fragment>    
                    )
                }
                {
                    analysisFields.length === 0 ? null :
                    analysisFields.map(a => 
                        props.hidden.split(",").includes("af" + a.analysisFieldID + "IndividualTargets") ? null :
                        <React.Fragment key={a.analysisFieldID}>
                            <CardSetHeader
                                title={(dateFormat(today, "mmm yyyy") + " " + a.analysisFieldLabel + " Targets (day " + dayOfMonth + " of " + daysInMonth + ")")}
                                onAddHidden={props.onAddHidden}
                                setName={"af" + a.analysisFieldID + "IndividualTargets"}
                            />
                            <Grid container spacing={2}>
                                {
                                    a.targets.map(s => <TargetKPICard 
                                        value={s}
                                        minHeight={props.minHeight}
                                        me={props.me}
                                        key={s.targetID}
                                    />)
                                }
                            </Grid>
                        </React.Fragment>    
                    )
                }
            </>
        )
    }
}