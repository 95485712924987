//react and material
import React from 'react';
//import PropTypes from 'prop-types';
//import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import InputAdornment from '@material-ui/core/InputAdornment'

//icons
import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { formatMoney } from '../../../lib/general';

export default function DataTableToolbar(props){
    //const classes = useStyles();

    const handleQuickFilterChange = (event) => {
        props.onQuickFilterChange(event.target.value)
    }
  
    const { selected, listIDs, title, onDataChanged } = props;
    const SelectActions = props.selectActions
    const numSelected = selected.length;
    const selectingMode = numSelected > 0 && SelectActions !== undefined
    
    return (
        <Toolbar style={{display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
            <div style={{display: "flex"}}>
                <Typography variant="subtitle1">
                    {//if selecting then show number selected, else show title
                        selectingMode ? 
                            <>
                                {numSelected} selected
                                {
                                    listIDs.length > 0 && listIDs[0].length > (2 + props.aggregateFieldIndex) ?
                                        //there is an aggregateField to total up
                                        " (" + formatMoney(listIDs.filter(l => selected.includes(l[0])).reduce((acc, cur) => acc + cur[2 + props.aggregateFieldIndex], 0), 2) + ")"
                                    : null
                                }
                            </>
                        :
                            <>
                            {
                                props.enableCrud ? 
                                    <Tooltip title="Add new">
                                        <IconButton size="small" onClick={() => props.handleCrud(0, "create")} aria-label="Add new">
                                            <AddCircleIcon />
                                        </IconButton>
                                    </Tooltip>
                                : null
                            }
                            {title}
                                {
                                    listIDs.length > 0 && listIDs[0].length > (2 + props.aggregateFieldIndex) ?
                                        //there is an aggregateField to total up
                                        " (" + formatMoney(listIDs.filter(row => row[2 + props.aggregateFieldIndex]).reduce((acc, row) => acc + row[2 + props.aggregateFieldIndex], 0), 2) + ")"
                                    : null
                                }
                            </>
                    }
                    {//if in selecting mode show the select action buttons, else show crud
                        selectingMode ?
                            <SelectActions
                                selected={selected}
                                listIDs={listIDs}
                                onDataChanged={onDataChanged}
                            />
                        :
                            null
                    }
                </Typography>
            </div>
            <div style={{display: "flex"}}>
                <TextField 
                    id="qf" 
                    label="Filter table" 
                    onChange={handleQuickFilterChange} 
                    value={props.quickFilter} 
                    style={{width: props.noSmallFilter ? "230px" : "125px"}}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        </Toolbar>
    );
} 
