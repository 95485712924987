import React, {useState} from 'react'
import {Route, Switch} from 'react-router-dom'

import AnalysisFieldsList from './analysisFieldsList'

export default function AnalysisFields(props) {
    return(
        <div>
            <Route path="/data/analysisFields" component={AnalysisFieldsList} />
        </div>
    )
}